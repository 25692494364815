import React from 'react';
import { ReactElement } from 'react';
import Feature from '../components/services/feature';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import NotificationIcon from '../components/icons/outline/features/notification';
import ChatIcon from '../components/icons/outline/features/chat';
import MultilingualIcon from '../components/icons/outline/features/multilingual';
import ContentIcon from '../components/icons/outline/features/content';
import FileIcon from '../components/icons/outline/features/file-management';
import MembreIcon from '../components/icons/outline/features/membre';
import GameIcon from '../components/icons/outline/features/game';
import AnalysisIcon from '../components/icons/outline/features/analysis';
import BlogIcon from '../components/icons/outline/features/blog';
import FingerprintIcon from '../components/icons/outline/features/fingerprint';

enum FeatureType {
    NOTIFICATION = 'notifications',
    AUTH = 'authentifation',
    LINGUAL = 'multilingual',
    CHAT = 'live chat',
    CONTENT = 'content management',
    FILE = 'file management',
    MEMBER = 'member submissions',
    GAME = 'quizzes and gamified apps',
    ANALYTICS = 'analytics',
    BLOG = 'blogsystem',
}

const features = [
    { icon: <NotificationIcon className='text-secondary' />, type: FeatureType.NOTIFICATION },
    { icon: <FingerprintIcon className='text-secondary' />, type: FeatureType.AUTH },
    { icon: <MultilingualIcon className='text-secondary' />, type: FeatureType.LINGUAL },
    { icon: <ChatIcon className='text-secondary' />, type: FeatureType.CHAT },
    { icon: <ContentIcon className='text-secondary' />, type: FeatureType.CONTENT },
    { icon: <FileIcon className='text-secondary' />, type: FeatureType.FILE },
    { icon: <MembreIcon className='text-secondary' />, type: FeatureType.MEMBER },
    { icon: <GameIcon className='text-secondary' />, type: FeatureType.GAME },
    { icon: <AnalysisIcon className='text-secondary' />, type: FeatureType.ANALYTICS },
    { icon: <BlogIcon className='text-secondary' />, type: FeatureType.BLOG },
];

export default function FeatureOverview(): ReactElement {
    const { strings } = useText(local);
    return (
        <div className='mt-12 flex flex-col items-center justify-center'>
            <div className='grid grid-cols-2 gap-5 sm:grid-cols-5 '>
                {features.map((item) => (
                    <Feature key={item.type} icon={item.icon} title={strings.featuretitle[item.type]} />
                ))}
            </div>
        </div>
    );
}

type LocalSchema = {
    featuretitle: {
        [key in FeatureType]: string;
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        featuretitle: {
            [FeatureType.NOTIFICATION]: 'Notifications',
            [FeatureType.AUTH]: 'Authentifation',
            [FeatureType.LINGUAL]: 'Multilingual',
            [FeatureType.CHAT]: 'Live Chat',
            [FeatureType.CONTENT]: 'Content Management',
            [FeatureType.FILE]: 'File Management',
            [FeatureType.MEMBER]: 'Member Submissions',
            [FeatureType.GAME]: 'Quizzes and Gamified Apps',
            [FeatureType.ANALYTICS]: 'Analytics',
            [FeatureType.BLOG]: 'Blogsystem',
        },
    },
};
