import React, { ReactElement, RefObject, useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import Strings from '../utils/string';
import useDimension from '../hooks/use-dimension';

export default function SceneNine(): ReactElement {
    const { strings } = useText(local);

    const sceneContainer = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);

    const textRef = useRef<HTMLDivElement>(null);
    const circleRef = useRef<HTMLDivElement>(null);
    const circleDivRef = useRef<HTMLDivElement>(null);
    const transitionDiv = useRef<HTMLDivElement>(null);
    const transitionBall = useRef<HTMLDivElement>(null);
    const transitionOutNumberRef = useRef<HTMLParagraphElement>(null);
    const blurRef = useRef<HTMLDivElement>(null);

    const timeline = useRef<GSAPTimeline>();
    const circleTimeline = useRef<GSAPTimeline>();
    const transitionTimeline = useRef<GSAPTimeline>();

    const dimension = useDimension();

    useGSAP(() => {
        timeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                pin: contentContainer.current,
                endTrigger: transitionDiv.current,
                start: 'top top',
                end: 'bottom bottom',
                scrub: true,
                id: 'sceneNine',
            },
        });
        initCircleTimeline();
        initTransitionTimeline();
    });

    function initCircleTimeline(): void {
        circleTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                endTrigger: transitionDiv.current,
                start: 'top top',
                end: 'top top',
                scrub: true,
            },
        });

        circleTimeline.current.to(circleRef.current, {
            borderRadius: '15%',
        });

        circleTimeline.current.to(circleRef.current, {
            height: '100%',
            borderRadius: '5%',
            backgroundColor: '#F9AAFF',
        });

        circleTimeline.current.to(circleRef.current, {
            borderRadius: '5%',
            width: '100%',
            height: '75%',
            backgroundColor: '#F97F26',
        });

        circleTimeline.current.to(circleRef.current, {
            borderRadius: '50%',
            width: '75%',
            height: '75%',
            backgroundColor: '#34AAFF',
        });
    }

    function initTransitionTimeline(): void {
        transitionTimeline.current = gsap.timeline({
            scrollTrigger: {
                pin: transitionDiv.current,
                trigger: transitionDiv.current,
                start: 'top top',
                end: 'bottom bottom',
                id: 'transition',
                scrub: true,
                onEnter: () => {
                    gsap.set(circleDivRef.current, { opacity: 0 });
                    gsap.set(transitionBall.current, { opacity: 1 });
                },
                onEnterBack: () => {
                    gsap.set(circleDivRef.current, { opacity: 1 });
                },
                onLeaveBack: () => {
                    gsap.set(transitionBall.current, { opacity: 0 });
                    gsap.set(circleDivRef.current, { opacity: 1 });
                },
            },
        });

        const circleCenter = calculateCenterFromDiv(circleRef);
        transitionBall.current?.setAttribute(
            'style',
            `left: ${(circleDivRef.current?.offsetLeft ?? 0) + circleCenter.x}px; top: ${(circleDivRef.current?.offsetTop ?? 0) + circleCenter.y}px`,
        );

        transitionTimeline.current.to(textRef.current, { opacity: 0, translateY: '-20vh' }, 0);
        transitionTimeline.current.to(
            transitionBall.current,
            {
                height: dimension.dimension === 'mobile' ? '150vw' : '120vh',
                width: dimension.dimension === 'mobile' ? '150vw' : '120vh',
            },
            0,
        );
        transitionTimeline.current.to(blurRef.current, { backdropFilter: 'blur(10px)' }, 0);
        transitionTimeline.current.to(transitionOutNumberRef.current, { opacity: 1 }, '<30%');
    }

    function calculateCenterFromDiv(div: RefObject<HTMLDivElement>): { x: number; y: number } {
        const centerX = (div.current?.offsetLeft ?? 0) + (div.current?.offsetWidth ?? 0) / 2;
        const centerY = (div.current?.offsetTop ?? 0) + (div.current?.offsetHeight ?? 0) / 2;

        return { x: centerX, y: centerY };
    }

    return (
        <section className='overflow-hidden'>
            <div ref={sceneContainer} className='relative h-[200vh] w-full'>
                <div
                    ref={contentContainer}
                    className='relative flex h-[100vh] min-h-screen w-full overflow-x-hidden bg-primary'
                >
                    <div className='relative flex h-full w-full flex-col items-center justify-center space-y-10 pt-5 md:flex-row md:space-x-28 md:space-y-0 md:px-2'>
                        <div
                            ref={textRef}
                            className='flex w-80 flex-col items-center justify-center space-y-5 md:mb-0 md:space-y-12 lg:w-[400px]'
                        >
                            <p className='text-xl lg:text-2xl' style={{ lineHeight: 1.5 }}>
                                {strings.entry}
                            </p>
                            <p className=' text-2xl lg:text-3xl ' style={{ lineHeight: 1.5 }}>
                                {Strings.Format(
                                    strings.text.base,
                                    <span className='font-bold'>{strings.text.product1} </span>,
                                    <span className='font-bold'>{strings.text.product2} </span>,
                                    <span className='font-bold'>{strings.text.product3} </span>,
                                )}
                            </p>
                        </div>
                        <div
                            ref={circleDivRef}
                            className='relative flex h-52 w-52 items-center justify-center lg:h-80 lg:w-80'
                        >
                            <div ref={circleRef} className=' h-3/4 w-3/4 rounded-full bg-green' />
                        </div>
                    </div>
                    <div ref={blurRef} className='absolute top-0 z-30 h-full w-full' />
                </div>
            </div>

            <div ref={transitionDiv} className='relative h-[200vh] w-[100vw]'>
                <div
                    ref={transitionBall}
                    className='absolute z-50 flex h-[156px] w-[156px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-blue opacity-0 lg:h-[240px] lg:w-[240px]'
                >
                    <p ref={transitionOutNumberRef} className='text-transition font-bold opacity-0'>
                        {strings.transitionOutNumber}
                    </p>
                </div>
            </div>
        </section>
    );
}

type LocalSchema = {
    entry: string;
    text: {
        base: string;
        product1: string;
        product2: string;
        product3: string;
    };
    transitionOutNumber: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        entry: 'So übertragen wir die Inhalte an Frontend-Anwendungen.',
        text: {
            base: 'Egal ob {0} {1} oder jegliches {2}',
            product1: 'Webseite,',
            product2: 'App',
            product3: '"Internet of Things"-Produkt',
        },
        transitionOutNumber: '3.',
    },
};
