import React, { ReactElement, useContext } from 'react';
import useText, { LocalizedStrings, SupportedLanguages } from '../../../hooks/use-locals';
import Strings from '../../../utils/string';
import { CookieConsentContext } from '../../../providers/cookie-consent-provider';

export default function CookieConsentBanner(): ReactElement {
    const { strings } = useText(local);

    const { allow } = useContext(CookieConsentContext);

    return (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center bg-[#103454] bg-opacity-60 p-4'>
            <div className='max-w-md space-y-4 rounded-3xl bg-white p-8'>
                <div className='flex flex-row items-center justify-center'>
                    <img alt='PRL Logo' className='h-[30px] w-[30px]' src='./logos/logo_minimized.svg' />
                    <p className='w-4/5 text-xl text-[#596E81]'>{strings.headline}</p>
                </div>
                <div>
                    <p className='text-left text-darkBlue'>{strings.welcome}</p>
                    <p className='text-left text-darkBlue'>{strings.text}</p>
                </div>
                <p className='text-left text-darkBlue'>
                    {Strings.Format(
                        strings.additional.base,
                        <a
                            target='_blank'
                            href='https://www.poprocket.com/datenschutz#cookie-section'
                            className='underline'
                            rel='noreferrer'
                        >
                            {strings.additional.highlight}
                        </a>,
                    )}
                </p>
                <p className='text-left text-darkBlue'>{strings.question}</p>
                <div className='flex flex-col space-y-2'>
                    <button type='button' className='rounded bg-[#2487c4] p-1 text-white ' onClick={() => allow(true)}>
                        {strings.accept}
                    </button>
                    <button
                        type='button'
                        className='rounded bg-[#d9dddf] p-1 text-[#3B3B3B]'
                        onClick={() => allow(false)}
                    >
                        {strings.decline}
                    </button>
                </div>
            </div>
        </div>
    );
}

type LocalSchema = {
    headline: string;
    welcome: string;
    text: string;
    additional: { base: string; highlight: string };
    question: string;
    accept: string;
    decline: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        headline: 'Datenschutz und Cookie Einstellungen',
        welcome: 'Hey 👋',
        text: 'um den Raketen-Antrieb für unsere Seite anzutreiben setzen wir essenzielle Cookies ein, die für die Funktionalität der Seite notwendig sind. Da wir uns stetig verbessern und dazulernen möchten, setzen wir darüberhinaus Google Analytics und Google Tag Manager ein - wenn du einverstanden bist.',
        additional: {
            base: 'Mehr Informationen dazu findest Du {0}.',
            highlight: 'hier',
        },
        question: 'Dürfen wir Dein Verhalten auf unserer Seite statistisch auswerten?',
        accept: 'Essentielle Cookies und Statistiken zulassen',
        decline: 'Nur essenzielle Cookies zulassen',
    },
};
