import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function MembreIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} width={width} height={height} viewBox='0 0 50 50' fill='none'>
            <circle cx='25' cy='25' r='24.5' stroke='currentColor' />
            <path
                d='M36.2646 17.2002C35.3086 17.2002 34.5312 17.9776 34.5312 18.9335C34.5312 19.8894 35.3087 20.6668 36.2646 20.6668C37.2205 20.6668 37.9979 19.8895 37.9979 18.9335C37.9979 17.9776 37.2205 17.2002 36.2646 17.2002ZM36.2646 19.8002C35.7866 19.8002 35.3979 19.4115 35.3979 18.9335C35.3979 18.4556 35.7866 18.0668 36.2646 18.0668C36.7426 18.0668 37.1312 18.4555 37.1312 18.9335C37.1312 19.4115 36.7425 19.8002 36.2646 19.8002Z'
                fill='currentColor'
            />
            <path
                d='M13.7333 31.5C12.7774 31.5 12 32.2774 12 33.2333C12 34.1892 12.7774 34.9666 13.7333 34.9666C14.6893 34.9666 15.4666 34.1892 15.4666 33.2333C15.4666 32.2774 14.6893 31.5 13.7333 31.5ZM13.7333 34.1C13.2554 34.1 12.8666 33.7113 12.8666 33.2333C12.8666 32.7553 13.2553 32.3666 13.7333 32.3666C14.2113 32.3666 14.6 32.7553 14.6 33.2333C14.6 33.7113 14.2113 34.1 13.7333 34.1Z'
                fill='currentColor'
            />
            <path
                d='M35.4303 17.7476L31.6919 15.9427C31.4761 15.8383 31.2174 15.9293 31.1134 16.1447C31.0094 16.3601 31.1 16.6192 31.3154 16.7232L35.0538 18.528C35.1145 18.5575 35.1786 18.5713 35.2418 18.5713C35.403 18.5713 35.5577 18.4812 35.6322 18.3261C35.7362 18.1107 35.6457 17.8516 35.4303 17.7476Z'
                fill='currentColor'
            />
            <path
                d='M31.4989 34.5332C30.543 34.5332 29.7656 35.3106 29.7656 36.2665C29.7656 37.2224 30.543 37.9999 31.4989 37.9999C32.4549 37.9999 33.2323 37.2225 33.2323 36.2666C33.2323 35.3107 32.4549 34.5332 31.4989 34.5332ZM31.4989 37.1332C31.021 37.1332 30.6323 36.7445 30.6323 36.2665C30.6323 35.7885 31.021 35.3999 31.4989 35.3999C31.9769 35.3999 32.3656 35.7886 32.3656 36.2666C32.3656 36.7446 31.9769 37.1332 31.4989 37.1332Z'
                fill='currentColor'
            />
            <path
                d='M30.6569 35.0806L26.9185 33.2758C26.7027 33.1714 26.444 33.262 26.34 33.4778C26.236 33.6931 26.3266 33.9523 26.542 34.0563L30.2803 35.8611C30.341 35.8906 30.4051 35.9044 30.4684 35.9044C30.6296 35.9044 30.7843 35.8143 30.8588 35.6591C30.9628 35.4437 30.8722 35.1846 30.6569 35.0806Z'
                fill='currentColor'
            />
            <path
                d='M24.9989 23.7002C24.043 23.7002 23.2656 24.4776 23.2656 25.4335C23.2656 26.3895 24.043 27.1668 24.9989 27.1668C25.9549 27.1668 26.7323 26.3894 26.7323 25.4335C26.7323 24.4776 25.9549 23.7002 24.9989 23.7002ZM24.9989 26.3002C24.521 26.3002 24.1323 25.9115 24.1323 25.4335C24.1323 24.9556 24.5209 24.5668 24.9989 24.5668C25.4769 24.5668 25.8656 24.9555 25.8656 25.4335C25.8656 25.9115 25.4769 26.3002 24.9989 26.3002Z'
                fill='currentColor'
            />
            <path
                d='M24.2357 24.2961L21.364 22.058C21.1751 21.9119 20.903 21.9444 20.7556 22.1334C20.6087 22.3223 20.6421 22.5944 20.831 22.7418L23.7027 24.98C23.782 25.0415 23.8756 25.0714 23.9688 25.0714C24.0979 25.0714 24.2253 25.0142 24.3111 24.9046C24.458 24.7156 24.4246 24.4435 24.2357 24.2961Z'
                fill='currentColor'
            />
            <path
                d='M32.7979 23.2666C30.1697 23.2666 28.0312 25.4051 28.0312 28.0333C28.0312 30.6615 30.1697 32.8 32.7979 32.8C35.4261 32.8 37.5646 30.6615 37.5646 28.0333C37.5646 25.4051 35.4261 23.2666 32.7979 23.2666ZM32.7979 31.9332C30.6473 31.9332 28.8979 30.1839 28.8979 28.0332C28.8979 25.8826 30.6473 24.1332 32.7979 24.1332C34.9486 24.1332 36.6979 25.8826 36.6979 28.0332C36.6979 30.1839 34.9486 31.9332 32.7979 31.9332Z'
                fill='currentColor'
            />
            <path
                d='M32.8036 25C31.8477 25 31.0703 25.7774 31.0703 26.7333C31.0703 27.6893 31.8477 28.4666 32.8036 28.4666C33.7596 28.4666 34.5369 27.6892 34.5369 26.7333C34.5369 25.7774 33.7596 25 32.8036 25ZM32.8036 27.6C32.3257 27.6 31.9369 27.2113 31.9369 26.7333C31.9369 26.2554 32.3256 25.8666 32.8036 25.8666C33.2816 25.8666 33.6703 26.2553 33.6703 26.7333C33.6703 27.2113 33.2816 27.6 32.8036 27.6Z'
                fill='currentColor'
            />
            <path
                d='M34.0602 29.333H31.546C30.8055 29.333 30.2031 29.9193 30.2031 30.6395V31.7818C30.2031 31.9365 30.2854 32.0795 30.4194 32.1571C31.1456 32.5774 31.9698 32.7997 32.8031 32.7997C33.6364 32.7997 34.4606 32.5774 35.1869 32.1571C35.3208 32.0799 35.4031 31.9365 35.4031 31.7822V30.64C35.4031 29.9193 34.8008 29.333 34.0602 29.333ZM34.5364 31.5235C33.4579 32.0608 32.1484 32.0608 31.0698 31.5235V30.64C31.0698 30.3973 31.2834 30.1997 31.546 30.1997H34.0602C34.3228 30.1997 34.5364 30.3973 34.5364 30.64V31.5235H34.5364Z'
                fill='currentColor'
            />
            <path
                d='M22.3995 28.4668C19.7713 28.4668 17.6328 30.6053 17.6328 33.2335C17.6328 35.8617 19.7713 38.0001 22.3995 38.0001C25.0277 38.0001 27.1662 35.8616 27.1662 33.2334C27.1662 30.6052 25.0277 28.4668 22.3995 28.4668ZM22.3995 37.1334C20.2489 37.1334 18.4995 35.3841 18.4995 33.2334C18.4995 31.0828 20.2489 29.3334 22.3995 29.3334C24.5501 29.3334 26.2995 31.0828 26.2995 33.2334C26.2995 35.3841 24.5501 37.1334 22.3995 37.1334Z'
                fill='currentColor'
            />
            <path
                d='M22.3974 30.2002C21.4414 30.2002 20.6641 30.9776 20.6641 31.9335C20.6641 32.8895 21.4415 33.6668 22.3974 33.6668C23.3533 33.6668 24.1307 32.8894 24.1307 31.9335C24.1307 30.9776 23.3533 30.2002 22.3974 30.2002ZM22.3974 32.8002C21.9194 32.8002 21.5307 32.4115 21.5307 31.9335C21.5307 31.4556 21.9194 31.0668 22.3974 31.0668C22.8753 31.0668 23.2641 31.4555 23.2641 31.9335C23.2641 32.4115 22.8753 32.8002 22.3974 32.8002Z'
                fill='currentColor'
            />
            <path
                d='M23.654 34.5332H21.1398C20.3992 34.5332 19.7969 35.1195 19.7969 35.8397V36.982C19.7969 37.1367 19.8792 37.2797 20.0131 37.3572C20.7394 37.7776 21.5636 37.9999 22.3969 37.9999C23.2302 37.9999 24.0544 37.7776 24.7806 37.3572C24.9146 37.2801 24.9969 37.1367 24.9969 36.9824V35.8402C24.9969 35.1195 24.3946 34.5332 23.654 34.5332ZM24.1302 36.7237C23.0516 37.261 21.7421 37.261 20.6635 36.7237V35.8402C20.6635 35.5975 20.8771 35.3999 21.1397 35.3999H23.6539C23.9165 35.3999 24.1301 35.5975 24.1301 35.8402L24.1302 36.7237Z'
                fill='currentColor'
            />
            <path
                d='M16.7667 17.6338C14.1385 17.6338 12 19.7723 12 22.4005C12 25.0287 14.1385 27.1672 16.7667 27.1672C19.3949 27.1672 21.5334 25.0287 21.5334 22.4005C21.5333 19.7723 19.3948 17.6338 16.7667 17.6338ZM16.7667 26.3005C14.616 26.3005 12.8667 24.5511 12.8667 22.4005C12.8667 20.2498 14.616 18.5005 16.7667 18.5005C18.9173 18.5005 20.6667 20.2498 20.6667 22.4005C20.6667 24.5511 18.9173 26.3005 16.7667 26.3005Z'
                fill='currentColor'
            />
            <path
                d='M16.7646 19.3662C15.8086 19.3662 15.0312 20.1436 15.0312 21.0995C15.0312 22.0554 15.8087 22.8328 16.7646 22.8328C17.7205 22.8328 18.4979 22.0554 18.4979 21.0995C18.4979 20.1436 17.7205 19.3662 16.7646 19.3662ZM16.7646 21.9662C16.2866 21.9662 15.8979 21.5775 15.8979 21.0995C15.8979 20.6215 16.2866 20.2328 16.7646 20.2328C17.2425 20.2328 17.6312 20.6215 17.6312 21.0995C17.6312 21.5775 17.2425 21.9662 16.7646 21.9662Z'
                fill='currentColor'
            />
            <path
                d='M18.0212 23.7002H15.507C14.7664 23.7002 14.1641 24.2865 14.1641 25.0067V26.149C14.1641 26.3036 14.2464 26.4466 14.3803 26.5242C15.1066 26.9446 15.9307 27.1669 16.7641 27.1669C17.5974 27.1669 18.4216 26.9446 19.1478 26.5242C19.2817 26.4471 19.3641 26.3036 19.3641 26.1494V25.0072C19.3641 24.2865 18.7617 23.7002 18.0212 23.7002ZM18.4974 25.8907C17.4188 26.428 16.1093 26.428 15.0307 25.8907V25.0072C15.0307 24.7645 15.2443 24.5669 15.5069 24.5669H18.0211C18.2837 24.5669 18.4973 24.7645 18.4973 25.0072L18.4974 25.8907Z'
                fill='currentColor'
            />
            <path
                d='M27.1651 12C24.5369 12 22.3984 14.1385 22.3984 16.7667C22.3984 19.3949 24.5369 21.5334 27.1651 21.5334C29.7933 21.5334 31.9318 19.3949 31.9318 16.7667C31.9318 14.1385 29.7933 12 27.1651 12ZM27.1651 20.6667C25.0145 20.6667 23.2651 18.9173 23.2651 16.7667C23.2651 14.616 25.0145 12.8667 27.1651 12.8667C29.3158 12.8667 31.0651 14.616 31.0651 16.7667C31.0651 18.9173 29.3158 20.6667 27.1651 20.6667Z'
                fill='currentColor'
            />
            <path
                d='M27.163 13.7334C26.207 13.7334 25.4297 14.5108 25.4297 15.4667C25.4297 16.4226 26.2071 17.2 27.163 17.2C28.1189 17.2 28.8963 16.4227 28.8963 15.4668C28.8963 14.5109 28.1189 13.7334 27.163 13.7334ZM27.163 16.3334C26.6851 16.3334 26.2963 15.9447 26.2963 15.4667C26.2963 14.9888 26.685 14.6 27.163 14.6C27.641 14.6 28.0297 14.9887 28.0297 15.4667C28.0296 15.9447 27.641 16.3334 27.163 16.3334Z'
                fill='currentColor'
            />
            <path
                d='M28.4274 18.0664H25.9132C25.1727 18.0664 24.5703 18.6527 24.5703 19.3729V20.5152C24.5703 20.6699 24.6526 20.8129 24.7865 20.8905C25.5128 21.3108 26.337 21.5331 27.1703 21.5331C28.0036 21.5331 28.8278 21.3108 29.5541 20.8905C29.688 20.8133 29.7703 20.6699 29.7703 20.5156V19.3734C29.7703 18.6527 29.1679 18.0664 28.4274 18.0664ZM28.9036 20.2569C27.8251 20.7942 26.5155 20.7942 25.4369 20.2569V19.3734C25.4369 19.1307 25.6506 18.9331 25.9132 18.9331H28.4274C28.6899 18.9331 28.9036 19.1307 28.9036 19.3734L28.9036 20.2569Z'
                fill='currentColor'
            />
            <path
                d='M23.1642 16.4842C23.0086 16.3031 22.7352 16.2814 22.5532 16.437L19.5502 19.0067C19.3682 19.1622 19.3469 19.4357 19.5025 19.6177C19.5883 19.7178 19.7096 19.7694 19.8318 19.7694C19.9315 19.7694 20.0316 19.7351 20.1135 19.6649L23.1165 17.0952C23.2985 16.9397 23.3198 16.6662 23.1642 16.4842Z'
                fill='currentColor'
            />
            <path
                d='M32.7159 23.4407L30.1159 19.974C29.9725 19.7821 29.7008 19.7444 29.5093 19.8874C29.3177 20.0308 29.2792 20.3025 29.4226 20.4936L32.0226 23.9603C32.1079 24.0738 32.2379 24.1336 32.3697 24.1336C32.4598 24.1336 32.5513 24.1054 32.6293 24.0469C32.8208 23.9035 32.8594 23.6318 32.7159 23.4407Z'
                fill='currentColor'
            />
            <path
                d='M29.1537 29.4663C29.0103 29.2747 28.7386 29.2362 28.547 29.3796L26.1264 31.1948C25.9349 31.3383 25.8964 31.61 26.0398 31.8011C26.1251 31.9146 26.2551 31.9744 26.3868 31.9744C26.4774 31.9744 26.5684 31.9458 26.6464 31.8877L29.067 30.0725C29.2586 29.9291 29.2972 29.6574 29.1537 29.4663Z'
                fill='currentColor'
            />
            <path
                d='M18.0682 32.7998H15.0349C14.7957 32.7998 14.6016 32.9935 14.6016 33.2331C14.6016 33.4728 14.7957 33.6664 15.0349 33.6664H18.0682C18.3074 33.6664 18.5015 33.4728 18.5015 33.2331C18.5015 32.9935 18.3074 32.7998 18.0682 32.7998Z'
                fill='currentColor'
            />
            <path
                d='M16.7646 12C15.8087 12 15.0312 12.7774 15.0312 13.7333C15.0312 14.6893 15.8087 15.4666 16.7646 15.4666C17.7205 15.4666 18.4979 14.6892 18.4979 13.7333C18.4979 12.7774 17.7205 12 16.7646 12ZM16.7646 14.6C16.2867 14.6 15.8979 14.2113 15.8979 13.7333C15.8979 13.2554 16.2866 12.8666 16.7646 12.8666C17.2426 12.8666 17.6313 13.2553 17.6313 13.7333C17.6312 14.2113 17.2426 14.6 16.7646 14.6Z'
                fill='currentColor'
            />
            <path
                d='M16.7693 14.5996C16.5301 14.5996 16.3359 14.7933 16.3359 15.0329V18.0662C16.3359 18.3059 16.5301 18.4996 16.7693 18.4996C17.0084 18.4996 17.2026 18.3059 17.2026 18.0662V15.0329C17.2026 14.7933 17.0084 14.5996 16.7693 14.5996Z'
                fill='currentColor'
            />
        </svg>
    );
}

MembreIcon.defaultProps = {
    width: 50,
    height: 50,
    className: '',
};
