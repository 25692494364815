import React, { ReactElement } from 'react';
import { cn } from '../../utils/utils';
import { Technology } from '../../types/projects';

type Props = {
    technology: Technology;
    className?: string;
};

export default function TechnologyLogo(props: Props): ReactElement {
    const { technology, className } = props;

    function getLogo(): ReactElement {
        const imgClass = 'h-auto w-full object-contain';
        const directusImgClass = 'p-1';

        switch (technology) {
            case Technology.TAILWIND: {
                return <img src='./logos/technology/tailwind.svg' alt='Tailwind' className={imgClass} />;
            }
            case Technology.AWS: {
                return <img src='./logos/technology/aws.svg' alt='Aws' className={imgClass} />;
            }
            case Technology.TYPESCRIPT: {
                return <img src='./logos/technology/typescript.svg' alt='Typescript' className={imgClass} />;
            }
            case Technology.NODE: {
                return <img src='/logos/technology/node.svg' alt='Node' className={imgClass} />;
            }
            case Technology.REACT: {
                return <img src='/logos/technology/react.svg' alt='React' className={imgClass} />;
            }
            case Technology.DIRECTUS: {
                return (
                    <img src='/logos/technology/directus.svg' alt='Directus' className={imgClass && directusImgClass} />
                );
            }
            default: {
                return <img src='https://img.plho.me/32' alt='placeholder' className={imgClass} />;
            }
        }
    }

    return <div className={cn('h-auto w-6', className || '')}>{getLogo()}</div>;
}

TechnologyLogo.defaultProps = {
    className: '',
};
