/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { ReactElement, useContext } from 'react';
import { Transition } from '@headlessui/react';
import { cn } from '../../../utils/utils';
import { ModalContext } from './modal';

type Props = {
    children: ReactElement | ReactElement[];
    className?: string;
};

export default function ModalContent(props: Props): ReactElement {
    const { children, className } = props;

    const { open, setOpen } = useContext(ModalContext);

    return (
        <>
            {open && <div className='fixed left-0 top-0 z-50 h-screen w-screen bg-black bg-opacity-50' />}
            <Transition
                className='fixed left-0 top-0 z-50 transform transition-all '
                appear
                show={open}
                enter='modal-in'
                enterFrom='scale-50 translate-y-10'
                enterTo='scale-100 translate-y-0'
                leave='ease-in duration-0'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
            >
                <div className='flex h-screen w-screen items-center justify-center' onClick={() => setOpen(false)}>
                    <div
                        className={cn(
                            'left-1/2 top-1/2 mx-10 w-full max-w-xl rounded-2xl bg-white p-5 drop-shadow-2xl',
                            className,
                        )}
                    >
                        {children}
                    </div>
                </div>
            </Transition>
        </>
    );
}

ModalContent.defaultProps = {
    className: '',
};
