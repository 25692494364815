import React, { ReactElement } from 'react';
import { Technology } from '../../types/projects';
import { cn } from '../../utils/utils';
import TechnologyLogo from '../technology/technology-logo';

type Props = {
    technologies: Technology[];
    imgPositioning: 'left' | 'right';
};
// TODO: Technologien auf 3 begrenzen?
export default function ProjectTechnologies(props: Props): ReactElement {
    const { technologies, imgPositioning } = props;

    return (
        <div className='flex flex-col flex-wrap justify-evenly md:justify-between'>
            {technologies.map((item, index) => (
                <div
                    key={item}
                    className={cn(
                        'flex h-16 w-16 items-center justify-center rounded-full border-4 border-gray-900 bg-white',
                        imgPositioning === 'left' && index % 2 === 0 ? 'md:ml-10' : 'md:ml-0',
                        imgPositioning === 'right' && index % 2 === 1 ? 'md:ml-10' : '',
                        imgPositioning === 'left' && index % 2 === 1 ? 'ml-10' : 'ml-0',
                        imgPositioning === 'right' && index % 2 === 0 ? 'ml-10' : '',
                    )}
                >
                    <TechnologyLogo className='flex w-8 self-center' technology={item} />
                </div>
            ))}
        </div>
    );
}
