import { ReactElement } from 'react';

type Args = string | ReactElement;

// Format expects a string with placeholders and a list of arguments to replace the placeholders with.
// It returns a list of ReactElements, which can be rendered directly in JSX.
// Example:
// Strings.Format('Hello {0} and {1} to the {0}! {1}', 'world', <span className="font-bold">John</span>)
function Format(str: string, ...args: Args[]): ReactElement[] {
    const result: ReactElement[] = [];

    const regex = /{(\d+)}/g;
    let match = regex.exec(str);
    let index = 0;

    while (match) {
        const placeholder = match[0];
        const placeholderIndex = Number.parseInt(match[1], 10);

        const text = str.slice(index, match.index);

        // @ts-ignore
        result.push(text);

        const arg = args[placeholderIndex];

        // @ts-ignore
        result.push(arg);

        index = match.index + placeholder.length;
        match = regex.exec(str);

        if (!match) {
            // @ts-ignore
            result.push(str.slice(index));
        }
    }

    return result;
}

const Strings = {
    Format,
};

export default Strings;
