import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function FingerprintIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} width={width} height={height} viewBox='0 0 50 50' fill='none'>
            <path
                d='M16.9786 16.61C17.1339 16.61 17.275 16.5535 17.3809 16.4476C17.4586 16.37 19.3145 14.5493 22.1021 13.646C25.8212 12.4322 29.3427 13.3707 32.5537 16.4194C32.7795 16.6382 33.1395 16.6241 33.3582 16.3982C33.4641 16.2853 33.5205 16.1442 33.5135 15.9959C33.5064 15.8477 33.45 15.7066 33.337 15.6008C31.3893 13.7588 29.3074 12.6085 27.1338 12.1851C25.3907 11.8464 23.5911 11.9734 21.7845 12.5592C18.7147 13.5542 16.6681 15.5655 16.5834 15.6502C16.4776 15.7561 16.4141 15.8972 16.4141 16.0525C16.4141 16.2077 16.4705 16.3488 16.5764 16.4547C16.6822 16.5464 16.8234 16.61 16.9786 16.61Z'
                fill='currentColor'
            />
            <path
                d='M34.6609 24.0056C34.0823 20.2795 32.6143 17.6048 30.2926 16.0664C26.9193 13.8293 22.932 14.7961 22.7697 14.8385C22.4662 14.909 22.2757 15.2195 22.3533 15.523C22.4239 15.8265 22.7344 16.017 23.0378 15.9394C23.1861 15.9041 26.6722 15.0573 29.6362 17.0192C31.704 18.3882 33.0237 20.7947 33.5459 24.1751C33.5671 24.3233 33.6518 24.4574 33.7717 24.5491C33.8705 24.6197 33.9834 24.662 34.1034 24.662C34.1316 24.662 34.1599 24.662 34.1881 24.655C34.4916 24.6055 34.7103 24.3161 34.6609 24.0056Z'
                fill='currentColor'
            />
            <path
                d='M16.4745 24.0133C16.9826 18.0712 20.9275 16.7021 21.0969 16.6456C21.238 16.5962 21.358 16.4974 21.4215 16.3633C21.4921 16.2292 21.4992 16.074 21.4497 15.9328C21.3509 15.6364 21.0263 15.4741 20.7299 15.5729C20.5323 15.6364 15.9099 17.2384 15.3383 23.9215C15.3242 24.0697 15.3736 24.2179 15.4724 24.3379C15.5712 24.4508 15.7053 24.5214 15.8606 24.5355H15.91C16.0441 24.5355 16.1711 24.486 16.277 24.4014C16.3898 24.3026 16.4604 24.1614 16.4745 24.0133Z'
                fill='currentColor'
            />
            <path
                d='M18.8371 23.9705C19.0206 22.5237 19.6064 21.1406 20.4955 20.0679C21.1307 19.3057 21.914 18.7129 22.7609 18.346C23.6642 17.9578 24.631 17.8238 25.6331 17.9437C27.0304 18.1202 28.4277 18.7835 29.8038 19.9268C30.0438 20.1243 30.4037 20.0961 30.6013 19.8562C30.7001 19.7433 30.7424 19.595 30.7283 19.4398C30.7142 19.2845 30.6436 19.1504 30.5236 19.0587C28.9781 17.7743 27.3832 17.0192 25.7672 16.8216C24.5675 16.6734 23.403 16.8357 22.3092 17.3086C21.3 17.7461 20.3685 18.4518 19.6204 19.3481C18.5901 20.5831 17.9126 22.178 17.7079 23.8364C17.5597 25.0149 17.3762 26.017 17.1575 26.8074C16.8752 27.8307 16.5435 28.5082 16.1836 28.8116C16.0707 28.9104 15.9931 29.0445 15.9789 29.1998C15.9648 29.348 16.0143 29.4962 16.106 29.6162C16.2189 29.7503 16.3812 29.8208 16.5435 29.8208C16.6705 29.8208 16.8046 29.7785 16.9105 29.6867C17.4539 29.2351 17.9055 28.3671 18.2513 27.1109C18.4843 26.2569 18.6819 25.1984 18.8371 23.9705Z'
                fill='currentColor'
            />
            <path
                d='M30.2367 26.0451V26.0381C30.1803 25.5229 30.1238 24.9866 30.0744 24.5279C30.0532 24.3444 30.0391 24.2315 30.025 24.1186C29.8697 22.7001 29.7145 21.3522 28.4795 20.2513C27.7879 19.6303 26.8775 19.2069 25.9177 19.0445C25.1273 18.9175 24.3158 18.9669 23.5818 19.2069C22.7632 19.468 22.0504 19.9338 21.4576 20.59C20.6743 21.4651 20.1521 22.6366 19.9121 24.0833C19.9051 24.1115 19.9051 24.1397 19.898 24.1609V24.1821C19.778 25.5371 19.404 27.3437 18.9241 28.8892C18.4795 30.3148 18.0208 31.3028 17.668 31.5992C17.5551 31.698 17.4774 31.8321 17.4633 31.9873C17.4492 32.1356 17.4986 32.2837 17.5903 32.4037C17.6891 32.5166 17.8233 32.5943 17.9785 32.6084C18.1267 32.6225 18.2749 32.5731 18.3949 32.4814C19.1077 31.8886 19.7146 30.1949 20.011 29.2351C20.5191 27.6049 20.9072 25.7065 21.0343 24.281C21.2389 23.0531 21.6694 22.0721 22.3045 21.3594C22.7562 20.8513 23.2996 20.4984 23.9277 20.3008C24.4993 20.1173 25.1203 20.075 25.7413 20.1808C26.4894 20.3078 27.2022 20.6325 27.7314 21.1123C28.6418 21.9239 28.7688 23.0601 28.9029 24.2598C28.9241 24.4222 28.9382 24.5774 28.9523 24.6762C29.0158 25.2267 29.0652 25.7065 29.1146 26.1723C29.3687 28.6211 29.6369 31.1546 30.7166 33.5329C30.8084 33.7446 31.0201 33.8646 31.2318 33.8646C31.3094 33.8646 31.387 33.8504 31.4647 33.8151C31.747 33.6881 31.874 33.3494 31.747 33.0671C30.7378 30.8369 30.4838 28.4022 30.2367 26.0451Z'
                fill='currentColor'
            />
            <path
                d='M27.8035 24.9802C27.9094 23.9357 27.7117 23.0254 27.2248 22.3408C26.9284 21.9174 26.5261 21.5928 26.0604 21.4022C25.6157 21.2187 25.1288 21.1693 24.6631 21.254C24.0421 21.3669 23.4916 21.7197 23.1105 22.242C22.8776 22.5595 22.673 22.9547 22.5036 23.4134C22.3413 23.858 22.2001 24.3732 22.0872 24.9589C22.0026 25.4036 21.9461 25.7987 21.8967 26.1727C21.8049 26.8573 21.7273 27.4571 21.445 28.2687C21.1627 29.0803 20.7675 29.9695 20.2594 30.9292C19.5961 32.1854 18.968 33.1028 18.7139 33.4204C18.5163 33.6603 18.5516 34.0203 18.7916 34.2179C18.9116 34.3167 19.0597 34.359 19.208 34.3449C19.3562 34.3308 19.4973 34.2602 19.589 34.1402C19.9419 33.7097 20.6335 32.6511 21.2616 31.4656C21.7909 30.4705 22.2143 29.5178 22.5177 28.6427C22.8353 27.7182 22.927 27.0407 23.0258 26.328C23.0753 25.9751 23.1246 25.6081 23.2093 25.1776C23.3081 24.6554 23.4352 24.1967 23.5763 23.8156C23.7104 23.4486 23.8657 23.1452 24.035 22.9193C24.2467 22.63 24.5431 22.4395 24.8748 22.383C25.1289 22.3336 25.39 22.3618 25.637 22.4677C25.8981 22.5736 26.131 22.7641 26.3004 23.0111C26.625 23.4698 26.752 24.1191 26.6744 24.8883C26.5333 26.2785 25.9757 28.3039 25.1007 30.5834C24.1762 33.0039 23.0117 35.3963 22.3272 36.2643C22.1367 36.5114 22.179 36.8642 22.426 37.0618C22.5319 37.1395 22.6518 37.1818 22.7718 37.1818C22.9412 37.1818 23.1106 37.1042 23.2165 36.963C23.9716 35.9962 25.1783 33.5403 26.1522 30.9856C27.0695 28.5934 27.6482 26.4692 27.8035 24.9802Z'
                fill='currentColor'
            />
            <path
                d='M32.6526 26.3631C32.5397 24.6341 32.4197 22.8416 31.8199 21.4937C31.6929 21.2114 31.3541 21.0773 31.0718 21.2044C30.7896 21.3314 30.6555 21.6701 30.7825 21.9524C31.2977 23.1098 31.4106 24.7964 31.5164 26.4337C31.6364 28.2333 31.7493 29.934 32.3774 31.0843C32.4479 31.2184 32.5679 31.3172 32.7161 31.3596C32.7726 31.3737 32.822 31.3808 32.8784 31.3808C32.9702 31.3808 33.0619 31.3596 33.1466 31.3102C33.2807 31.2396 33.3795 31.1197 33.4219 30.9715C33.4642 30.8233 33.4501 30.6751 33.3724 30.541C32.8643 29.6094 32.7655 28.0357 32.6526 26.3631Z'
                fill='currentColor'
            />
            <path
                d='M25.5461 24.1119C25.5532 23.9637 25.5038 23.8155 25.3979 23.7026C25.2991 23.5897 25.158 23.5262 25.0027 23.5191C24.6922 23.505 24.424 23.745 24.4099 24.0555C24.304 26.1656 23.8524 28.3109 23.1114 30.2657C22.3845 32.1923 21.3965 33.8578 20.1898 35.2128C19.9851 35.4457 19.9992 35.8055 20.2392 36.0173C20.3451 36.1161 20.4791 36.1584 20.6132 36.1584C20.7685 36.1584 20.9237 36.0949 21.0367 35.9678C22.3351 34.507 23.3937 32.7216 24.1771 30.6679C24.9604 28.6003 25.4332 26.3349 25.5461 24.1119Z'
                fill='currentColor'
            />
            <path
                d='M28.8194 30.816L28.763 30.5337C28.7277 30.329 28.5865 30.1596 28.3889 30.0891C28.2478 30.0396 28.0925 30.0467 27.9514 30.1103C27.8173 30.1738 27.7115 30.2937 27.662 30.4349L27.6409 30.4984C27.2457 31.6134 26.8575 32.7072 26.3706 33.8011C25.8272 35.0361 25.2344 36.1229 24.564 37.118C24.4793 37.245 24.4511 37.3932 24.4793 37.5414C24.5076 37.6896 24.5922 37.8166 24.7193 37.9013C24.8181 37.9649 24.924 38.0001 25.0369 38.0001C25.2203 38.0001 25.3968 37.9154 25.5097 37.7531C26.2154 36.7087 26.8364 35.5654 27.4151 34.2598C27.6339 33.7659 27.8456 33.2436 28.0714 32.6438C28.3185 33.6035 28.6572 34.6409 29.3064 35.7912C29.377 35.9253 29.504 36.0171 29.6452 36.0594C29.7934 36.1018 29.9416 36.0806 30.0757 36.01C30.2098 35.9323 30.3015 35.8124 30.3438 35.6712C30.3862 35.523 30.365 35.3749 30.2944 35.2407C29.4334 33.6953 29.137 32.298 28.8194 30.816Z'
                fill='currentColor'
            />

            <circle cx='25' cy='25' r='24.5' stroke='currentColor' />
        </svg>
    );
}

FingerprintIcon.defaultProps = {
    width: 50,
    height: 50,
    className: '',
};
