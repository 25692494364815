import React, { ReactElement, useRef } from 'react';
import { gsap } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locale';
import Strings from '../utils/string';
import { Player } from '@lottiefiles/react-lottie-player';
import useDimension from '../hooks/use-dimension';

import RollingCirclesMobile from '../assets/lotties/Block2.json';
import RollingCirclesDesktop from '../assets/lotties/Block2_Desktop.json';

export default function BlockTwo(): ReactElement {
    const sceneContainerRef = useRef<HTMLDivElement>(null);
    const LottieAnimation = useRef<Player>(null);

    const contentContainerRef = useRef<HTMLDivElement>(null);
    const secondContentContainerRef = useRef<HTMLDivElement>(null);
    const LottieContainerRef = useRef<HTMLDivElement>(null);
    const firstContentRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const darkBlueCircleRef = useRef<HTMLDivElement>(null);
    const blackCircleWithinRef = useRef<HTMLDivElement>(null);
    const indicatorRef = useRef<HTMLDivElement>(null);

    const mainTimeline = useRef<GSAPTimeline>();
    const firstContentTimeline = useRef<GSAPTimeline>();
    const contentTimeline = useRef<GSAPTimeline>();
    const lottieTimeline = useRef<GSAPTimeline>();
    const initBlackTransitionTimeline = useRef<GSAPTimeline>();
    const blackBackgroundTimeline = useRef<GSAPTimeline>();
    const indicatorTimeline = useRef<GSAPTimeline>();

    const { strings } = useText(local);
    const { dimension, windowSize } = useDimension();

    useGSAP(() => {
        mainTimeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'blockTwo',
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: 'bottom bottom',
                pin: contentContainerRef.current,
                scrub: true,
            },
        });

        firstContentTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: '10% top',
                scrub: true,
                id: 'blowTwoContent',
            },
        });

        firstContentTimeline.current.to(firstContentRef.current, { opacity: 1, translateY: 0 });

        contentTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '23% top',
                end: '40% top',
                scrub: true,
                id: 'contentShow',
            },
        });

        contentTimeline.current.to(firstContentRef.current, { opacity: 0, translateY: -300, height: 0 });
        contentTimeline.current.to(secondContentContainerRef.current, { maxHeight: '70%', translateY: 0 });
        contentTimeline.current.to(contentRef.current, { opacity: 1 }, '<');
        contentTimeline.current.to(LottieContainerRef.current, { opacity: 1 });

        indicatorTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: '23% top',
                scrub: true,
                id: 'line',
            },
        });

        indicatorTimeline.current.from(indicatorRef.current, { width: 0 });

        lottieTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '38% top',
                end: '65% top',
                scrub: true,
                toggleActions: 'onUpdate',
                id: 'lottieBlockTwo',
                onUpdate: ({ progress }) => {
                    const totalFrames = LottieAnimation.current?.state.instance?.totalFrames;
                    const currentFrame = Math.round(progress * (totalFrames || 0));
                    if (progress >= 0.99) {
                        LottieAnimation.current?.pause();
                    } else {
                        LottieAnimation.current?.state?.instance?.goToAndStop(currentFrame, true);
                    }
                },
            },
        });

        initBlackTransitionTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '63% top',
                end: '67% bottom',
                scrub: true,
            },
        });
        initBlackTransitionTimeline.current.to(blackCircleWithinRef.current, { opacity: 1, translateY: 0 });

        blackBackgroundTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '67% top',
                end: 'bottom bottom',
                scrub: true,
                id: 'blackBackground',
            },
        });
        blackBackgroundTimeline.current.to(blackCircleWithinRef.current, { scale: 200, ease: 'power1.in' });
    });

    return (
        <section ref={sceneContainerRef} className='relative flex h-[600vh]'>
            <div
                ref={contentContainerRef}
                className='relative flex h-[100vh] min-h-screen w-full flex-col items-center justify-center space-y-10 overflow-hidden bg-green'
            >
                <div className='w-md md:max-w-4xl'>
                    <div
                        ref={firstContentRef}
                        className='relative z-10 flex h-auto w-full translate-y-20 flex-col items-center space-y-24 px-4 text-center opacity-0'
                    >
                        <div className='flex flex-col items-center justify-center space-y-5'>
                            <h1 className='text-2xl font-bold uppercase text-darkBlue md:text-5xl'>
                                {strings.headline.title}
                                <br />
                                {strings.headline.titleNewLine}
                            </h1>
                            <div className='space-y-10'>
                                <p className='max-w-xs leading-normal text-darkBlue md:max-w-xl'>
                                    {Strings.Format(
                                        strings.headline.description,
                                        <span className='text-xl'>{strings.headline.break}</span>,
                                        <br />,
                                    )}
                                </p>
                                <div className='mx-auto flex w-full max-w-[200px]'>
                                    <div
                                        ref={indicatorRef}
                                        className='relative inline-block h-2 w-full rounded-full bg-white'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    ref={darkBlueCircleRef}
                    className='absolute z-50 h-[100vw] w-[100vw] translate-x-full rounded-full bg-darkBlue opacity-0'
                />
                <div
                    ref={secondContentContainerRef}
                    className='w-md flex max-h-[0%] translate-y-20 flex-col items-center justify-center md:max-w-4xl'
                >
                    <div
                        ref={contentRef}
                        className='relative z-10 mt-5 flex h-auto w-full flex-col items-center px-4 text-center opacity-0'
                    >
                        <h1 className='max-w-xs text-[2.6rem] font-bold leading-normal text-darkBlue md:max-w-4xl md:text-5xl md:leading-normal'>
                            {strings.subline}
                        </h1>
                    </div>
                    <div className='relative h-40 w-[27rem] opacity-0 md:w-[1536px]' ref={LottieContainerRef}>
                        <div
                            className='absolute left-0 top-1/3 z-40 h-52 w-96 bg-transparent md:w-[1536px]'
                            style={
                                windowSize.width > 1536
                                    ? {
                                          background:
                                              'linear-gradient(90deg, rgb(95 233 159) 0%, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 88%, rgb(95 233 159) 100%)',
                                      }
                                    : { background: 'none' }
                            }
                        />

                        <Player
                            ref={LottieAnimation}
                            src={dimension === 'mobile' ? RollingCirclesMobile : RollingCirclesDesktop}
                            autoplay={false}
                            style={dimension === 'mobile' ? { marginTop: '-70%' } : { marginTop: '-30%' }}
                        />
                        <div
                            ref={blackCircleWithinRef}
                            className='absolute bottom-[5.5rem] right-[13rem] z-50 h-6 w-6 rounded-full bg-darkBlue opacity-0 md:-bottom-[2rem] md:right-[46rem]'
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

type LocalSchema = {
    headline: {
        title: string;
        titleNewLine: string;
        description: string;
        break: string;
    };
    subline: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        headline: {
            title: 'Dürfen wir vorstellen:',
            titleNewLine: 'Directus',
            description:
                'Ein Open-Source-Headless-CMS, das die Art und Weise, wie wir Content verwalten, revolutioniert und die absolute Freiheit in der Anbindung bietet. Da ist es egal, ob du eine Webseite, eine App, ein Spiel oder eine andere datengetriebene digitale Experience schaffen möchtest.{1} Ein CMS, das sich ausschließlich um Content-Management kümmert und eine nahtlose Integration mit verschiedenen Datenbanken und Frontend-Anwendungen ermöglicht.',
            break: '',
        },
        subline: 'Bist du bereit, die Ketten zu sprengen und den Daten freien Lauf zu lassen?',
    },
};
