import React, { ReactElement, useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { Player } from '@lottiefiles/react-lottie-player';
import FanningOutCircles from '../assets/lotties/fanning-out-circles.json';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import Strings from '../utils/string';

export default function ReasonTwo(): ReactElement {
    const { strings } = useText(local);

    const sceneContainer = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);
    const lottieRef = useRef<Player>(null);

    const timeline = useRef<GSAPTimeline>();
    const lottieTl = useRef<GSAPTimeline>();

    useGSAP(() => {
        initTimeline();
        initLottieTimeline();
    });

    function initTimeline(): void {
        timeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: 'bottom bottom',
                scrub: true,
            },
        });
    }

    function initLottieTimeline(): void {
        lottieTl.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: '62% top',
                scrub: true,
                pin: contentContainer.current,
                onUpdate: (self) => {
                    const pbInstance = lottieRef.current?.state?.instance;
                    const totalFrames = pbInstance?.totalFrames ?? 0;
                    lottieRef.current?.state?.instance?.goToAndStop(Math.floor(self.progress * totalFrames), true);
                },
            },
        });
    }

    return (
        <section>
            <div className='h-[60vh] w-full bg-primary' />
            <div ref={sceneContainer} className='relative h-[320vh] w-full overflow-hidden bg-primary'>
                <div
                    ref={contentContainer}
                    className='flex h-[100vh] min-h-screen w-full flex-col items-center justify-center md:flex-row md:space-x-20 md:px-20'
                >
                    <div className='mb-10 flex w-full items-center justify-center md:mb-0 md:justify-end'>
                        <p className='w-80 text-2xl leading-tight sm:w-[475px] sm:text-4xl' style={{ lineHeight: 1.5 }}>
                            {Strings.Format(
                                strings.text.base,
                                <span className='font-bold'>{strings.text.api} </span>,
                                <span className='font-bold'>{strings.text.db}</span>,
                            )}
                        </p>
                    </div>
                    <div className='flex w-full justify-center md:justify-start'>
                        <Player ref={lottieRef} className='w-[360px]' autoplay={false} src={FanningOutCircles} />
                    </div>
                </div>
            </div>
        </section>
    );
}

type LocalSchema = {
    text: {
        base: string;
        api: string;
        db: string;
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        text: {
            base: 'State-of-the-Art Interaktion über {0} oder {1}',
            api: 'REST API',
            db: 'GraphQL',
        },
    },
};
