import React, { ReactElement, useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { Player } from '@lottiefiles/react-lottie-player';
import OverlappingCirclesMobile from '../assets/lotties/overlapping-circles-mobile.json';
import OverlappingCirclesDesktop from '../assets/lotties/overlapping-circles-desktop.json';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import Strings from '../utils/string';
import BallTransition from './transitions/ball-transition';
import useDimension from '../hooks/use-dimension';

export default function ReasonOne(): ReactElement {
    const { strings } = useText(local);

    const { dimension } = useDimension();

    const sceneContainer = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);

    const firstText = useRef<HTMLDivElement>(null);
    const secondText = useRef<HTMLDivElement>(null);

    const lottieRef = useRef<Player>(null);
    const blurRef = useRef<HTMLDivElement>(null);

    const ballRef = useRef<HTMLDivElement>(null);

    const timeline = useRef<GSAPTimeline>();
    const lottieTl = useRef<GSAPTimeline>();
    const moveTextTl = useRef<GSAPTimeline>();
    const transitionTimeline = useRef<GSAPTimeline>();

    useGSAP(() => {
        timeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: contentContainer.current,
            },
        });
        initLottieTimeline();
        initMoveTextTl();

        initTransitionTimeline();
    });

    function initLottieTimeline(): void {
        lottieTl.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: '30% top',
                scrub: true,
                onUpdate: (self) => {
                    const pbInstance = lottieRef.current?.state?.instance;
                    const totalFrames = pbInstance?.totalFrames ?? 0;
                    lottieRef.current?.state?.instance?.goToAndStop(Math.floor(self.progress * totalFrames), true);
                },
            },
        });
    }

    function initMoveTextTl(): void {
        moveTextTl.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '25% top',
                end: '55% top',
                scrub: true,
            },
        });

        if (dimension === 'mobile') {
            moveTextTl.current.to(firstText.current, { top: '-=10%', opacity: 0 }, 0);
            moveTextTl.current.to(secondText.current, { top: '20%' }, 0);
            moveTextTl.current.to(secondText.current, { opacity: 1 }, '<70%');
        } else {
            moveTextTl.current.to(firstText.current, { top: '-=10%', opacity: 0 }, 0);
            moveTextTl.current.to(firstText.current, { opacity: 0 }, '<70%');
            moveTextTl.current.to(secondText.current, { top: '40%' }, 0);
            moveTextTl.current.to(secondText.current, { opacity: 1 }, '<70%');
        }
    }

    function initTransitionTimeline(): void {
        transitionTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: ballRef.current,
                start: 'top bottom',
                end: 'top top',
                scrub: true,
            },
        });
        transitionTimeline.current.to(blurRef.current, { backdropFilter: 'blur(8px)' });
    }

    return (
        <section>
            <div className='h-[50vh] w-full bg-primary' />
            <div ref={sceneContainer} className='relative h-[330vh] w-full overflow-hidden bg-primary'>
                <div
                    ref={contentContainer}
                    className='relative h-[100vh] min-h-screen w-full items-center justify-center overflow-hidden'
                >
                    <div className='relative z-0 h-full w-full items-center justify-center'>
                        <div
                            className='absolute top-40 flex w-full flex-col items-center justify-center space-y-5'
                            ref={firstText}
                        >
                            <div className='w-80 md:w-[30rem]'>
                                <p className='w-80 text-2xl sm:text-4xl md:w-[30rem]' style={{ lineHeight: 1.9 }}>
                                    {Strings.Format(
                                        strings.arguments.first.base,
                                        <span className='text-3xl font-bold md:text-5xl'>
                                            <br />
                                            {strings.arguments.first.bold1}
                                        </span>,
                                        <span className='text-3xl font-bold md:text-5xl'>
                                            {strings.arguments.first.bold2}
                                        </span>,
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className='absolute flex h-full w-full items-center justify-center'>
                            <Player
                                ref={lottieRef}
                                className={dimension === 'mobile' ? 'w-[700px]' : 'w-[1440px]'}
                                autoplay={false}
                                src={dimension === 'mobile' ? OverlappingCirclesMobile : OverlappingCirclesDesktop}
                            />
                        </div>
                    </div>
                    <div
                        className='absolute flex w-full flex-col items-center justify-center space-y-6 opacity-0 '
                        ref={secondText}
                    >
                        <p className='w-80 text-2xl sm:w-[430px] sm:text-4xl' style={{ lineHeight: 1.5 }}>
                            {Strings.Format(
                                strings.arguments.second.base,
                                <span className='font-bold'>{strings.arguments.second.bold1}</span>,
                                <span className='font-bold'>{strings.arguments.second.bold2}</span>,
                            )}
                        </p>
                    </div>
                    <div ref={blurRef} className='absolute top-0 z-50 h-full w-full' />
                </div>
            </div>
            <BallTransition ref={ballRef} number={2} />
        </section>
    );
}

type LocalSchema = {
    arguments: {
        first: {
            base: string;
            bold1: string;
            bold2: string;
        };
        second: {
            base: string;
            bold1: string;
            bold2: string;
        };
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        arguments: {
            first: {
                base: 'Entkopplung von {0} und {1}',
                bold1: 'Frontend',
                bold2: 'Backend',
            },
            second: {
                base: 'Saubere Trennung für {0} und {1}',
                bold1: 'bessere Wartbarkeit',
                bold2: 'hohe Flexibilität',
            },
        },
    },
};
