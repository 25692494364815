import React, { ReactElement } from 'react';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import WayPointIcon from '../assets/decorators/way-point-icon';
import TelefonIcon from '../assets/decorators/telefon-icon';
import MailIcon from '../assets/decorators/mail-icon';

export default function FooterContactSection(): ReactElement {
    const { strings } = useText(local);

    return (
        <div className='flex flex-col items-start justify-between space-y-10 border-y-2 border-gray-400 py-8 md:h-24 md:flex-row md:items-center md:space-y-0'>
            <div className='flex flex-row items-center justify-start space-x-5 '>
                <WayPointIcon className='text-mediumGray' />
                <a
                    className='border-b-2 border-white text-start text-xl text-mediumGray hover:border-accentYellow'
                    href={strings.wayPoint.href}
                >
                    {strings.wayPoint.text}
                </a>
            </div>
            <div className='flex flex-row items-center justify-start space-x-5'>
                <TelefonIcon className='text-mediumGray ' />
                <a
                    className='border-b-2 border-white text-start text-xl text-mediumGray hover:border-accentYellow'
                    href={strings.telefon.href}
                >
                    {strings.telefon.text}
                </a>
            </div>
            <div className='flex flex-row items-center justify-start space-x-5'>
                <MailIcon className='text-mediumGray' />
                <a
                    className='border-b-2 border-white text-start text-xl text-mediumGray hover:border-accentYellow'
                    href={strings.mail.href}
                >
                    {strings.mail.text}
                </a>
            </div>
        </div>
    );
}

type LocalSchema = {
    wayPoint: {
        text: string;
        href: string;
    };
    telefon: {
        text: string;
        href: string;
    };
    mail: {
        text: string;
        href: string;
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        wayPoint: {
            text: 'Gasstraße 14, 22761 Hamburg',
            href: 'https://maps.app.goo.gl/u29ak8VWcUjTQJk18',
        },
        telefon: {
            text: '040-6887 8690',
            href: 'tel:+494068878690',
        },
        mail: {
            text: 'hello@poprocket.com',
            href: 'mailto:hello@poprocket.com',
        },
    },
};
