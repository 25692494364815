import React, { ReactElement } from 'react';
import { FooterNavItemType } from '../../types/navigation';
import useText, { LocalizedStrings, SupportedLanguages } from '../../hooks/use-locals';

export default function FooterNavigation(): ReactElement {
    const { strings } = useText(local);

    const footerItems = [
        { type: FooterNavItemType.AGB },
        { type: FooterNavItemType.DSVGO },
        { type: FooterNavItemType.IMPRINT },
        { type: FooterNavItemType.JOB },
    ];

    return (
        <div className='flex w-auto flex-col items-start justify-start md:flex-row md:items-center md:justify-center md:space-x-5'>
            {footerItems.map((item) => (
                <a className='items-center justify-center text-mediumGray' href={strings.navigation[item.type].link}>
                    {strings.navigation[item.type].name}
                </a>
            ))}
        </div>
    );
}

type LocalSchema = {
    navigation: {
        [key in FooterNavItemType]: {
            name: string;
            link: string;
        };
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        navigation: {
            [FooterNavItemType.AGB]: {
                name: 'AGB',
                link: 'https://agb.poprocket.com/',
            },
            [FooterNavItemType.DSVGO]: {
                name: 'Datenschutz',
                link: 'https://poprocket.com/datenschutz',
            },

            [FooterNavItemType.IMPRINT]: {
                name: 'Impressum',
                link: 'https://poprocket.com/impressum',
            },
            [FooterNavItemType.JOB]: {
                name: 'Jobs',
                link: 'https://de.linkedin.com/company/pop-rocket-labs',
            },
        },
    },
};
