import React, { ReactElement, useRef } from 'react';
import { cn } from '../utils/utils';
import { gsap } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locale';
import Strings from '../utils/string';
import { Player } from '@lottiefiles/react-lottie-player';

import ExplodingCirclesMobile from '../assets/lotties/Block1.json';
import ExplodingCirclesDesktop from '../assets/lotties/Block1_Desktop.json';

export default function SceneThree(): ReactElement {
    const sceneContainerRef = useRef<HTMLDivElement>(null);
    const contentContainerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const blackBackgroundRef = useRef<HTMLDivElement>(null);
    const headlineRef = useRef<HTMLDivElement>(null);
    const h1Ref = useRef<HTMLDivElement>(null);
    const descriptionRef = useRef<HTMLDivElement>(null);
    const lottieContainerRef = useRef<HTMLDivElement>(null);
    const greenBackgroundRef = useRef<HTMLDivElement>(null);
    const lottieAnimationRef = useRef<Player>(null);
    const indicatorRef = useRef<HTMLDivElement>(null);

    const mainTimeline = useRef<GSAPTimeline>();
    const backgroundChangeTimeline = useRef<GSAPTimeline>();
    const ballRollInTimeline = useRef<GSAPTimeline>();
    const lottieTimeline = useRef<GSAPTimeline>();
    const headlineTimeline = useRef<GSAPTimeline>();
    const hideLottieTimeline = useRef<GSAPTimeline>();
    const greenBackgroundTimeline = useRef<GSAPTimeline>();
    const indicatorTimeline = useRef<GSAPTimeline>();

    const { strings } = useText(local);

    useGSAP(() => {
        mainTimeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'sceneThree',
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: 'bottom top',
                pin: contentContainerRef.current,
                scrub: true,
                onEnter() {
                    contentContainerRef.current?.style.setProperty('opacity', '1');
                },
                onLeaveBack() {
                    contentContainerRef.current?.style.setProperty('opacity', '0');
                },
                onLeave() {
                    contentContainerRef.current?.style.setProperty('opacity', '0');
                },
                onEnterBack() {
                    contentContainerRef.current?.style.setProperty('opacity', '1');
                },
            },
        });

        ballRollInTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: '60% top',
                scrub: true,
                id: 'ballrollin',
            },
        });

        ballRollInTimeline.current.to(blackBackgroundRef.current, rollInBlackCircleKeyframe);

        backgroundChangeTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '20% top',
                end: '22% top',
                scrub: true,
                id: 'bgchange',
            },
        });

        backgroundChangeTimeline.current.to(contentContainerRef.current, changeBackgroundColorKeyframe);

        lottieTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '5% top',
                end: '47% top',
                scrub: true,
                id: 'lottie',
                toggleActions: 'onUpdate',
                onUpdate: ({ progress }) => {
                    const totalFrames = lottieAnimationRef.current?.state.instance?.totalFrames;
                    const currentFrame = Math.round(progress * (totalFrames || 0));

                    lottieAnimationRef.current?.setSeeker(currentFrame, false);
                },
            },
        });

        headlineTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '12% top',
                end: '33% top',
                scrub: true,
            },
        });

        headlineTimeline.current.to(headlineRef.current, showKeyframe);
        headlineTimeline.current.to(h1Ref.current, headlineColorChangeKeyframe);

        hideLottieTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '40% top',
                end: '50% top',
                scrub: true,
            },
        });

        hideLottieTimeline.current.to(lottieContainerRef.current, hidenScaleKeyframe);
        hideLottieTimeline.current.to(descriptionRef.current, reduceTranslateY);
        hideLottieTimeline.current.to(descriptionRef.current, shownSlideKeyframe);

        indicatorTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '55% top',
                end: 'bottom 10%',
                scrub: true,
                id: 'line',
            },
        });

        indicatorTimeline.current.from(indicatorRef.current, showIndicator);

        greenBackgroundTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '70% top',
                end: 'bottom top',
                scrub: true,
                id: 'greenBall',
            },
        });

        greenBackgroundTimeline.current.to(greenBackgroundRef.current, rollInGreenKeyframe);
    });

    return (
        <section ref={sceneContainerRef} className='relative flex h-[450vh]'>
            <div
                ref={contentContainerRef}
                className='relative z-10 flex h-[100vh] min-h-screen w-full items-center justify-center overflow-hidden bg-blue opacity-0'
            >
                <div
                    ref={blackBackgroundRef}
                    className='absolute h-[250vw] w-[250vw] -translate-x-full rounded-full bg-darkBlue md:h-[200vw] md:w-[200vw]'
                />
                <div
                    ref={greenBackgroundRef}
                    className='opacity-1 absolute z-50 h-[50vw] w-[50vw] translate-x-[150%] translate-y-[150%] rounded-full bg-green md:translate-x-[200%] md:translate-y-[200%]'
                />
                <div
                    ref={contentRef}
                    className='relative flex h-full max-w-md flex-col items-center justify-evenly md:max-w-4xl md:justify-center'
                >
                    <div className='relative flex h-[250px] w-[250px] items-center justify-center rounded-full bg-pink md:h-[350px] md:w-[350px]'>
                        <div ref={lottieContainerRef}>
                            <Player
                                ref={lottieAnimationRef}
                                src={window.innerWidth < 768 ? ExplodingCirclesMobile : ExplodingCirclesDesktop}
                                autoplay={false}
                                className={cn(
                                    window.innerWidth < 768 ? 'w-[22rem] md:w-[32rem]' : 'w-[1536px] md:w-[1536px]',
                                )}
                            />
                        </div>
                    </div>
                    <div
                        ref={headlineRef}
                        className='absolute flex flex-col items-center justify-center space-y-14 opacity-0 md:space-y-52'
                    >
                        <h1 ref={h1Ref} className='w-[20rem] text-5xl font-bold uppercase text-white md:w-[30rem]'>
                            {strings.headline.title}
                        </h1>
                        <div ref={descriptionRef} className='flex flex-col space-y-10 opacity-0'>
                            <p className='text-xl text-darkBlue md:w-[35rem]'>
                                {Strings.Format(
                                    strings.headline.description,
                                    <span>
                                        <br />
                                        <br />
                                    </span>,
                                    <span className='font-bold'>{strings.headline.bold}</span>,
                                )}
                            </p>
                            <div className='mx-auto flex w-full max-w-[200px]'>
                                <div
                                    ref={indicatorRef}
                                    className='relative inline-block h-2 w-full rounded-full bg-white'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const showKeyframe: GSAPTweenVars = {
    opacity: 1,
};

const shownSlideKeyframe: GSAPTweenVars = {
    opacity: 1,
    translateY: 0,
};

const reduceTranslateY: GSAPTweenVars = {
    translateY: 0,
    marginBottom: '0',
    marginTop: '10px',
};

const hidenScaleKeyframe: GSAPTweenVars = {
    opacity: 0,
    scale: 10,
};

const showIndicator: GSAPTweenVars = {
    width: 0,
};

const rollInBlackCircleKeyframe: GSAPTweenVars = {
    translateX: '100%',
};

const changeBackgroundColorKeyframe: GSAPTweenVars = {
    backgroundColor: '#F9AAFF',
};

const headlineColorChangeKeyframe: GSAPTweenVars = {
    color: '#0C1220',
};

const rollInGreenKeyframe: GSAPTweenVars = {
    opacity: 1,
    width: window.innerWidth < 768 ? '100vh' : '150vw',
    height: window.innerWidth < 768 ? '100vh' : '150vw',
    transform: 'translate',
    translateX: '0%',
    translateY: '0%',
    scale: 1.2,
};

type LocalSchema = {
    headline: {
        title: string;
        description: string;
        bold: string;
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        headline: {
            title: 'Befreit die Daten!',
            description:
                'Herkömmliche CMS Systeme bestehen aus starren “Out of the Box” Lösungen, die direkt mit einem Frontend verschmolzen sind.{0} Das bindet Daten an {1} und einen spezifischen Einsatzzweck und macht sie langfristig unflexibel, pflegeintensiv und (wenn wir mal ehrlich sind) oftmals überladen.',
            bold: 'unflexible Strukturen',
        },
    },
};
