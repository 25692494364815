import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function WayPointIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg width={width} height={height} className={className} viewBox='0 0 18 23' fill='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9 2.30002C5.27208 2.30002 2.25 5.38928 2.25 9.20008C2.25 10.3821 2.65944 11.7034 3.35419 13.0731C4.04389 14.4328 4.97655 15.7657 5.93092 16.9503C6.88255 18.1316 7.83731 19.1427 8.55539 19.8593C8.71683 20.0204 8.86597 20.1663 9 20.2955C9.13403 20.1663 9.28317 20.0204 9.44461 19.8593C10.1627 19.1427 11.1174 18.1316 12.0691 16.9503C13.0235 15.7657 13.9561 14.4328 14.6458 13.0731C15.3406 11.7034 15.75 10.3821 15.75 9.20008C15.75 5.38928 12.7279 2.30002 9 2.30002ZM9 21.8502C8.26786 22.7233 8.26766 22.7232 8.26743 22.723L8.26504 22.7209L8.25937 22.7159L8.23965 22.6984C8.22283 22.6835 8.19875 22.662 8.1679 22.6342C8.10621 22.5786 8.01738 22.4979 7.90529 22.3938C7.68119 22.1857 7.36362 21.8843 6.98368 21.5051C6.22519 20.7482 5.2112 19.675 4.19408 18.4125C3.1797 17.1533 2.14361 15.6831 1.35674 14.1318C0.574938 12.5905 0 10.893 0 9.20008C0 4.11901 4.02944 0 9 0C13.9706 0 18 4.11901 18 9.20008C18 10.893 17.4251 12.5905 16.6433 14.1318C15.8564 15.6831 14.8203 17.1533 13.8059 18.4125C12.7888 19.675 11.7748 20.7482 11.0163 21.5051C10.6364 21.8843 10.3188 22.1857 10.0947 22.3938C9.98262 22.4979 9.89379 22.5786 9.8321 22.6342C9.80125 22.662 9.77717 22.6835 9.76035 22.6984L9.74063 22.7159L9.73496 22.7209L9.73319 22.7224C9.73296 22.7226 9.73214 22.7233 9 21.8502ZM9 21.8502L8.26743 22.723C8.68873 23.0921 9.31084 23.0925 9.73214 22.7233L9 21.8502ZM9 6.90006C7.75736 6.90006 6.75 7.92981 6.75 9.20008C6.75 10.4703 7.75736 11.5001 9 11.5001C10.2426 11.5001 11.25 10.4703 11.25 9.20008C11.25 7.92981 10.2426 6.90006 9 6.90006ZM4.5 9.20008C4.5 6.65954 6.51472 4.60004 9 4.60004C11.4853 4.60004 13.5 6.65954 13.5 9.20008C13.5 11.7406 11.4853 13.8001 9 13.8001C6.51472 13.8001 4.5 11.7406 4.5 9.20008Z'
                fill='currentColor'
            />
        </svg>
    );
}

WayPointIcon.defaultProps = {
    width: 18,
    height: 23,
    className: '',
};
