import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
};

export default function PrlLogo(props: IconProps): ReactElement {
    const { width, height } = props;

    return (
        <svg width={width} height={height} viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_8181_8149)'>
                <path
                    d='M0.117188 5.59108C0.217615 5.62629 9.06643 10.7665 9.06643 10.7665V0.445312L0.117188 5.59108Z'
                    fill='#F6BB5D'
                />
                <path d='M0.117188 15.8899L9.06643 10.7682L0.117188 5.59277V15.8899Z' fill='#D9DDDF' />
                <path d='M9.06643 10.7686V21.0379L0.117188 15.8902L9.06643 10.7686Z' fill='#F7F6F7' />
                <path
                    d='M18.0136 5.59108C17.9113 5.62629 9.0625 10.7665 9.0625 10.7665V0.445312L18.0136 5.59108Z'
                    fill='#EC7E54'
                />
                <path d='M18.0117 15.8899L9.0625 10.7682L18.0117 5.59277V15.8899Z' fill='#C22435' />
                <path d='M18.0117 15.8902L9.0625 10.7686V21.0379L18.0117 15.8902Z' fill='#2486C4' />
            </g>
            <defs>
                <clipPath id='clip0_8181_8149'>
                    <rect width={width} height={height} fill='white' transform='translate(0.078125 -0.00585938)' />
                </clipPath>
            </defs>
        </svg>
    );
}

PrlLogo.defaultProps = {
    width: 18,
    height: 21,
};
