import React, { ReactElement, useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import useText from '../hooks/use-locals';
import { LocalizedStrings, SupportedLanguages } from '../hooks/use-locale';
import ArrowCircle from '../assets/decorators/arrow-circle';
import BallTransition from './transitions/ball-transition';

export default function BlockFour(): ReactElement {
    const { strings } = useText(local);

    const timeline = useRef<GSAPTimeline>();
    const transitionTimeline = useRef<GSAPTimeline>();
    const textSwitchTimeline = useRef<GSAPTimeline>();
    const indicatorTimeline = useRef<GSAPTimeline>();

    const sceneContainer = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);

    const ballRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const questionRef = useRef<HTMLDivElement>(null);
    const blurRef = useRef<HTMLDivElement>(null);
    const indicatorRef = useRef<HTMLDivElement>(null);

    useGSAP(() => {
        timeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: 'bottom center',
                scrub: true,
                pin: contentContainer.current,
                anticipatePin: 1,
            },
        });

        textSwitchTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '25% top',
                end: '80% bottom',
                scrub: true,
                id: 'textSwitch',
            },
        });

        textSwitchTimeline.current.to(textRef.current, { opacity: 0, top: '-=10%' });

        textSwitchTimeline.current.fromTo(questionRef.current, { top: '+=10%' }, { opacity: 1, top: '-=10%' }, '<50%');

        indicatorTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '0% top',
                end: '50% bottom',
                scrub: true,
                id: 'line',
            },
        });

        indicatorTimeline.current.from(indicatorRef.current, showIndicator);

        transitionTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: ballRef.current,
                start: 'top bottom',
                end: 'top top',
                scrub: true,
            },
        });

        transitionTimeline.current.to(blurRef.current, { backdropFilter: 'blur(8px)' });
        transitionTimeline.current.to(questionRef.current, { opacity: 0, top: '-=15%' });
    });

    return (
        <section>
            <div ref={sceneContainer} className='relative h-[300vh] w-full'>
                <div ref={contentContainer} className='relative h-[100vh] min-h-screen w-full overflow-hidden bg-pink'>
                    <div className='relative h-full w-full'>
                        <div
                            ref={textRef}
                            className='absolute flex h-full w-full flex-col items-center justify-center space-y-10 text-black'
                        >
                            <p className='mx-auto flex px-5 text-2xl text-black sm:w-[460px] md:px-0'>{strings.text}</p>

                            <div className='mx-auto flex w-full max-w-[200px]'>
                                <div
                                    ref={indicatorRef}
                                    className='relative inline-block h-2 w-full rounded-full bg-white'
                                />
                            </div>
                        </div>
                        <div ref={questionRef} className='absolute flex h-full w-full opacity-0'>
                            <div className='absolute flex h-full w-full flex-col items-center justify-center space-y-20 text-black'>
                                <p className='w-64 text-center text-5xl font-bold md:w-full'>{strings.question}</p>
                                <p className='text-center text-5xl font-bold md:w-full'>{strings.answer}</p>
                            </div>
                            <ArrowCircle
                                width={50}
                                height={50}
                                className='absolute bottom-[15%] flex w-full items-center justify-center text-black'
                            />
                        </div>
                    </div>
                    <div ref={blurRef} className='absolute top-0 z-30 h-full w-full' />
                </div>
            </div>
            <BallTransition ref={ballRef} number={1} />
        </section>
    );
}

type LocalSchema = {
    text: string;
    question: string;
    answer: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        text: 'Wir bei Pop Rocket haben auf der Suche nach frischen, robusten und praxisbewährten Lösungen bereits mehrfach erfolgreich auf Directus gesetzt. ',
        question: 'Warum wir überzeugt sind?',
        answer: 'Zeigen wir dir!',
    },
};

const showIndicator: GSAPTweenVars = {
    width: 0,
};
