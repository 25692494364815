import React, { ForwardedRef } from 'react';
import { forwardRef } from 'react';

type Props = {
    number: number;
};

const BallTransition = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { number } = props;

    return (
        <div ref={ref} className='relative z-30 h-[170vw] overflow-hidden bg-transparent sm:h-[150vw]'>
            <div className='absolute left-1/2 z-30 h-full w-[170vw] -translate-x-1/2 items-center rounded-full bg-blue sm:w-[150vw]'>
                <p className='absolute left-1/2 top-1/2 z-40 flex -translate-x-1/2 -translate-y-1/2 justify-center text-transition font-bold text-white'>
                    {number}.
                </p>
            </div>
        </div>
    );
});

export default BallTransition;
