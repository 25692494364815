import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
    style?: React.CSSProperties;
};

export default function Arrow(props: IconProps): ReactElement {
    const { width, height, className, style } = props;

    return (
        <svg className={className} width={width} height={height} viewBox='0 0 31 105' fill='none' style={style}>
            <path d='M15 0.666016L15 102.666' stroke='white' strokeWidth='2' />
            <path d='M1.08594 89.0547L15.2946 103.243L29.5026 89.0547' stroke='white' strokeWidth='2' />
        </svg>
    );
}
Arrow.defaultProps = {
    width: 31,
    height: 105,
    className: '',
    style: {},
};
