import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function GameIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 50 50'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle cx='25' cy='25' r='24.5' stroke='currentColor' />

            <path
                d='M21.9678 29.766H20.2347V28.0329C20.2347 27.7936 20.0407 27.5996 19.8015 27.5996H18.0684C17.8291 27.5996 17.6351 27.7936 17.6351 28.0329V29.766H15.902C15.6627 29.766 15.4688 29.96 15.4688 30.1992V31.9323C15.4688 32.1716 15.6627 32.3656 15.902 32.3656H17.6351V34.0987C17.6351 34.3379 17.8291 34.5319 18.0684 34.5319H19.8015C20.0407 34.5319 20.2347 34.3379 20.2347 34.0987V32.3656H21.9678C22.2071 32.3656 22.4011 32.1716 22.4011 31.9323V30.1992C22.4011 29.96 22.2071 29.766 21.9678 29.766ZM21.5345 31.499H19.8015C19.5622 31.499 19.3682 31.693 19.3682 31.9323V33.6654H18.5016V31.9323C18.5016 31.693 18.3076 31.499 18.0684 31.499H16.3353V30.6325H18.0684C18.3076 30.6325 18.5016 30.4385 18.5016 30.1992V28.4661H19.3682V30.1992C19.3682 30.4385 19.5622 30.6325 19.8015 30.6325H21.5345V31.499Z'
                fill='currentColor'
            />
            <path
                d='M37.9547 30.2743C37.6224 27.2886 34.8183 24.4953 31.83 24.1768C31.5762 24.1488 31.3211 24.1344 31.0657 24.1335H26.2997V22.4004H25.4332V21.5339C25.4332 20.5767 24.6573 19.8008 23.7001 19.8008H21.5338C21.0552 19.8008 20.6672 19.4129 20.6672 18.9343C20.6672 18.4557 21.0552 18.0677 21.5338 18.0677H28.4661C29.4232 18.0677 30.1992 17.2918 30.1992 16.3347C30.1992 15.3775 29.4232 14.6016 28.4661 14.6016H26.2997C25.8211 14.6016 25.4332 14.2136 25.4332 13.735V12.002H24.5667V13.735C24.5667 14.6922 25.3426 15.4681 26.2997 15.4681H28.4661C28.9447 15.4681 29.3326 15.8561 29.3326 16.3347C29.3326 16.8133 28.9447 17.2012 28.4661 17.2012H21.5338C20.5766 17.2012 19.8007 17.9771 19.8007 18.9343C19.8007 19.8914 20.5766 20.6674 21.5338 20.6674H23.7001C24.1787 20.6674 24.5667 21.0553 24.5667 21.5339V22.4004H23.7001V24.1335H18.9341C18.6785 24.1341 18.4231 24.1486 18.169 24.1768C15.1794 24.4975 12.377 27.2908 12.0456 30.2764C11.6103 34.0802 14.341 37.5167 18.1448 37.952C19.6938 38.1292 21.2572 37.7791 22.5827 36.9584C23.1534 36.5848 23.8057 36.3543 24.4843 36.2864C24.8196 36.2599 25.1563 36.2587 25.4917 36.2829C26.1811 36.3496 26.8439 36.5827 27.4232 36.9623C30.68 38.975 34.9519 37.9665 36.9647 34.7096C37.7837 33.3844 38.1325 31.8219 37.9547 30.2743ZM24.5667 23.267H25.4332V24.1335H24.5667V23.267ZM35.5869 35.11C33.628 37.301 30.3796 37.7712 27.8799 36.2257C27.1818 35.7711 26.3833 35.494 25.5536 35.4185C25.3708 35.4052 25.1862 35.3985 24.9999 35.3985C24.8028 35.3985 24.61 35.4068 24.4159 35.4219C23.5989 35.5002 22.8129 35.7746 22.1248 36.2218C19.2759 37.9844 15.5376 37.1038 13.7749 34.255C13.0574 33.0952 12.7516 31.7275 12.9069 30.3726C13.192 27.8068 15.6937 25.3146 18.2621 25.0386C18.4853 25.0137 18.7096 25.0008 18.9341 25.0001H31.0657C31.2902 25.0002 31.5145 25.0122 31.7377 25.036C34.3057 25.312 36.807 27.8042 37.0929 30.37C37.2972 32.0932 36.7483 33.8207 35.5869 35.11Z'
                fill='currentColor'
            />
            <path
                d='M31.069 26.7334C30.1119 26.7334 29.3359 27.5093 29.3359 28.4665C29.3359 29.4236 30.1119 30.1996 31.069 30.1996C32.0262 30.1996 32.8021 29.4236 32.8021 28.4665C32.8021 27.5093 32.0262 26.7334 31.069 26.7334ZM31.069 29.333C30.5904 29.333 30.2025 28.9451 30.2025 28.4665C30.2025 27.9879 30.5904 27.5999 31.069 27.5999C31.5476 27.5999 31.9356 27.9879 31.9356 28.4665C31.9356 28.9451 31.5476 29.333 31.069 29.333Z'
                fill='currentColor'
            />
            <path
                d='M31.069 31.9326C30.1119 31.9326 29.3359 32.7085 29.3359 33.6657C29.3359 34.6228 30.1119 35.3988 31.069 35.3988C32.0262 35.3988 32.8021 34.6228 32.8021 33.6657C32.8021 32.7085 32.0262 31.9326 31.069 31.9326ZM31.069 34.5322C30.5904 34.5322 30.2025 34.1443 30.2025 33.6657C30.2025 33.1871 30.5904 32.7992 31.069 32.7992C31.5476 32.7992 31.9356 33.1871 31.9356 33.6657C31.9356 34.1443 31.5476 34.5322 31.069 34.5322Z'
                fill='currentColor'
            />
            <path
                d='M28.03 29.333C27.0728 29.333 26.2969 30.1089 26.2969 31.0661C26.2969 32.0232 27.0728 32.7992 28.03 32.7992C28.9871 32.7992 29.763 32.0232 29.763 31.0661C29.763 30.1089 28.9871 29.333 28.03 29.333ZM28.03 31.9326C27.5514 31.9326 27.1634 31.5447 27.1634 31.0661C27.1634 30.5875 27.5514 30.1995 28.03 30.1995C28.5086 30.1995 28.8965 30.5875 28.8965 31.0661C28.8965 31.5447 28.5085 31.9326 28.03 31.9326Z'
                fill='currentColor'
            />
            <path
                d='M34.1003 29.333C33.1431 29.333 32.3672 30.1089 32.3672 31.0661C32.3672 32.0232 33.1431 32.7992 34.1003 32.7992C35.0574 32.7992 35.8333 32.0232 35.8333 31.0661C35.8333 30.1089 35.0574 29.333 34.1003 29.333ZM34.1003 31.9326C33.6217 31.9326 33.2337 31.5447 33.2337 31.0661C33.2337 30.5875 33.6217 30.1995 34.1003 30.1995C34.5789 30.1995 34.9668 30.5875 34.9668 31.0661C34.9668 31.5447 34.5788 31.9326 34.1003 31.9326Z'
                fill='currentColor'
            />
        </svg>
    );
}

GameIcon.defaultProps = {
    width: 50,
    height: 50,
    className: '',
};
