import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import CookieConsentBanner from '../components/modals/cookie-consent/cookie-consent-banner';
import { ReactTagManager } from 'react-gtm-ts';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

type CookieConsentContextType = {
    isBannerHidden: boolean;
    setIsBannerHidden: (value: boolean) => void;

    allow: (value: boolean) => void;
};

export const CookieConsentContext = React.createContext<CookieConsentContextType>({
    isBannerHidden: false,
    setIsBannerHidden: () => {},
    allow: () => {},
});

type Props = {
    children: ReactNode | ReactNode[];
};

export default function CookieConsentProvider(props: Props): ReactElement {
    const { children } = props;

    const [isBannerHidden, setIsBannerHidden] = useState(false);
    const allowed = cookies.get('cookies_allowed');

    function initTagManager(): void {
        const tagManagerArgs = {
            code: 'GTM-583GRT6L',
        };
        ReactTagManager.init(tagManagerArgs);
        cookies.set('cookies_allowed', 'true', { path: '/' });
    }

    function allow(value: boolean): void {
        setIsBannerHidden(true);

        if (value) {
            initTagManager();
        } else {
            cookies.set('cookies_allowed', 'false', { path: '/' });
        }
    }

    const value = useMemo(
        () => ({
            isBannerHidden,
            setIsBannerHidden,
            allow,
        }),
        [isBannerHidden],
    );

    useEffect(() => {
        if (allowed === true) {
            initTagManager();
            setIsBannerHidden(true);
            return;
        }
        if (allowed === false) {
            setIsBannerHidden(true);
            return;
        }

        setIsBannerHidden(false);
    }, [allowed]);

    return (
        <CookieConsentContext.Provider value={value}>
            {children}
            {!isBannerHidden && allowed === undefined && createPortal(<CookieConsentBanner />, document.body)}
        </CookieConsentContext.Provider>
    );
}
