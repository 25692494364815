export enum NavItemType {
    EXPERIENCE = 'experience',
    SERVICE = 'service',
    CREDENTIALS = 'credentials',
    TEAM = 'team',
}

export enum FooterNavItemType {
    JOB = 'jobs',
    DSVGO = 'dsvgo',
    AGB = 'agb',
    IMPRINT = 'imprint',
}
