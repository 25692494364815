import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function ArrowRight(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 21 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M0 6.5L18.999 6.5' stroke='currentColor' strokeWidth='2' />
            <path d='M13.5071 12L18.9993 6.49988L13.5071 1' stroke='currentColor' strokeWidth='2' />
        </svg>
    );
}
ArrowRight.defaultProps = {
    width: 21,
    height: 13,
    className: '',
};
