import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import LocalProvider from './providers/local-provider';
import GsapProvider from './providers/gsap-provider';
import CookieConsentProvider from './providers/cookie-consent-provider';

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);

root.render(
    <React.StrictMode>
        <LocalProvider>
            <GsapProvider>
                <CookieConsentProvider>
                    <App />
                </CookieConsentProvider>
            </GsapProvider>
        </LocalProvider>
    </React.StrictMode>,
);
