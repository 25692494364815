import React, { ReactElement, useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import useText from '../hooks/use-locals';
import { LocalizedStrings, SupportedLanguages } from '../hooks/use-locale';
import Strings from '../utils/string';
import useDimension from '../hooks/use-dimension';

export default function SceneTwelve(): ReactElement {
    const { strings } = useText(local);

    const windowDim = useDimension();

    const timeline = useRef<GSAPTimeline>();

    const transitionInTimeline = useRef<GSAPTimeline>();
    const textTimeline = useRef<GSAPTimeline>();
    const openSlotTimeline = useRef<GSAPTimeline>();
    const pinkBallsTimeline = useRef<GSAPTimeline>();
    const transitionOutTimeline = useRef<GSAPTimeline>();

    const sceneContainer = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);
    const firstContent = useRef<HTMLDivElement>(null);

    const ballTransitionInRef = useRef<HTMLDivElement>(null);
    const transitionInNumberRef = useRef<HTMLDivElement>(null);

    const argOneRef = useRef<HTMLDivElement>(null);
    const argTwoRef = useRef<HTMLDivElement>(null);

    const disclaimerTextRef = useRef<HTMLDivElement>(null);

    const slotRef = useRef<HTMLDivElement>(null);
    const slotDivRef = useRef<HTMLDivElement>(null);

    const pinkBallCount = 7;
    const pinkBallsRef = useRef<HTMLDivElement[]>([]);

    const ballTransitionOutRef = useRef<HTMLDivElement>(null);
    const textOutRef = useRef<HTMLDivElement>(null);

    const screenInnerHeight = window.innerHeight;

    function percentToPixelY(percent: number): number {
        return (percent / 100) * screenInnerHeight;
    }
    function percentToPixelX(percent: number): number {
        const divWidth = gsap.getProperty(sceneContainer.current, 'width') as number;
        return (percent / 100) * divWidth;
    }

    useGSAP(() => {
        timeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: 'bottom bottom',
                id: 'scene-twelve',
                scrub: true,
                pin: contentContainer.current,
                onEnter: () => {
                    gsap.set(contentContainer.current, { opacity: 1 });
                },
                onLeaveBack: () => {
                    gsap.set(contentContainer.current, { opacity: 0 });
                },
            },
        });

        initTransitionInTimeline();
        initTextTimeline();
        initOpenSlotTimeline();
        initPinkBallsTimeline();
        initTransitionOutTimeline();
    });

    function initTransitionInTimeline(): GSAPTimeline {
        transitionInTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: '20% top',
                scrub: true,
            },
        });

        transitionInTimeline.current.to(transitionInNumberRef.current, { opacity: 0 }, 0);
        transitionInTimeline.current.to(ballTransitionInRef.current, { top: '110%' }, 0);
        transitionInTimeline.current.to(argOneRef.current, { opacity: 1 }, '<=+70%');

        return transitionInTimeline.current;
    }

    function initTextTimeline(): GSAPTimeline {
        textTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '30% top',
                end: '50% top',
                scrub: true,
                ariaLabel: 'textTimeline',
            },
        });

        textTimeline.current.to(argOneRef.current, { opacity: 0 });
        textTimeline.current.to(disclaimerTextRef.current, { opacity: 1, bottom: 5, scale: 1 }, '<');
        textTimeline.current.to(argTwoRef.current, { opacity: 1 }, '<+90%');

        return textTimeline.current;
    }

    function initOpenSlotTimeline(): GSAPTimeline {
        openSlotTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '15% top',
                end: '25% top',
                scrub: true,
                ariaLabel: 'openSlotTimeline',
            },
        });
        openSlotTimeline.current.fromTo(slotDivRef.current, { opacity: 0 }, { opacity: 1 }, '<5%');
        openSlotTimeline.current.fromTo(slotRef.current, { height: 0 }, { height: '208px' });

        return openSlotTimeline.current;
    }

    function initPinkBallsTimeline(): GSAPTimeline {
        pinkBallsTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '25% top',
                end: '55% top',
                scrub: true,
                ariaLabel: 'pinkBallsTimeline',
            },
        });

        const ballPath = [
            { x: percentToPixelX(30), y: percentToPixelY(30) },
            { x: percentToPixelX(40), y: percentToPixelY(50) },
            { x: percentToPixelX(50), y: percentToPixelY(100) },
        ];

        pinkBallsRef.current.forEach((ball, index) => {
            pinkBallsTimeline.current?.to(
                ball,
                {
                    motionPath: {
                        path: ballPath,
                        curviness: 2,
                    },
                },
                index * 0.1,
            );
        });

        pinkBallsTimeline.current.to(
            ballTransitionOutRef.current,
            {
                left: '50%',
                top: '50%',
            },
            pinkBallCount * 0.1,
        );
        return pinkBallsTimeline.current;
    }

    function initTransitionOutTimeline(): GSAPTimeline {
        transitionOutTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '55% top',
                end: 'bottom bottom',
                scrub: true,
            },
        });

        transitionOutTimeline.current.to(ballTransitionOutRef.current, {
            height: windowDim.dimension === 'mobile' ? '150vw' : '150vh',
            width: windowDim.dimension === 'mobile' ? '150vw' : '150vh',
        });

        transitionOutTimeline.current.to(textOutRef.current, { opacity: 1 });
        transitionOutTimeline.current.to(firstContent.current, { opacity: 0 });
        transitionOutTimeline.current.to(ballTransitionOutRef.current, { scale: 0.3 });
        return transitionOutTimeline.current;
    }

    return (
        <section>
            <div ref={sceneContainer} className='relative h-[600vh] w-full overflow-hidden'>
                <div ref={contentContainer} className='relative h-[100vh] min-h-screen w-full bg-primary opacity-0'>
                    <div ref={firstContent} className='absolute h-full w-full'>
                        <div className='relative top-1/4 z-20 flex flex-col items-center'>
                            <p
                                ref={argOneRef}
                                className='absolute w-72 items-center justify-center text-4xl opacity-0 md:w-96 md:text-5xl'
                                style={{ lineHeight: 1.3 }}
                            >
                                {Strings.Format(
                                    strings.arguements.one.base,
                                    <span className='font-bold'> {strings.arguements.one.highlight}</span>,
                                )}
                            </p>
                            <p
                                ref={argTwoRef}
                                className='absolute -top-16 w-[310px] items-center justify-center text-xl opacity-0 md:w-[45rem] md:text-3xl'
                                style={{ lineHeight: 1.5 }}
                            >
                                {Strings.Format(
                                    strings.arguements.two.base,
                                    <span className='text-4xl font-bold md:text-6xl'>
                                        <br />
                                        {strings.arguements.two.highlight}
                                        <br />
                                    </span>,
                                )}
                            </p>
                        </div>

                        <div
                            ref={ballTransitionInRef}
                            className='absolute h-[700px] w-[700px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-blue sm:h-[880px] sm:w-[880px]'
                            style={{ top: '50%', left: '50%' }}
                        >
                            <p
                                ref={transitionInNumberRef}
                                className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-9xl font-bold'
                            >
                                {strings.transitionInNumber}
                            </p>
                        </div>
                        <div
                            ref={disclaimerTextRef}
                            className='absolute -bottom-10 left-1/2 z-30 max-w-[960px] -translate-x-1/2 scale-50 text-xs opacity-0'
                        >
                            {Strings.Format(
                                strings.disclaimer.base,
                                <span>
                                    <br />
                                    {strings.disclaimer.disclaimerHighlight}
                                </span>,
                            )}
                        </div>
                        <div
                            ref={slotRef}
                            className='absolute bottom-0 left-1/2 h-52 w-56 -translate-x-1/2 bg-primary'
                        />
                        <div
                            ref={slotDivRef}
                            className='absolute bottom-0 left-1/2 z-20 flex h-44 w-60 -translate-x-1/2 bg-blue '
                        />
                        {Array.from({ length: pinkBallCount }).map((_, index) => (
                            <div
                                key={`pink-ball-${index.toString()}`}
                                ref={(el) => {
                                    if (el) {
                                        pinkBallsRef.current[index] = el;
                                    }
                                }}
                                className='absolute z-10 h-24 w-24 -translate-x-1/2 -translate-y-1/2 rounded-full bg-pink'
                                style={{ top: -96 }}
                            />
                        ))}
                    </div>

                    <div
                        ref={ballTransitionOutRef}
                        className='absolute z-40 h-24 w-24 -translate-x-1/2 -translate-y-1/2 rounded-full bg-pink'
                        style={{ top: -96 }}
                    >
                        <div
                            ref={textOutRef}
                            className='absolute left-1/2 top-1/2 z-40 flex w-64 -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center space-y-1 text-black opacity-0 md:w-[600px]'
                        >
                            <p
                                className='pb-3 text-4xl font-bold text-darkBlue md:text-7xl'
                                style={{ lineHeight: 1.3 }}
                            >
                                {strings.circleSentences.one}
                            </p>
                            <p className='text-4xl font-bold text-white md:text-7xl' style={{ lineHeight: 1.3 }}>
                                {strings.circleSentences.two}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

type LocalSchema = {
    arguements: {
        one: {
            base: string;
            highlight: string;
        };
        two: {
            base: string;
            highlight: string;
        };
    };
    circleSentences: {
        one: string;
        two: string;
    };
    transitionInNumber: string;
    disclaimer: {
        base: string;
        disclaimerHighlight: string;
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        arguements: {
            one: {
                base: 'Keine {0}',
                highlight: 'Lizenzgebühren',
            },
            two: {
                base: 'Directus ist Open Source, wird {0} und von einer großen Comminuty belebt',
                highlight: 'aktiv weiterentwickelt ',
            },
        },
        circleSentences: {
            one: 'Einzigartiger Tech Stack.',
            two: 'Einzigartige Anwendungen.',
        },
        transitionInNumber: '5.',
        disclaimer: {
            base: '*Directus ist grundsätzlich kostenlos. {0}',
            disclaimerHighlight: 'Je nach Projektanforderung und Techstack könnten jedoch Lizenzgebühren anfallen.',
        },
    },
};
