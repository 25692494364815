import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function MailIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 23 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.25 2.75C0.25 1.50736 1.25736 0.5 2.5 0.5H20.5C21.7426 0.5 22.75 1.50736 22.75 2.75V16.25C22.75 17.4926 21.7426 18.5 20.5 18.5H2.5C1.25736 18.5 0.25 17.4926 0.25 16.25V2.75ZM4.20842 2.75L11.5 9.13014L18.7916 2.75H4.20842ZM20.5 4.24486L12.2408 11.4717C11.8167 11.8428 11.1833 11.8428 10.7592 11.4717L2.5 4.24486V16.25H20.5V4.24486Z'
                fill='currentColor'
            />
        </svg>
    );
}
MailIcon.defaultProps = {
    width: 23,
    height: 19,
    className: '',
};
