import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function BugermenuIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg width={width} height={height} viewBox='0 0 25 25' fill='currentColor' className={className}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.53125 6.40039H20.5312V8.40039H4.53125V6.40039ZM4.53125 11.4004H20.5312V13.4004H4.53125V11.4004ZM20.5312 16.4004H4.53125V18.4004H20.5312V16.4004Z'
                fill='currentColor'
            />
        </svg>
    );
}

BugermenuIcon.defaultProps = {
    width: 25,
    height: 25,
    className: '',
};
