export enum ProjectType {
    WEBAPP = 'web-app',
    REACT = 'react-app',
    REACTNATIVE = 'react-native-app',
}

export enum Technology {
    TAILWIND = 'tailwind',
    AWS = 'aws',
    TYPESCRIPT = 'typescript',
    NODE = 'node',
    REACT = 'react',
    DIRECTUS = 'directus',
}

export type Company = {
    name: string;
    logo: Image;
};

export type Image = {
    src: string;
    alt: string;
};

export type Project = {
    id: string;
    type?: ProjectType;
    company: Company;
    content: ProjectContent;
    technologies: Technology[];
    accentcolor: string;
    image: Image;
};

export type ProjectContent = {
    title: string;
    description: string;
    bulletpoints: string[];
};
