import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function ContentIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} width={width} height={height} viewBox='0 0 50 50' fill='none'>
            <circle cx='25' cy='25' r='24.5' stroke='currentColor' />
            <path
                d='M36.1746 23.1963H34.5153V14.4309C34.5153 13.4244 33.6966 12.6055 32.6904 12.6055H21.329C21.0486 12.6055 20.8212 12.8328 20.8212 13.1133C20.8212 13.3937 21.0486 13.6211 21.329 13.6211H32.6903C33.1366 13.6211 33.4996 13.9844 33.4996 14.4309V20.8096L30.8008 18.886C30.62 18.7571 30.3764 18.7607 30.1993 18.8948L27.213 21.1581L22.4667 17.2025C22.2803 17.0472 22.0102 17.0454 21.8218 17.1982L16.0547 21.8735V14.4309C16.0547 13.9857 16.417 13.6225 16.8622 13.6212C17.1427 13.6204 17.3694 13.3924 17.3686 13.112C17.3678 12.832 17.1406 12.6056 16.8608 12.6056C16.8603 12.6056 16.8598 12.6056 16.8593 12.6056C15.8556 12.6084 15.0391 13.4273 15.0391 14.4309V24.1687C15.0391 25.1749 15.858 25.9936 16.8645 25.9936H22.7209V27.4568H13.8252C12.8188 27.4567 12 28.2755 12 29.282V35.5692C12 36.5756 12.8188 37.3944 13.8252 37.3944H23.4868C24.4932 37.3944 25.3119 36.5756 25.3119 35.5692V33.772H31.6351C31.9155 33.772 32.1429 33.5446 32.1429 33.2641C32.1429 32.9837 31.9155 32.7563 31.6351 32.7563H25.3122V29.2818C25.3122 28.3835 24.6264 27.5961 23.7366 27.4739V25.0217C23.7366 24.5752 24.0996 24.2119 24.5459 24.2119H36.1746C36.6211 24.2119 36.9844 24.5752 36.9844 25.0217V31.9465C36.9844 32.393 36.6211 32.7563 36.1746 32.7563H36.0998C35.8194 32.7563 35.592 32.9836 35.592 33.2641C35.592 33.5446 35.8194 33.7719 36.0998 33.7719H36.1746C37.1811 33.7719 38 32.9531 38 31.9465V25.0217C38 24.0152 37.1811 23.1963 36.1746 23.1963ZM24.2964 35.5691C24.2964 36.0155 23.9332 36.3787 23.4868 36.3787H13.8252C13.3788 36.3787 13.0156 36.0155 13.0156 35.5691V29.282C13.0156 28.8356 13.3788 28.4724 13.8252 28.4724H23.2273C23.4335 28.4724 23.6389 28.459 23.8312 28.5495C24.1119 28.6815 24.2963 28.9719 24.2963 29.282L24.2964 35.5691ZM24.5459 23.1963C23.5698 23.1963 22.7447 24.0019 22.7215 24.978H16.8645C16.418 24.978 16.0547 24.6149 16.0547 24.1686V23.1809L22.1372 18.25L28.0722 23.1962L24.5459 23.1963ZM29.6588 23.1963L28.014 21.8256L30.5154 19.9297L33.4997 22.0568V23.1963H29.6588Z'
                fill='currentColor'
            />
            <path
                d='M18.9539 13.5828C19.1593 13.6683 19.4053 13.6056 19.5443 13.4316C19.6759 13.2669 19.6912 13.0341 19.5836 12.8529C19.3515 12.4621 18.7392 12.5697 18.6502 13.0145C18.6032 13.2495 18.7331 13.4907 18.9539 13.5828Z'
                fill='currentColor'
            />
            <path
                d='M28.8954 31.8206H30.3438C30.5644 31.8206 30.7597 31.6783 30.8272 31.4683L31.3421 29.8683L33.5719 30.2235C33.5985 30.2277 33.6252 30.2298 33.6518 30.2298C33.7718 30.2298 33.8889 30.1873 33.9814 30.1083C34.0945 30.0118 34.1596 29.8707 34.1596 29.722V28.0784H34.257C34.5375 28.0784 34.7648 27.851 34.7648 27.5706C34.7648 27.2901 34.5375 27.0628 34.257 27.0628H34.1596V25.6563C34.1596 25.5076 34.0945 25.3665 33.9814 25.27C33.8683 25.1735 33.7188 25.1314 33.5719 25.1548L28.8552 25.906H27.5698C26.9627 25.906 26.4688 26.4 26.4688 27.0071V28.3711C26.4688 28.9783 26.9627 29.4722 27.5698 29.4722H28.3876V31.3128C28.3876 31.5933 28.6149 31.8206 28.8954 31.8206ZM29.9738 30.805H29.4032V29.5595L29.5033 29.5755L30.3273 29.7067L29.9738 30.805ZM33.144 29.1269L29.4031 28.5311V26.8472L33.144 26.2514V29.1269ZM27.4844 28.3711V27.0071C27.4844 26.96 27.5228 26.9216 27.5699 26.9216H28.3876V28.4566H27.5698C27.5228 28.4566 27.4844 28.4183 27.4844 28.3711Z'
                fill='currentColor'
            />
            <path
                d='M34.1327 32.8421C33.9522 32.7218 33.7099 32.7313 33.5386 32.864C33.3708 32.994 33.3025 33.2222 33.3688 33.4233C33.5167 33.872 34.1698 33.8797 34.3286 33.4352C34.4055 33.2195 34.3253 32.9682 34.1327 32.8421Z'
                fill='currentColor'
            />
            <path
                d='M20.6897 31.8488L17.5917 30.1868C17.2591 30.0084 16.8438 30.2577 16.8438 30.6343V34.182C16.8438 34.5699 17.2827 34.8184 17.6156 34.6157L20.7136 32.7301C20.8692 32.6354 20.9622 32.4646 20.9572 32.2825C20.9524 32.1004 20.8502 31.9349 20.6897 31.8488ZM17.8594 33.2784V31.4829L19.4273 32.3241L17.8594 33.2784Z'
                fill='currentColor'
            />
            <path
                d='M28.9396 16.462C28.9396 15.5007 28.1576 14.7188 27.1963 14.7188C26.2351 14.7188 25.4531 15.5007 25.4531 16.462C25.4531 17.4232 26.2351 18.2052 27.1963 18.2052C28.1576 18.2052 28.9396 17.4232 28.9396 16.462ZM26.4688 16.462C26.4688 16.0608 26.7951 15.7344 27.1963 15.7344C27.5976 15.7344 27.9239 16.0608 27.9239 16.462C27.9239 16.8631 27.5976 17.1896 27.1963 17.1896C26.7951 17.1896 26.4688 16.8632 26.4688 16.462Z'
                fill='currentColor'
            />
        </svg>
    );
}

ContentIcon.defaultProps = {
    width: 50,
    height: 50,
    className: '',
};
