import React, { ReactElement, ReactNode } from 'react';

type Props = {
    icon: ReactNode;
    title: string;
};

export default function Feature(props: Props): ReactElement {
    const { icon, title } = props;

    return (
        <div className='mt-4 flex flex-col items-center justify-center '>
            {icon}
            <p className='mt-4 max-w-[140px] text-center text-xs font-bold uppercase'>{title}</p>
        </div>
    );
}
