import React, { ReactElement } from 'react';
import ColoredPageLink from '../components/footer/colored-page-link';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import { NavItemType } from '../types/navigation';

export default function FooterPageLinks(): ReactElement {
    const { strings } = useText(local);

    const pageLinks = [
        { type: NavItemType.EXPERIENCE, className: 'text-[#ec7e54]' },
        { type: NavItemType.SERVICE, className: 'text-[#c22435]' },
        { type: NavItemType.TEAM, className: 'text-[#2486c4]' },
    ];

    return (
        <div className='m-auto flex w-auto flex-col justify-between space-y-14 py-14 md:flex-row md:space-x-28 md:space-y-0'>
            {pageLinks.map((link) => (
                <ColoredPageLink
                    key={strings.navigation[link.type].name}
                    title={strings.navigation[link.type].name}
                    href={strings.navigation[link.type].link}
                    linkText={strings.navigation[link.type].linkDescription}
                    className={link.className}
                />
            ))}
        </div>
    );
}

type LocalSchema = {
    navigation: {
        [key in NavItemType]: {
            name: string;
            link: string;
            linkDescription: string;
        };
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        navigation: {
            [NavItemType.EXPERIENCE]: {
                name: 'Spannende Expeditionen',
                link: 'https://poprocket.com/referenzen',
                linkDescription: 'Unsere Referenzen',
            },
            [NavItemType.SERVICE]: {
                name: 'Eine klare Mission',
                link: 'https://poprocket.com/leistungen',
                linkDescription: 'Zu unseren Leistungen',
            },
            [NavItemType.TEAM]: {
                name: 'Die Besatzung',
                link: 'https://poprocket.com/team',
                linkDescription: 'Triff das Team',
            },
            [NavItemType.CREDENTIALS]: {
                name: '',
                link: '',
                linkDescription: '',
            },
        },
    },
};
