import React, { ReactElement } from 'react';
import ProjectHeadline from '../components/projects/project-headline';
import { Project } from '../types/projects';
import ProjectSummary from '../components/projects/project-summary';
import ProjectImage from '../components/projects/project-image';
import { cn } from '../utils/utils';
import ProjectTechnologies from '../components/projects/project-technologies';
import useDimension from '../hooks/use-dimension';

type Props = {
    project: Project;
    imgPositioning: 'left' | 'right';
};

export default function ProjectSection(props: Props): ReactElement {
    const { project, imgPositioning } = props;

    const { dimension } = useDimension();

    return (
        <div className='justify-center space-y-20 overflow-hidden md:space-y-10'>
            {project.type && <ProjectHeadline type={project.type} accentcolor={project.accentcolor} />}
            <div
                className={cn(
                    `flex flex-col items-center gap-2 space-y-14 md:justify-center md:gap-20`,
                    imgPositioning === 'right' ? 'md:flex-row-reverse' : 'md:flex-row',
                    imgPositioning === 'left' ? ' ' : '',
                )}
            >
                <div
                    className={cn(
                        `relative m-0 flex flex-row`,
                        imgPositioning === 'left' ? 'md:ml-10 md:flex-row-reverse' : 'md:flex-row',
                        imgPositioning === 'right' ? 'flex-row-reverse md:mr-10' : '',
                        imgPositioning === 'right' && dimension === 'mobile' ? '-right-8' : '',
                        imgPositioning === 'left' && dimension === 'mobile' ? '-left-8' : '',
                    )}
                >
                    <ProjectImage image={project.image} color={project.accentcolor} />
                    <ProjectTechnologies technologies={project.technologies} imgPositioning={imgPositioning} />
                </div>
                <ProjectSummary
                    logo={project.company.logo}
                    content={project.content}
                    accentcolor={project.accentcolor}
                />
            </div>
        </div>
    );
}
