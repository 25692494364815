import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function TelefonIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox='0 0 23 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.15244 2.5C2.82169 4.87498 2.4957 11.2892 7.34936 16.3295C12.203 21.3698 18.3796 21.0313 20.6667 20.6878V15.828L15.8611 14.9963L15.1356 16.5031C14.9521 16.8842 14.577 17.125 14.1667 17.125V16C14.1667 17.125 14.1661 17.125 14.1656 17.125L14.1644 17.125L14.162 17.125L14.1565 17.125L14.1432 17.1248C14.1332 17.1246 14.1211 17.1243 14.1069 17.1239C14.0785 17.1229 14.0418 17.1212 13.9973 17.1181C13.9083 17.112 13.7881 17.1003 13.6411 17.0785C13.3472 17.0349 12.9445 16.9503 12.4699 16.786C11.5169 16.4561 10.283 15.8079 9.06729 14.5455C7.85162 13.2831 7.22742 12.0017 6.90975 11.012C6.75154 10.5191 6.67005 10.101 6.62807 9.79582C6.60706 9.64312 6.59589 9.51826 6.58996 9.42591C6.58699 9.37971 6.58534 9.34158 6.58442 9.31209C6.58396 9.29734 6.58369 9.28475 6.58353 9.27438L6.58337 9.2605L6.58334 9.25482L6.58333 9.2523L6.58333 9.25112C6.58333 9.25056 6.58333 9.25 7.66666 9.25H6.58333C6.58333 8.87385 6.76435 8.52259 7.06573 8.31394L9.58435 6.57028L8.01654 2.5H3.15244ZM8.82978 9.79685L10.7862 8.4424C11.6387 7.8522 11.9766 6.72255 11.5961 5.73465L10.0282 1.66437C9.6992 0.81014 8.9025 0.25 8.01654 0.25H3.09583C2.11161 0.25 1.18517 0.960057 1.0236 2.06901C0.656012 4.59194 0.155677 12.0411 5.81729 17.9205C11.4789 23.7999 18.6522 23.2803 21.0817 22.8986C22.1496 22.7308 22.8333 21.7687 22.8333 20.7466V15.828C22.8333 14.7281 22.0676 13.7895 21.0229 13.6086L16.2173 12.7769C15.2791 12.6145 14.3486 13.1066 13.9232 13.9901L13.5478 14.7697C13.4299 14.7396 13.2981 14.701 13.1551 14.6515C12.4831 14.4189 11.5503 13.9421 10.5994 12.9545C9.64837 11.9669 9.18923 10.9983 8.96523 10.3005C8.90471 10.1119 8.86113 9.94238 8.82978 9.79685Z'
                fill='currentColor'
            />
        </svg>
    );
}

TelefonIcon.defaultProps = {
    width: 23,
    height: 24,
    className: '',
};
