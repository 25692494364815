import React, { ReactElement, useContext } from 'react';
import useText, { LocalizedStrings, SupportedLanguages } from '../../../hooks/use-locals';
import { Technology } from '../../../types/projects';
import { ModalContext } from '../core/modal';
import TechnologyLogo from '../../technology/technology-logo';
import Button from '../../button/button';

type Props = {
    technology: Technology;
};

export default function TechnologyModalContent(props: Props): ReactElement {
    const { technology } = props;
    const { strings } = useText(local);
    const { setOpen } = useContext(ModalContext);

    return (
        <div className='flex flex-col items-center justify-center space-y-5'>
            <div className='rounded-full bg-white p-2'>
                <TechnologyLogo technology={technology} className='h-10 w-10' />
            </div>

            <p className='font-bold uppercase text-black '>{strings.technologies[technology].title}</p>
            <p className='w-fit pb-10 text-left leading-relaxed text-black'>
                {strings.technologies[technology].content}
            </p>

            <Button
                variant='outlined'
                severity='secondary'
                title={strings.buttonClose}
                onClick={() => setOpen(false)}
                className='w-fit border-darkBlue text-darkBlue'
            />
        </div>
    );
}

type LocalSchema = {
    technologies: {
        [key in Technology]: {
            title: string;
            content: string;
        };
    };
    buttonClose: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        technologies: {
            [Technology.REACT]: {
                title: 'React / React Native',
                content:
                    'React Native ist ein plattformübergreifendes Framework für mobile App-Entwicklung, das auf JavaScript und React basiert. Es ermöglicht die Erstellung von nativen Apps für iOS und Android mit einer gemeinsamen Codebasis, was effizientere Entwicklung und Wartung ermöglicht.',
            },
            [Technology.TAILWIND]: {
                title: 'Tailwind CSS',
                content:
                    'Tailwind CSS ist ein innovatives CSS-Framework, das Entwicklern erlaubt, durch einfaches Hinzufügen von CSS-Klassen schnell und individuell gestaltete Benutzeroberflächen zu erstellen. Mit seiner großen Auswahl an vorgefertigten Utility-Klassen macht es das Entwickeln von Websites oder Apps effizienter.',
            },
            [Technology.NODE]: {
                title: 'Node.js',
                content:
                    'Node.js ist eine serverseitige JavaScript-Plattform, die Entwicklern ermöglicht, JavaScript außerhalb von Webbrowsern zu verwenden. Dank seiner effizienten und flexiblen Natur ist es ideal für die Entwicklung schneller Webanwendungen mit reibungsloser Echtzeitkommunikation. ',
            },
            [Technology.TYPESCRIPT]: {
                title: 'Typescript',
                content:
                    'TypeScript ist eine von Microsoft entwickelte Programmiersprache, die die Stärken von JavaScript erweitert, indem sie Typisierung hinzufügt. Dadurch werden Fehler frühzeitig erkannt, die Code-Wartung vereinfacht und die Lesbarkeit des Codes verbessert.',
            },
            [Technology.AWS]: {
                title: 'AWS',
                content:
                    'Mit AWS Cloud-Computing können Sie schnell und kostengünstig auf eine Vielzahl von IT-Ressourcen zugreifen, ohne eigene Infrastruktur betreiben zu müssen. Von Rechenleistung über Datenbanken bis hin zu künstlicher Intelligenz bietet AWS alles für Ihre Anwendungsbedürfnisse.',
            },
            [Technology.DIRECTUS]: {
                title: 'DIRECTUS',
                content: '',
            },
        },
        buttonClose: 'Schließen',
    },
};
