import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function FileIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} width={width} height={height} viewBox='0 0 50 50' fill='none'>
            <circle cx='25' cy='25' r='24.5' stroke='currentColor' />
            <path
                d='M34.7137 15.4458H32.5209V13.253C32.521 12.5638 31.957 12 31.2682 12H15.292C14.6029 12 14.0391 12.5638 14.0391 13.253V33.3012C14.0391 33.9903 14.6029 34.5542 15.2921 34.5542H17.4849V36.747C17.4849 37.4361 18.0484 38 18.7379 38H29.3882C30.077 38 31.0519 37.6135 31.5534 37.1414L35.0537 33.8466C35.5558 33.3745 35.9662 32.4241 35.9662 31.735L35.9668 16.6989C35.9667 16.0096 35.4028 15.4458 34.7137 15.4458ZM17.4848 16.6988V33.6144H15.292C15.1223 33.6144 14.9788 33.471 14.9788 33.3012V13.253C14.9788 13.0833 15.1223 12.9398 15.292 12.9398H31.2682C31.4377 12.9398 31.5815 13.0833 31.5815 13.253V15.4458H18.7378C18.0483 15.4458 17.4848 16.0096 17.4848 16.6988ZM34.4096 33.1624L30.9093 36.4569C30.8376 36.5243 30.7442 36.5904 30.6411 36.6533V33.771C30.6411 33.5558 30.7279 33.3622 30.871 33.2181C31.0155 33.075 31.209 32.988 31.4242 32.988H34.5584C34.5098 33.054 34.46 33.1148 34.4096 33.1624ZM35.0263 31.7349C35.0263 31.8279 35.0093 31.9357 34.9837 32.0481H31.4242C30.4735 32.05 29.7032 32.8203 29.7013 33.771V37.0222C29.5895 37.0448 29.4817 37.0601 29.3881 37.0601H18.7378V37.0601C18.5677 37.0601 18.4246 36.9167 18.4246 36.7469V16.6988C18.4246 16.529 18.5677 16.3856 18.7378 16.3856H34.7136C34.8834 16.3856 35.0269 16.529 35.0269 16.6988L35.0263 31.7349Z'
                fill='currentColor'
            />
            <path
                d='M31.2651 19.8311H22.1808C21.9211 19.8311 21.7109 20.0412 21.7109 20.3009C21.7109 20.5606 21.9212 20.7708 22.1808 20.7708H31.2651C31.5245 20.7708 31.735 20.5606 31.735 20.3009C31.735 20.0412 31.5245 19.8311 31.2651 19.8311Z'
                fill='currentColor'
            />
            <path
                d='M31.2651 26.0967H22.1808C21.9211 26.0967 21.7109 26.3069 21.7109 26.5666C21.7109 26.8263 21.9212 27.0364 22.1808 27.0364H31.2651C31.5245 27.0364 31.735 26.8263 31.735 26.5666C31.735 26.3068 31.5245 26.0967 31.2651 26.0967Z'
                fill='currentColor'
            />
            <path
                d='M27.1928 29.2285H22.1808C21.9211 29.2285 21.7109 29.4387 21.7109 29.6984C21.7109 29.9581 21.9211 30.1683 22.1808 30.1683H27.1928C27.4522 30.1683 27.6624 29.9581 27.6624 29.6984C27.6624 29.4387 27.4522 29.2285 27.1928 29.2285Z'
                fill='currentColor'
            />
            <path
                d='M31.2651 22.9639H22.1808C21.9211 22.9639 21.7109 23.1741 21.7109 23.4337C21.7109 23.6934 21.9212 23.9036 22.1808 23.9036H31.2651C31.5245 23.9036 31.735 23.6934 31.735 23.4337C31.735 23.1741 31.5245 22.9639 31.2651 22.9639Z'
                fill='currentColor'
            />
        </svg>
    );
}

FileIcon.defaultProps = {
    width: 50,
    height: 50,
    className: '',
};
