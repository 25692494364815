import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function ChatIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} width={width} height={height} viewBox='0 0 50 50' fill='none'>
            <circle cx='25' cy='25' r='24.5' stroke='currentColor' />
            <path
                fill='currentColor'
                d='M36.6958 22.8825C36.4328 22.8825 36.2197 23.0959 36.2197 23.3586V25.0552C36.2197 26.0288 35.4275 26.821 34.4539 26.821H31.7403C31.614 26.821 31.4931 26.871 31.4036 26.9602L28.1138 30.2502V27.2971C28.1138 27.034 27.9006 26.821 27.6377 26.821H20.8216C19.848 26.821 19.0558 26.0289 19.0558 25.0552V17.8535C19.0558 16.88 19.848 16.0879 20.8216 16.0879H34.4539C35.4275 16.0879 36.2197 16.88 36.2197 17.8535V19.55C36.2197 19.813 36.4328 20.0261 36.6958 20.0261C36.9585 20.0261 37.1719 19.813 37.1719 19.55V17.8535C37.1719 16.3549 35.9527 15.1357 34.4539 15.1357H20.8216C19.3228 15.1357 18.1036 16.3549 18.1036 17.8535V23.9697H14.6385C13.6232 23.9697 12.7969 24.7957 12.7969 25.8114V30.1984C12.7969 31.2141 13.6232 32.0401 14.6385 32.0401H16.0946L18.4541 34.3998C18.5453 34.491 18.6672 34.5393 18.7909 34.5393C18.8522 34.5393 18.9143 34.5274 18.973 34.503C19.151 34.4294 19.267 34.2559 19.267 34.0633V32.0401H22.9432C23.9587 32.0401 24.7849 31.2141 24.7849 30.1984V27.7731H27.1617V31.3995C27.1617 31.5921 27.2777 31.7656 27.4556 31.8393C27.5144 31.8637 27.5763 31.8756 27.6377 31.8756C27.7617 31.8756 27.8834 31.8272 27.9744 31.736L31.9374 27.7731H34.4539C35.9527 27.7731 37.1719 26.5539 37.1719 25.0552V23.3586C37.1719 23.0959 36.9585 22.8825 36.6958 22.8825ZM23.8329 30.1984C23.8329 30.6891 23.4339 31.0879 22.9432 31.0879H18.7909C18.5279 31.0879 18.3149 31.3013 18.3149 31.564V32.914L16.6284 31.2275C16.5391 31.1382 16.418 31.0879 16.2917 31.0879H14.6385C14.148 31.0879 13.749 30.6891 13.749 30.1984V25.8114C13.749 25.3209 14.148 24.9219 14.6385 24.9219H18.1036V25.0552C18.1036 26.5539 19.3228 27.7731 20.8216 27.7731H23.8329V30.1984Z'
            />
            <path
                fill='currentColor'
                d='M32.283 18.2559H22.9995C22.7366 18.2559 22.5234 18.4691 22.5234 18.7319C22.5234 18.995 22.7366 19.208 22.9995 19.208H32.283C32.5458 19.208 32.759 18.995 32.759 18.7319C32.759 18.4691 32.5458 18.2559 32.283 18.2559Z'
            />
            <path
                fill='currentColor'
                d='M32.759 21.3022C32.759 21.0394 32.5458 20.8262 32.283 20.8262H22.9995C22.7366 20.8262 22.5234 21.0394 22.5234 21.3022C22.5234 21.5653 22.7366 21.7783 22.9995 21.7783H32.283C32.5458 21.7783 32.759 21.5653 32.759 21.3022Z'
            />
            <path
                fill='currentColor'
                d='M22.9995 23.3975C22.7366 23.3975 22.5234 23.6107 22.5234 23.8735C22.5234 24.1366 22.7366 24.3496 22.9995 24.3496H28.3553C28.6182 24.3496 28.8314 24.1366 28.8314 23.8735C28.8314 23.6107 28.6182 23.3975 28.3553 23.3975H22.9995Z'
            />
            <path
                fill='currentColor'
                d='M36.6948 20.9785C36.4327 20.9785 36.2188 21.1925 36.2188 21.4546C36.2188 21.7169 36.4327 21.9307 36.6948 21.9307C36.957 21.9307 37.1709 21.7169 37.1709 21.4546C37.1709 21.1925 36.957 20.9785 36.6948 20.9785Z'
            />
        </svg>
    );
}

ChatIcon.defaultProps = {
    width: 50,
    height: 50,
    className: '',
};
