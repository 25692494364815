import React, { ReactElement, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Image } from '../../types/projects';
import { useGSAP } from '@gsap/react';
import { cn } from '../../utils/utils';

gsap.registerPlugin(ScrollTrigger);

type Props = {
    image: Image;
    color: string;
};

export default function ProjectImage(props: Props): ReactElement {
    const { image, color } = props;

    const imgRef = useRef<HTMLImageElement>(null);
    const timeline = useRef<GSAPTimeline>();

    useGSAP(() => {
        timeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: imgRef.current,
                start: 'top 90%',
            },
        });

        timeline.current.to(imgRef.current, { autoAlpha: 1, y: '-50%' });
    }, [imgRef.current]);

    return (
        <div className={cn('relative h-72 w-72 rounded-full sm:h-96 sm:w-96')}>
            <div
                className='absolute left-1/2 top-1/2 h-72 w-72 -translate-x-1/2 -translate-y-1/2 transform rounded-full sm:h-80 sm:w-80 '
                style={{ backgroundColor: color }}
            />
            <img
                ref={imgRef}
                className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[20%] transform opacity-0'
                src={image.src}
                alt={image.alt}
            />
        </div>
    );
}
