import React, { createContext, ReactElement, useMemo, useState } from 'react';

type ModalContextType = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const ModalContext = createContext<ModalContextType>({
    open: false,
    setOpen: () => {},
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function Modal(props: Props): ReactElement {
    const { children } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const value = useMemo(
        () => ({
            open: isOpen,
            setOpen: setIsOpen,
        }),
        [isOpen],
    );

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}

Modal.defaultProps = {};
