import React, { ReactElement } from 'react';
import Button from '../components/button/button';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import os from '../utils/os';

export default function ContactSection(): ReactElement {
    const { strings } = useText(local);

    function handleClicked(): void {
        const email = os.getEnv('REACT_APP_CONTACT_MAIL');
        window.location.href = `mailto:${email}?subject=${strings.mailTo.subject}`;
    }

    return (
        <div className='my-20 flex flex-col items-center justify-center space-y-6'>
            <h2 className='max-w-xs text-base font-bold leading-normal md:max-w-sm'>{strings.title}</h2>
            <Button
                onClick={() => handleClicked()}
                title={strings.buttonText}
                variant='outlined'
                severity='secondary'
            />
        </div>
    );
}

type LocalSchema = {
    buttonText: string;
    title: string;
    mailTo: { subject: string };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        buttonText: 'DEIN SETUP BESPRECHEN',
        title: 'Klingt gut? Unsere Experten zeigen dir gerne, wie dein Projekt konkret von einem Headless CMS profitieren kann!',
        mailTo: {
            subject: 'Let’s go Headless',
        },
    },
};
