import React, { ReactElement, useEffect, useRef } from 'react';
import useText, { LocalizedStrings, SupportedLanguages } from '../../hooks/use-locals';
import gsap from 'gsap';
import { NavItemType } from '../../types/navigation';

type Props = {
    isNavOpen: boolean;
};

const items = [
    { type: NavItemType.EXPERIENCE, className: 'hover:text-[#f6bb6d]' },
    { type: NavItemType.SERVICE, className: 'hover:text-[#ec7e54]' },
    { type: NavItemType.CREDENTIALS, className: 'hover:text-[#c22435]' },
    { type: NavItemType.TEAM, className: 'hover:text-[#2486c4]' },
];

export default function AppNavigation(props: Props): ReactElement {
    const { isNavOpen } = props;
    const { strings } = useText(local);

    const navRef = useRef(null);

    useEffect(() => {
        const navElement = navRef.current;
        if (isNavOpen) {
            gsap.fromTo(navElement, { x: '-100%', opacity: 0 }, { x: '0%', duration: 0.5, opacity: 1 });
        } else {
            gsap.to(navElement, { x: '-100%', opacity: 0, duration: 0.5, ease: 'power1.inOut' });
        }
    }, [isNavOpen]);

    return (
        <nav
            ref={navRef}
            className={`${isNavOpen ? '' : 'opacity-0'} absolute z-10 flex h-screen w-full items-center justify-center bg-primary pb-40`}
        >
            <ul className='flex-row space-y-4 text-3xl'>
                {items.map((item) => (
                    <li key={strings.navigation[item.type].name}>
                        <a
                            className={`decoration-2 underline-offset-8 hover:underline ${item.className}`}
                            href={strings.navigation[item.type].link}
                        >
                            {strings.navigation[item.type].name}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

type LocalSchema = {
    navigation: {
        [key in NavItemType]: {
            name: string;
            link: string;
        };
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        navigation: {
            [NavItemType.EXPERIENCE]: { name: 'Experience', link: 'https://poprocket.com/' },
            [NavItemType.SERVICE]: { name: 'Leistung', link: 'https://poprocket.com/leistungen' },
            [NavItemType.CREDENTIALS]: { name: 'Referenzen', link: 'https://poprocket.com/referenzen' },
            [NavItemType.TEAM]: { name: 'Team', link: 'https://poprocket.com/team' },
        },
    },
};
