import React, { createContext, ReactElement, useEffect, useMemo, useState } from 'react';
import { SupportedLanguages } from '../hooks/use-locals';

type LocalContextType = {
    language: SupportedLanguages;
    setLocal(local: SupportedLanguages): void;
};

export const LocalizationContext = createContext<LocalContextType>({
    language: 'default',
    setLocal: () => {},
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function LocalProvider(props: Props): ReactElement {
    const { children } = props;

    const [local, setLocal] = useState<SupportedLanguages>('default');

    function handleSetLocal(value: SupportedLanguages): void {
        setLocal(value);
        localStorage.setItem('local', value);
    }

    useEffect(() => {
        const loadedLocal = localStorage.getItem('local') as SupportedLanguages;
        if (loadedLocal) {
            setLocal(loadedLocal);
        }
    }, []);

    const value = useMemo(() => ({ language: local, setLocal: handleSetLocal }), [local]);

    return <LocalizationContext.Provider value={value}>{children}</LocalizationContext.Provider>;
}
