import React, { ReactElement, useRef } from 'react';
import { gsap } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locale';

import PrlLogo from '../assets/images/Prl_Logo.svg';
import DirectusLogo from '../assets/images/Directus_Logo.png';
import useDimension from '../hooks/use-dimension';
import { cn } from '../utils/utils';

export default function SceneTwo(): ReactElement {
    const sceneContainerRef = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);

    const headlineContainerRef = useRef<HTMLDivElement>(null);
    const logoContainerRef = useRef<HTMLDivElement>(null);
    const prlLogoContainerRef = useRef<HTMLDivElement>(null);
    const directusLogoContainerRef = useRef<HTMLDivElement>(null);
    const prlImgRef = useRef<HTMLImageElement>(null);
    const directusImgRef = useRef<HTMLImageElement>(null);
    const xCharRef = useRef<HTMLParagraphElement>(null);

    const mainTimeline = useRef<GSAPTimeline>();
    const contentTimeline = useRef<GSAPTimeline>();
    const headlineHideTimeline = useRef<GSAPTimeline>();
    const circleMergeTimeline = useRef<GSAPTimeline>();
    const circleScaleTimeline = useRef<GSAPTimeline>();

    const { strings } = useText(local);
    const windowDimension = useDimension();

    useGSAP(() => {
        mainTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: 'bottom top',
                pin: contentContainer.current,
                scrub: true,
                id: 'sceneTwo',
                onEnter: () => {
                    gsap.set(contentContainer.current, { opacity: 1 });
                },
                onLeaveBack: () => {
                    gsap.set(contentContainer.current, { opacity: 0 });
                },
            },
        });

        contentTimeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'contentShow',
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: '8% top',
                scrub: true,
            },
        });

        contentTimeline.current.to(headlineContainerRef.current, showKeyframe, 0);
        contentTimeline.current.to(logoContainerRef.current, showKeyframe, 0);

        headlineHideTimeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'headlineHide',
                trigger: sceneContainerRef.current,
                start: '55% top',
                end: '60% top',
                scrub: true,
            },
        });

        headlineHideTimeline.current.to(headlineContainerRef.current, hideKeyframe);

        circleMergeTimeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'circleMerge',
                trigger: sceneContainerRef.current,
                start: '35% top',
                end: '80% top',
                scrub: true,
            },
        });

        circleMergeTimeline.current.to(xCharRef.current, hideKeyframe);
        circleMergeTimeline.current.to(prlLogoContainerRef.current, moveToRightKeyframe, '<');
        circleMergeTimeline.current.to(directusLogoContainerRef.current, moveToLeftKeyframe, '<');
        circleMergeTimeline.current.to(prlImgRef.current, hideKeyframe, '<');
        circleMergeTimeline.current.to(directusImgRef.current, hideKeyframe, '<');
        circleMergeTimeline.current.to(prlLogoContainerRef.current, hideKeyframe, '>');

        circleScaleTimeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'scaleCircle',
                trigger: sceneContainerRef.current,
                start: '80% top',
                end: 'bottom top',
                scrub: true,
            },
        });
        circleScaleTimeline.current.to(logoContainerRef.current, { top: '50%' }, '<5%');
        circleScaleTimeline.current.to(directusLogoContainerRef.current, {
            width: windowDimension.dimension === 'mobile' ? '250px' : '350px',
            height: windowDimension.dimension === 'mobile' ? '250px' : '350px',
            top: '50%',
        });
    });

    return (
        <section ref={sceneContainerRef} className='relative flex h-[200vh]'>
            <div
                ref={contentContainer}
                className='relative flex h-[100vh] min-h-screen w-full flex-col items-center justify-center overflow-hidden bg-blue opacity-0'
            >
                <div
                    className='absolute top-[15%] flex w-72 translate-y-20 flex-col opacity-0 md:top-1/3 md:w-[40rem]'
                    ref={headlineContainerRef}
                >
                    <h1 className='md:w-lg text-left text-3xl font-bold uppercase leading-normal md:text-center md:text-4xl md:leading-normal'>
                        {strings.firstText.headline.title}
                    </h1>
                    <p className='max-w-xs self-start text-left text-xl leading-normal md:max-w-sm md:self-center md:text-center'>
                        {strings.firstText.headline.description}
                    </p>
                </div>
                <div
                    className={cn(
                        'absolute left-1/2 flex h-40 w-72 -translate-x-1/2 -translate-y-1/2 opacity-0 md:w-[640px]',
                        windowDimension.windowSize.height < 900 && windowDimension.windowSize.width > 1200
                            ? 'top-[66%]'
                            : 'top-[57%]',
                        windowDimension.windowSize.height < 850 && windowDimension.windowSize.width < 450
                            ? 'top-[65%]'
                            : '',
                    )}
                    ref={logoContainerRef}
                >
                    <div
                        ref={prlLogoContainerRef}
                        className='absolute left-0 top-1/2 flex h-16 w-16 -translate-y-1/2 transform items-center justify-center rounded-full bg-white p-3 md:h-24 md:w-24'
                    >
                        <img ref={prlImgRef} src={PrlLogo} alt='PRL Logo' />
                    </div>
                    <p
                        ref={xCharRef}
                        className='absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center text-2xl font-bold leading-normal md:text-4xl md:leading-normal'
                    >
                        X
                    </p>
                    <div
                        ref={directusLogoContainerRef}
                        className='absolute right-0 top-1/2 flex h-16 w-16 -translate-y-1/2 transform items-center justify-center rounded-full bg-white p-3 md:h-24 md:w-24'
                    >
                        <img ref={directusImgRef} src={DirectusLogo} alt='Directus Logo' />
                    </div>
                </div>
            </div>
        </section>
    );
}

const showKeyframe: GSAPTweenVars = {
    opacity: 1,
    y: 0,
};

const hideKeyframe: GSAPTweenVars = {
    opacity: 0,
};

const moveToRightKeyframe: GSAPTweenVars = {
    backgroundColor: '#F9AAFF',
    left: '50%',
    translateX: '-50%',
    ease: 'power1.out',
};

const moveToLeftKeyframe: GSAPTweenVars = {
    backgroundColor: '#F9AAFF',
    left: '50%',
    translateX: '-50%',
    ease: 'power1.out',
};

type LocalSchema = {
    firstText: {
        headline: {
            title: string;
            description: string;
        };
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        firstText: {
            headline: {
                title: 'Du willst mehr Flexibilität für deine Webseite oder App?',
                description: 'Dann werde Teil der Daten-Revolution mit Pop Rocket Labs und Directus!',
            },
        },
    },
};
