import React, { ButtonHTMLAttributes, ReactElement, useContext } from 'react';
import { ModalContext } from './modal';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    children: ReactElement | ReactElement[];
};
export default function ModalTrigger(props: Props): ReactElement {
    const { children, ...rest } = props;
    const { setOpen } = useContext(ModalContext);
    return (
        <button type='button' onClick={() => setOpen(true)} {...rest}>
            {children}
        </button>
    );
}
