/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, ReactElement, useRef } from 'react';
import { Technology } from '../../types/projects';
import { cn } from '../../utils/utils';
import TechnologyLogo from '../technology/technology-logo';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

type Props = {
    technology: Technology;
    label?: string;
    className?: string;
    animated?: boolean;
    originPos: { x: number; y: number };
    position: { x: number; y: number };
};

export default function Point(props: Props): ReactElement {
    const { technology, label, animated, className, originPos, position } = props;

    const elRef = useRef<HTMLDivElement>(null);
    const lineRef = useRef<SVGLineElement>(null);
    const bgRef = useRef<HTMLDivElement>(null);

    const timeline = useRef<GSAPTimeline>();

    useGSAP(() => {
        if (!animated) {
            return;
        }

        timeline.current = gsap.timeline({
            repeat: -1,
            yoyo: true,
        });

        for (let i = 0; i < 10; i += 1) {
            const newPoint = {
                x: Math.floor(Math.random() * 35),
                y: Math.floor(Math.random() * 35),
            };

            timeline.current?.to(elRef.current, {
                duration: 2,
                x: newPoint.x,
                y: newPoint.y,
                ease: 'linear',
                onUpdate: () => {
                    const offsetX = gsap.getProperty(elRef.current, 'x') as number;
                    const offsetY = gsap.getProperty(elRef.current, 'y') as number;

                    const top = gsap.getProperty(elRef.current, 'top') as number;
                    const left = gsap.getProperty(elRef.current, 'left') as number;

                    const bgWidth = gsap.getProperty(bgRef.current, 'width') as number;
                    const bgHeight = gsap.getProperty(bgRef.current, 'height') as number;

                    const offsetXPercent = (offsetX / bgWidth) * 100;
                    const offsetYPercent = (offsetY / bgHeight) * 100;

                    const newPos = {
                        x: left + offsetXPercent,
                        y: top + offsetYPercent,
                    };

                    lineRef.current?.setAttribute('y2', `${newPos.y}%`);
                    lineRef.current?.setAttribute('x2', `${newPos.x}%`);
                },
            });
        }
    }, [elRef]);

    return (
        <div className='absolute top-0 h-full w-full' ref={bgRef}>
            <div
                ref={elRef}
                className={cn(
                    'absolute z-20 flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center -space-y-2 overflow-hidden rounded-full border-[3px] border-secondary bg-white p-2 md:h-16 md:w-16',
                    className || '',
                    label ? 'p-4' : '',
                )}
                style={{ top: `${position?.y}%`, left: `${position?.x}%` }}
            >
                <TechnologyLogo technology={technology} className='flex h-full w-full' />
                {label && <p className='pb-1 text-sm font-bold uppercase text-darkBlue'>{label}</p>}
            </div>
            <div className='absolute h-full w-full'>
                <svg className={cn('h-full w-full', animated === false ? 'hidden' : '')}>
                    <line
                        ref={lineRef}
                        x2={`${position}%`}
                        y2={`${position}%`}
                        x1={`${originPos?.x}%`}
                        y1={`${originPos?.y}%`}
                        strokeWidth='2'
                        className='stroke-white'
                    />
                </svg>
            </div>
        </div>
    );
}

Point.defaultProps = {
    className: '',
    animated: false,
};
