import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function CloseIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg width={width} height={height} viewBox='0 0 24 24' fill='currentColor' className={className}>
            <path
                d='M18.7091 6.71004L17.2891 5.29004L11.9991 10.59L6.70906 5.29004L5.28906 6.71004L10.5891 12L5.28906 17.29L6.70906 18.71L11.9991 13.41L17.2891 18.71L18.7091 17.29L13.4091 12L18.7091 6.71004Z'
                fill='currentColor'
            />
        </svg>
    );
}

CloseIcon.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
