import React from 'react';
import { ReactElement } from 'react';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import Button from '../components/button/button';
import os from '../utils/os';

export default function FazitSection(): ReactElement {
    const { strings } = useText(local);

    function handleClicked(): void {
        const email = os.getEnv('REACT_APP_CONTACT_MAIL');
        window.location.href = `mailto:${email}?subject=${strings.mailTo.subject}`;
    }

    return (
        <div className='flex h-full w-full flex-col items-center justify-center space-y-14 bg-secondary px-16 py-20 md:h-screen md:px-0'>
            <div className='space-y-8 md:w-[43em] '>
                <h2 className='text-center text-4xl font-bold text-gray-900 md:text-5xl' style={{ lineHeight: 1.3 }}>
                    {strings.fazit.title}
                </h2>
            </div>
            <div>
                <h3 className='font-bold leading-loose text-gray-900'>{strings.contact.title}</h3>
                <p className='leading-loose text-gray-900 md:max-w-lg'>{strings.contact.text}</p>{' '}
            </div>
            <Button onClick={() => handleClicked()} className='uppercase' title={strings.contact.buttontext} />
        </div>
    );
}

type LocalSchema = {
    fazit: {
        title: string;
    };
    contact: {
        title: string;
        text: string;
        buttontext: string;
    };
    mailTo: { subject: string };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        fazit: {
            title: 'Kurz gesagt: Egal wie dein Projekt aussieht, dein Content ist flexibel und sicher. ',
        },
        contact: {
            title: 'Bist du bereit für eine neue Art von Content?',
            text: 'Unsere Headless-Experten helfen dir dabei, nicht den Kopf zu verlieren. Nimm heute Kontakt zu uns auf, wir beraten dich gerne und bringen dein Projekt auf’s nächste Level!',
            buttontext: 'jetzt durchstarten',
        },
        mailTo: {
            subject: 'Let’s go Headless',
        },
    },
};
