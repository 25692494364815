import { useEffect, useState } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';

enum DimensionType {
    Mobile = 'mobile',
    Desktop = 'desktop',
}

type DimensionApi = {
    dimension: DimensionType;
    windowSize: { width: number; height: number };
};

export default function useDimension(): DimensionApi {
    const windowSize = useWindowSize();

    const [dimension, setDimension] = useState<DimensionType>(
        window.innerWidth < 640 ? DimensionType.Mobile : DimensionType.Desktop,
    );

    useEffect(() => {
        setDimension(windowSize.width! < 640 ? DimensionType.Mobile : DimensionType.Desktop);
    }, [windowSize]);

    return {
        dimension,
        windowSize: {
            width: windowSize.width || 0,
            height: windowSize.height || 0,
        },
    };
}
