import React, { ReactElement } from 'react';
import { gsap, MotionPathPlugin } from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

type Props = {
    children: ReactElement | ReactElement[];
};

ScrollTrigger.config({
    limitCallbacks: true,
    ignoreMobileResize: false,
});

ScrollTrigger.normalizeScroll(true);

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);

export default function GsapProvider(props: Props): ReactElement {
    const { children } = props;
    return <>{children}</>;
}
