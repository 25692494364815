import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function NotificationIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg width={width} height={height} viewBox='0 0 50 50' fill='none' className={className}>
            <circle cx='25' cy='25' r='24.5' stroke='currentColor' />
            <path
                fill='currentColor'
                d='M33.2969 25.3485V22.918C33.2969 19.3723 30.9401 16.3671 27.711 15.3856V14.2852C27.711 13.0251 26.6859 12 25.4258 12C24.1658 12 23.1407 13.0251 23.1407 14.2852V15.3856C19.9115 16.3671 17.5547 19.3723 17.5547 22.918V25.3485C17.5547 28.463 16.3676 31.4162 14.212 33.6642C14.0007 33.8845 13.9414 34.2095 14.0611 34.4903C14.1808 34.771 14.4566 34.9531 14.7618 34.9531H21.6939C22.0477 36.6894 23.5866 38 25.4258 38C27.2651 38 28.8039 36.6894 29.1578 34.9531H36.0899C36.3951 34.9531 36.6708 34.771 36.7905 34.4903C36.9103 34.2095 36.8509 33.8845 36.6397 33.6642C34.4841 31.4162 33.2969 28.4629 33.2969 25.3485ZM24.6641 14.2852C24.6641 13.8651 25.0058 13.5234 25.4258 13.5234C25.8459 13.5234 26.1876 13.8651 26.1876 14.2852V15.0838C25.9369 15.0597 25.6828 15.0469 25.4258 15.0469C25.1689 15.0469 24.9148 15.0597 24.6641 15.0838V14.2852ZM25.4258 36.4766C24.4328 36.4766 23.5861 35.8397 23.2717 34.9531H27.58C27.2655 35.8397 26.4189 36.4766 25.4258 36.4766ZM16.4035 33.4297C18.1383 31.1043 19.0782 28.2902 19.0782 25.3485V22.918C19.0782 19.4179 21.9257 16.5703 25.4258 16.5703C28.9259 16.5703 31.7735 19.4179 31.7735 22.918V25.3485C31.7735 28.2902 32.7134 31.1043 34.4482 33.4297H16.4035Z'
            />
            <path
                fill='currentColor'
                d='M35.3266 22.9176C35.3266 23.3383 35.6676 23.6793 36.0883 23.6793C36.5089 23.6793 36.85 23.3383 36.85 22.9176C36.85 19.8657 35.6615 16.9964 33.5035 14.8383C33.206 14.5409 32.7237 14.5408 32.4262 14.8383C32.1288 15.1358 32.1288 15.6181 32.4262 15.9155C34.2966 17.7859 35.3266 20.2726 35.3266 22.9176Z'
            />
            <path
                fill='currentColor'
                d='M14.7617 23.6793C15.1824 23.6793 15.5234 23.3383 15.5234 22.9176C15.5234 20.2726 16.5535 17.7859 18.4238 15.9156C18.7212 15.6181 18.7212 15.1358 18.4238 14.8383C18.1263 14.5409 17.644 14.5409 17.3465 14.8383C15.1885 16.9964 14 19.8656 14 22.9176C14 23.3383 14.341 23.6793 14.7617 23.6793Z'
            />
        </svg>
    );
}

NotificationIcon.defaultProps = {
    width: 50,
    height: 50,
    className: '',
};
