import React, { ReactElement } from 'react';
import FooterPageLinks from './footer-page-links';
import FooterNavigation from '../components/navigations/footer-navigation';
import FooterContactSection from './footer-contact-section';

export default function Footer(): ReactElement {
    return (
        <footer className='h-full flex-row items-start justify-start bg-white md:items-center md:justify-center'>
            <div className='m-auto px-8 pt-10 md:max-w-[1010px]'>
                <FooterPageLinks />
                <FooterContactSection />
                <div className='flex flex-col items-start justify-start space-y-10 py-9 md:flex-row md:items-center md:justify-between md:space-y-0'>
                    <p className='text-md text-mediumGray'>© Pop Rocket Labs GmbH, {new Date().getFullYear()}</p>
                    <FooterNavigation />
                </div>
            </div>
        </footer>
    );
}
