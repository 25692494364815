import React, { ReactElement } from 'react';
import { Image, ProjectContent } from '../../types/projects';

type Props = {
    content: ProjectContent;
    logo: Image;
    accentcolor: string;
};

export default function ProjectSummary(props: Props): ReactElement {
    const { content, logo, accentcolor } = props;

    return (
        <div className='space-y-4 px-5 text-left md:px-0'>
            <img src={logo.src} alt={logo.alt} />
            <h3 className='text-2xl font-bold leading-9 md:w-56'>{content.title}</h3>
            <p className='h-12 w-72'>{content.description}</p>
            <div className='pl-5'>
                <ul className='list-outside list-disc space-y-4' style={{ color: accentcolor }}>
                    {content.bulletpoints.map((item, index) => (
                        <li className='text-sm md:w-64' key={`${content.title}-${index.toString()}`}>
                            <span className='text-white'>{item}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
