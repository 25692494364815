import React, { ReactElement, useState } from 'react';
import BurgermenuIcon from '../components/icons/fill/burgermenu-icon';
import CloseIcon from '../components/icons/fill/close-icon';
import IconButton from '../components/button/icon-button';
import PrlLogo from '../assets/images/prl-logo';
import AppNavigation from '../components/navigations/app-navigation';

export default function Header(): ReactElement {
    const [isNavOpen, setIsNavOpen] = useState(false);

    return (
        <header className='sticky top-0 z-50 h-full w-full flex-row items-center'>
            <div
                className={`${isNavOpen ? '' : 'shadow-md'} absolute z-20 flex h-20 w-full items-center justify-between bg-opacity-0 px-10 backdrop-blur-lg sm:px-32 `}
            >
                <IconButton
                    icon={<PrlLogo />}
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}
                />
                <IconButton
                    icon={isNavOpen ? <CloseIcon /> : <BurgermenuIcon />}
                    onClick={() => {
                        setIsNavOpen(!isNavOpen);
                    }}
                />
            </div>
            <AppNavigation isNavOpen={isNavOpen} />
        </header>
    );
}
