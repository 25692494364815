import React, { ReactElement, useRef } from 'react';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { Player } from '@lottiefiles/react-lottie-player';
import MobilePinkBrush from '../assets/lotties/mobile-pink-brush.json';
import DesktopPinkBrush from '../assets/lotties/desktop-pink-brush.json';
import useDimension from '../hooks/use-dimension';

export default function BlockThree(): ReactElement {
    const { strings } = useText(local);
    const { dimension } = useDimension();

    const textScrollInTimeline = useRef<GSAPTimeline>();
    const timeline = useRef<GSAPTimeline>();
    const moveBallsTimeline = useRef<GSAPTimeline>();
    const switchToLottieTl = useRef<GSAPTimeline>();
    const lottieTl = useRef<GSAPTimeline>();
    const bgSwitchTl = useRef<GSAPTimeline>();

    const sceneContainer = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);

    const lBallRef = useRef<HTMLDivElement>(null);
    const rBallRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const pinkBrush = useRef<Player>(null);
    const pinkBrushDiv = useRef<HTMLDivElement>(null);
    const pinkBg = useRef<HTMLDivElement>(null);

    useGSAP(() => {
        textScrollInTimeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'blockThreeTest',
                trigger: sceneContainer.current,
                start: 'top bottom',
                end: 'top top',
                scrub: true,
            },
        });

        textScrollInTimeline.current.to(textRef.current, { translateY: 200, ease: 'power1.in' }, 0);
        textScrollInTimeline.current.to(textRef.current, { scale: 1.2 }, 0);

        timeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'blockThree',
                trigger: sceneContainer.current,
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: contentContainer.current,
            },
        });
        initMoveBallsTimeline();
        initAnimationSwitchTimeline();
        initLottieAnimationTimeline();
        initBgSwitchTimeline();
    }, [sceneContainer]);

    function initMoveBallsTimeline(): void {
        moveBallsTimeline.current = gsap.timeline({
            scrollTrigger: {
                id: 'moveBalls',
                trigger: sceneContainer.current,
                start: 'top top',
                end: '25% top',
                scrub: true,
            },
        });

        moveBallsTimeline.current.to(lBallRef.current, { left: `50%` });
        moveBallsTimeline.current.to(rBallRef.current, { right: `50%` }, '<');
    }

    function initAnimationSwitchTimeline(): void {
        switchToLottieTl.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '25% top',
                end: '30% top',
                scrub: true,
            },
        });

        switchToLottieTl.current.to(lBallRef.current, { backgroundColor: '#F9AAFF', opacity: 1 });
        switchToLottieTl.current.to(rBallRef.current, { opacity: 0 });
        switchToLottieTl.current.to(pinkBrushDiv.current, { opacity: 1 });
    }

    function initLottieAnimationTimeline(): void {
        lottieTl.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '30% top',
                end: '80% top',
                id: 'lottie',
                scrub: true,
                onUpdate: (self) => {
                    const pbInstance = pinkBrush.current?.state?.instance;
                    const totalFrames = pbInstance?.totalFrames ?? 0;
                    pinkBrush.current?.state?.instance?.goToAndStop(Math.floor(self.progress * totalFrames), true);
                },
            },
        });
    }

    function initBgSwitchTimeline(): void {
        bgSwitchTl.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '75% top',
                end: 'bottom top',
                scrub: true,
            },
        });

        bgSwitchTl.current.fromTo(pinkBg.current, { height: '0%' }, { height: '50%' });
    }

    useGSAP(() => {
        initMoveBallsTimeline();
        initAnimationSwitchTimeline();
        initLottieAnimationTimeline();
        initBgSwitchTimeline();
    });

    return (
        <div ref={sceneContainer} className='relative h-[450vh] w-full overflow-hidden bg-primary'>
            <div
                ref={contentContainer}
                className='relative h-[100vh] min-h-screen w-full items-center justify-center bg-primary'
            >
                <div className='relative h-full w-full items-center justify-center '>
                    <div
                        className='absolute top-0 flex w-full flex-col items-center justify-center space-y-6'
                        ref={textRef}
                    >
                        <p className='text-lg'>{strings.text}</p>
                        <h2 className='w-56 items-center justify-center text-center text-5xl font-bold sm:w-full'>
                            {strings.title}
                        </h2>
                    </div>
                    <div className='absolute bottom-0 flex w-full justify-center sm:bottom-10'>
                        <div
                            ref={lBallRef}
                            className='absolute bottom-0 mb-[130px] h-44 w-44 -translate-x-1/2 rounded-full bg-green sm:mb-[120px] sm:h-[250px] sm:w-[250px]'
                            style={{ left: dimension === 'mobile' ? '-120px' : '-250px' }}
                        />
                        <div
                            ref={rBallRef}
                            className='absolute bottom-0 mb-[130px] h-44 w-44 translate-x-1/2 rounded-full bg-blue opacity-85 sm:mb-[120px] sm:h-[250px] sm:w-[250px]'
                            style={{ right: dimension === 'mobile' ? '-120px' : '-250px' }}
                        />
                        <div ref={pinkBrushDiv} className='absolute bottom-0 opacity-0'>
                            <Player
                                ref={pinkBrush}
                                className='w-full'
                                autoplay={false}
                                src={dimension === 'mobile' ? MobilePinkBrush : DesktopPinkBrush}
                            />
                        </div>
                    </div>
                </div>
                <div ref={pinkBg} className='absolute bottom-0 w-full bg-pink' />
            </div>
        </div>
    );
}

type LocalSchema = {
    text: string;
    title: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        text: '5 Gründe für ein',
        title: 'PERFECT MATCH!',
    },
};
