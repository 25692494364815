import { useContext } from 'react';
import { LocalizationContext } from '../providers/local-provider';

export type SupportedLanguages = 'default';

export type LocalizedStrings<X, T extends string = 'default'> = {
    [key in T]: X;
};

type TextApi<T> = {
    strings: T;
    language: SupportedLanguages;
    setLocal(language: SupportedLanguages): void;
};

export default function useText<Source>(source: LocalizedStrings<Source>): TextApi<Source> {
    const { language, setLocal } = useContext(LocalizationContext);

    return {
        // @ts-ignore
        strings: source[language as SupportedLanguages],
        language,
        setLocal,
    };
}
