export const colors = {
    primary: '#0C1220',
    secondary: '#5FE99F',
    green: '#5FE99F',
    darkBlue: '#0C1220',
    blue: '#34AAFF',
    pink: '#F9AAFF',
    orange: '#F97F26',
    white: '#FFFFFF',
};
