import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function ArrowCircle(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg width={width} height={height} viewBox='0 0 60 60' fill='none' className={className}>
            <g clipPath='url(#clip0_6455_4560)'>
                <path
                    d='M60 30C60 13.4584 46.5416 0 30 0C13.4584 0 0 13.4584 0 30C0 46.5416 13.4584 60 30 60C46.5416 60 60 46.5416 60 30ZM2.99755 30C2.99755 15.1101 15.1101 2.99755 30 2.99755C44.8899 2.99755 57.0024 15.1101 57.0024 30C57.0024 44.8899 44.8899 57.0024 30 57.0024C15.1101 57.0024 2.99755 44.8899 2.99755 30Z'
                    fill='currentColor'
                />
                <path
                    d='M31.0636 44.0945L39.4935 35.6647C40.0808 35.0774 40.0808 34.1353 39.4935 33.548C38.9062 32.9608 37.9641 32.9608 37.3769 33.548L31.5041 39.4208V16.9697C31.5041 16.1378 30.8312 15.4648 29.9992 15.4648C29.1672 15.4648 28.4943 16.1378 28.4943 16.9697V39.4086L22.6215 33.5358C22.0343 32.9485 21.0922 32.9485 20.5049 33.5358C19.9176 34.1231 19.9176 35.0652 20.5049 35.6524L28.9348 44.0823C29.2284 44.3759 29.6077 44.5228 29.9992 44.5228C30.3907 44.5228 30.77 44.3882 31.0636 44.0945Z'
                    fill='currentColor'
                />
            </g>
            <defs>
                <clipPath id='clip0_6455_4560'>
                    <rect width='60' height='60' fill='currentColor' />
                </clipPath>
            </defs>
        </svg>
    );
}
ArrowCircle.defaultProps = {
    width: 60,
    height: 60,
    className: '',
};
