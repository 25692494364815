import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import { cn } from '../../utils/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const buttonVariants = cva(
    'inline-flex h-9 w-72 items-center justify-center rounded-2xl border px-5 py-2.5 text-sm font-bold uppercase shadow',
    {
        variants: {
            variant: {
                default: [
                    'bg-primary',
                    'border-primary',
                    'hover:bg-white',
                    'hover:border-white',
                    'hover:text-primary',
                    'transition-colors',
                ],
                outlined: [
                    'border-primary',
                    'bg-opacity-0',
                    'hover:bg-primary-100',
                    'hover:bg-opacity-100',
                    'hover:text-primary',
                    'transition-colors',
                ],
            },
            severity: {
                primary: ['bg-primary', 'border-primary', 'text-white'],
                secondary: ['bg-secondary', 'border-secondary'],
            },
        },
        compoundVariants: [
            {
                variant: 'outlined',
                severity: 'secondary',
                className: ['text-secondary'],
            },
        ],
        defaultVariants: {
            variant: 'default',
        },
    },
);

type Props = ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof buttonVariants> & {};
export default function Button(props: Props): ReactElement {
    const { className, title, variant, severity, ...rest } = props;

    return (
        <button type='button' className={cn(buttonVariants({ variant, severity }), className ?? '')} {...rest}>
            {title}
        </button>
    );
}
