import React, { ReactElement } from 'react';
import { Technology } from '../types/projects';
import TechnologyModal from '../components/modals/technology-info/technology-modal';
import Point from '../components/bubbel-section/point';
import { TechnologyPoint } from '../types/technologies';
import { Player } from '@lottiefiles/react-lottie-player';
import BubbleBackground from '../assets/lotties/bubbel-background.json';
import Arrow from '../assets/decorators/arrow';
import useDimension from '../hooks/use-dimension';

const points: TechnologyPoint[] = [
    {
        technology: Technology.TAILWIND,
        pos: {
            default: {
                x: 15,
                y: 65,
            },
            sm: {
                x: 14,
                y: 65,
            },
        },
    },
    {
        technology: Technology.AWS,
        pos: {
            default: {
                x: 15,
                y: 25,
            },
            sm: {
                x: 20,
                y: 25,
            },
        },
    },
    {
        technology: Technology.TYPESCRIPT,
        pos: {
            default: {
                x: 45,
                y: 5,
            },
            sm: {
                x: 45,
                y: 8,
            },
        },
    },
    {
        technology: Technology.REACT,
        pos: {
            default: {
                x: 80,
                y: 25,
            },
            sm: {
                x: 75,
                y: 25,
            },
        },
    },
    {
        technology: Technology.NODE,
        pos: {
            default: {
                x: 80,
                y: 70,
            },
            sm: {
                x: 75,
                y: 70,
            },
        },
    },
];

const rootPos = {
    x: 50,
    y: 60,
};

export default function BubbelSection(): ReactElement {
    const window = useDimension();

    return (
        <div className='flex w-full flex-col items-center space-y-5 '>
            <div className='relative flex h-96 w-full max-w-2xl'>
                <div className='absolute -z-20 flex h-full w-full items-center justify-center'>
                    <Player autoplay src={BubbleBackground} loop className='h-full w-full' speed={0.1} />
                </div>

                <div className='absolute h-full w-full'>
                    <div className='relative flex h-full w-full flex-col'>
                        {points.map((point) => {
                            const pos = window.dimension === 'mobile' ? point.pos.sm : point.pos.default;

                            return (
                                <TechnologyModal
                                    technology={point.technology}
                                    key={point.technology}
                                    trigger={
                                        <Point
                                            key={point.technology}
                                            technology={point.technology}
                                            animated
                                            originPos={rootPos}
                                            position={pos}
                                            className='p-3 transition-transform hover:scale-125'
                                        />
                                    }
                                />
                            );
                        })}

                        <Point
                            key={Technology.DIRECTUS}
                            technology={Technology.DIRECTUS}
                            label={Technology.DIRECTUS}
                            animated={false}
                            originPos={rootPos}
                            position={{ x: rootPos.x, y: rootPos.y }}
                            className='h-28 w-28 md:h-32 md:w-32'
                        />

                        <Arrow
                            className='relative -translate-x-1/2'
                            style={{ top: `${rootPos.y + 7}%`, left: `${rootPos.x}%` }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
