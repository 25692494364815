import React, { LinkHTMLAttributes, ReactElement } from 'react';
import ArrowRight from '../../assets/decorators/arrow-right';

type Props = LinkHTMLAttributes<HTMLAnchorElement> & {
    title: string;
    linkText: string;
    className: string;
};

export default function ColoredPageLink(props: Props): ReactElement {
    const { title, href, linkText, className } = props;
    return (
        <div className='flex flex-col justify-end space-y-5 text-left'>
            <h2 className='w-56 text-4xl text-[#6d7278]'>{title}</h2>
            <a className={`underline-offset-3 flex flex-row space-x-8 underline ${className}`} href={href}>
                <p className='font-bold'>{linkText}</p>
                <ArrowRight className={`m-auto underline ${className}`} />
            </a>
        </div>
    );
}
