import React from 'react';
import { ReactElement } from 'react';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import { Project, ProjectType, Technology } from '../types/projects';
import ProjectSection from './project-section';
import { colors } from '../utils/colors';

export default function ProjectsOverview(): ReactElement {
    const { strings } = useText(local);

    return (
        <div className='mx-auto mb-20 max-w-screen-lg'>
            <h2 className='mb-10 text-center text-2xl font-bold uppercase text-green'>{strings.title}</h2>
            <div className='space-y-14 md:space-y-40'>
                {staticProjectData.map((item, index) => (
                    <ProjectSection key={item.id} project={item} imgPositioning={index % 2 === 0 ? 'left' : 'right'} />
                ))}
            </div>
        </div>
    );
}

type LocalSchema = {
    title: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        title: 'Directus im Praxistest: Unsere Referenzen',
    },
};

const staticProjectData: Project[] = [
    {
        id: 'exaple-1',
        type: ProjectType.WEBAPP,
        company: {
            name: 'Peta',
            logo: {
                src: './logos/peta.png',
                alt: '',
            },
        },
        content: {
            title: 'Jede Art Content, schnell moderiert',
            description: 'Für PETAs Veganstart Web-App bietet Directus',
            bulletpoints: [
                'die Verwaltungsmöglichkeit verschiedenster Content-Typen, wie Quizfragen, Rezepte oder sogar Push-Notifications',
                'Direkte Content-Updates auf den Endgeräten, ohne Umwege über AppStore Submission - dank der Nutzung einer WebView, die in einem App-Wrapper geladen wird',
            ],
        },
        technologies: [Technology.AWS, Technology.TAILWIND, Technology.TYPESCRIPT],
        accentcolor: colors.green,
        image: {
            src: './images/peta-app.png',
            alt: '',
        },
    },
    {
        id: 'exaple-2',
        type: ProjectType.REACTNATIVE,
        company: {
            name: 'artgerecht',
            logo: {
                src: './logos/artgerecht.png',
                alt: '',
            },
        },
        content: {
            title: 'Kinderleichte Verwaltung',
            description: 'Das Team der Artgerecht App setzt mit Directus auf',
            bulletpoints: [
                'eine einfache Blogverwaltung, mit der Möglichkeit Einträge mit Bildern, Audiodateien, formatierten Texten und weiteren Custom-Fields zu befüllen',
                'Direkte Content-Updates auf den Endgeräten, ohne Umwege über AppStore Submission - dank der Nutzung einer WebView, die in einem App-Wrapper geladen wird',
            ],
        },
        technologies: [Technology.AWS, Technology.TAILWIND, Technology.TYPESCRIPT],
        accentcolor: colors.pink,
        image: {
            src: './images/artgerecht-app.png',
            alt: '',
        },
    },
    {
        id: 'exaple-3',
        company: {
            name: 'stadtreinigung-hamburg',
            logo: {
                src: './logos/stadtreinigung-hamburg.png',
                alt: '',
            },
        },
        content: {
            title: 'Vielseitig einsetzbar',
            description: 'Die Zero Waste Map App der Hamburger Stadtreinigung nutzt Directus für',
            bulletpoints: [
                'Content Submission von User-Generated Content, der direkt im CMS kontrolliert und freigegeben werden kann',
                'Die Übergabe von Geolocation-Daten, die im CMS gespeichert und im Frontend auf einer Karte platziert und dargestellt werden',
            ],
        },
        technologies: [Technology.AWS, Technology.TAILWIND, Technology.TYPESCRIPT],
        accentcolor: colors.blue,
        image: {
            src: './images/stadtreinigung-app.png',
            alt: '',
        },
    },
];
