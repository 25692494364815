import React, { ReactElement } from 'react';
import useText, { LocalizedStrings, SupportedLanguages } from '../../hooks/use-locals';
import { ProjectType } from '../../types/projects';

type Props = {
    type: ProjectType;
    accentcolor: string;
};

export default function ProjectHeadline(props: Props): ReactElement {
    const { type, accentcolor } = props;
    const { strings } = useText(local);

    return (
        <div className='inline-flex flex-col items-center space-y-4 px-5'>
            <h3 className='text-center text-4xl font-bold uppercase sm:text-5xl'>{strings.projectType[type]}</h3>
            <div className='w-8/12 border-2 md:w-11/12' style={{ borderColor: accentcolor }} />
        </div>
    );
}

type LocalSchema = {
    title: string;
    projectType: {
        [key in ProjectType]: string;
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        title: 'Projekte Lorem Ipsum....',
        projectType: {
            [ProjectType.WEBAPP]: 'The power of Web Apps',
            [ProjectType.REACT]: 'React',
            [ProjectType.REACTNATIVE]: 'React Native durchgespielt',
        },
    },
};
