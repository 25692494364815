import React, { ReactElement, useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap/all';
import { cn } from '../utils/utils';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locale';
import Strings from '../utils/string';
import { Colors } from '../styles/global';
import useDimension from '../hooks/use-dimension';

const TOTAL_COL_BALLS_COUNT = 6;

// Note: Only for Debug/Dev purposes
const SHOW_BALLS_MARKER = false;
const SHOW_TEXT_MARKER = false;

export default function SceneTen(): ReactElement {
    const sceneContainer = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);

    const col1ballsRef = useRef<HTMLDivElement[]>([]);
    const col2ballsRef = useRef<HTMLDivElement[]>([]);
    const col3ballsRef = useRef<HTMLDivElement[]>([]);
    const col4ballsRef = useRef<HTMLDivElement[]>([]);

    const ballsContainerRef = useRef<HTMLDivElement>(null);
    const transitionStartBallsContainerRef = useRef<HTMLDivElement>(null);
    const transitionBallRef = useRef<HTMLDivElement>(null);
    const transitionBgRef = useRef<HTMLDivElement>(null);

    const headlineRef = useRef<HTMLDivElement>(null);
    const explanationRef = useRef<HTMLDivElement>(null);
    const highlightTextRef = useRef<HTMLSpanElement>(null);

    const transitionOutNumberRef = useRef<HTMLParagraphElement>(null);

    const ballsTimeline = useRef<GSAPTimeline>();
    const textTimeline = useRef<GSAPTimeline>();
    const transitionOutTimeline = useRef<GSAPTimeline>();

    const { strings } = useText(local);
    const { dimension } = useDimension();

    useGSAP(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: 'bottom top',
                pin: contentContainer.current,
                scrub: true,
                markers: SHOW_BALLS_MARKER,
            },
        });

        initBallTimeline();
        initTextTimeline();
        initTransitionOutTimeline();
    });

    function initBallTimeline(): void {
        ballsTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: 'center center',
                scrub: true,
                id: 'sceneTenBalls',
                markers: SHOW_BALLS_MARKER,
            },
        });

        col1ballsRef.current.forEach((ball, index) => {
            ballsTimeline.current?.fromTo(
                ball,
                {
                    y: `${50 + 10 * (index + 2)}vh`,
                },
                {
                    y: 0,
                },
                index,
            );
        });

        col2ballsRef.current.forEach((ball, index) => {
            ballsTimeline.current?.fromTo(
                ball,
                {
                    y: `${50 + 20 * (index + 3)}vh`,
                },
                {
                    y: 60,
                },
                index,
            );
        });

        col3ballsRef.current.forEach((ball, index) => {
            ballsTimeline.current?.fromTo(
                ball,
                {
                    y: `${50 + 40 * (index + 4)}vh`,
                },
                {
                    y: 60 * 2,
                },
                index,
            );
        });

        col4ballsRef.current.forEach((ball, index) => {
            ballsTimeline.current?.fromTo(
                ball,
                {
                    y: `${50 + 60 * (index + 5)}vh`,
                },
                {
                    y: 60 * 3,
                },
                index,
            );
        });
    }

    function initTextTimeline(): void {
        textTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: '90% bottom',
                scrub: true,
                id: 'sceneTenText',
                markers: SHOW_TEXT_MARKER,
            },
        });

        textTimeline.current.fromTo(
            headlineRef.current,
            {
                opacity: 0,
                y: 100,
            },
            {
                opacity: 1,
                y: 0,
            },
        );

        textTimeline.current?.to(headlineRef.current, {
            opacity: 0,
            delay: 1,
        });

        textTimeline.current.fromTo(
            explanationRef.current,
            {
                opacity: 0,
                y: 100,
            },
            {
                opacity: 1,
                y: 0,
            },
        );

        textTimeline.current.fromTo(
            highlightTextRef.current,
            {
                color: 'white',
            },
            {
                color: Colors.secondary,
            },
        );
    }

    function initTransitionOutTimeline(): void {
        transitionOutTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '50% center',
                end: 'bottom top',
                scrub: true,
                id: 'sceneTenTransitionOut',
                markers: SHOW_TEXT_MARKER,
            },
        });

        const originBallRef = col2ballsRef.current[2];

        transitionOutTimeline.current?.to(
            transitionBallRef.current,
            {
                opacity: 1,
            },
            0,
        );

        transitionOutTimeline.current?.to(
            originBallRef,
            {
                opacity: 0,
            },
            1,
        );

        transitionOutTimeline.current?.fromTo(
            transitionBallRef.current,
            calculateTransitionBallStartPos(),
            {
                left: '50%',
                top: '50%',
                width: dimension === 'mobile' ? '80vh' : '90vh',
                height: dimension === 'mobile' ? '80vh' : '90vh',
            },
            2,
        );

        transitionOutTimeline.current?.to(
            transitionBgRef.current,
            {
                opacity: 1,
            },
            2,
        );

        transitionOutTimeline.current?.to(
            transitionOutNumberRef.current,
            {
                opacity: 1,
            },
            '<+=50%',
        );
    }

    function calculateTransitionBallStartPos(): { left: string; top: string } {
        if (!ballsContainerRef.current || !transitionStartBallsContainerRef.current) {
            return {
                left: '50%',
                top: '50%',
            };
        }

        if (!ballsContainerRef.current || !transitionStartBallsContainerRef.current) {
            return {
                left: '50%',
                top: '50%',
            };
        }

        const originBallRef = col2ballsRef.current[2];

        let left = originBallRef.offsetLeft;
        left += ballsContainerRef.current?.offsetLeft || 0;

        if (window.innerWidth > 640) {
            left += transitionStartBallsContainerRef.current?.offsetLeft || 0;
            left += transitionStartBallsContainerRef.current?.offsetWidth || 0;
            left += 4;
        }

        left -= originBallRef.offsetWidth;

        left -= ballsContainerRef.current.offsetWidth / 2;
        left += transitionStartBallsContainerRef.current?.offsetWidth || 0;
        left += originBallRef.offsetWidth / 2;

        left += (contentContainer.current?.offsetWidth || 0) / 2;

        return {
            left: `${left}px`,
            top: `${
                ballsContainerRef.current.offsetTop + originBallRef.offsetTop + 60 + originBallRef.offsetHeight / 2
            }px`,
        };
    }

    return (
        <div ref={sceneContainer} className='relative flex h-[600vh] w-full justify-center overflow-y-hidden'>
            <div
                ref={contentContainer}
                className='relative h-[100vh] min-h-screen w-full max-w-[1536px] items-center justify-center overflow-hidden'
            >
                <div className='absolute top-[20%] z-10 flex h-fit w-full flex-col md:top-0 md:z-0 md:h-full md:w-1/2 md:items-center md:justify-center'>
                    <div ref={headlineRef} className='absolute ml-0 w-full text-center opacity-0 md:ml-28'>
                        <h2 className='text-3xl font-bold leading-normal md:text-5xl md:leading-normal'>
                            {strings.headline}
                        </h2>
                        <p className='text-2xl leading-normal md:text-4xl md:leading-normal'>{strings.subheadline}</p>
                    </div>
                    <p
                        ref={explanationRef}
                        className='absolute ml-0 px-10 text-xl leading-normal opacity-0 md:ml-28 md:px-0 md:text-4xl md:leading-normal'
                    >
                        {Strings.Format(
                            strings.explanation.base,
                            <span className='text-3xl font-bold leading-normal md:text-5xl md:leading-normal'>
                                {strings.explanation.highlighted}
                            </span>,
                            <span
                                ref={highlightTextRef}
                                className='text-3xl font-bold leading-normal md:text-5xl md:leading-normal'
                            >
                                {strings.explanation.extraHighlighted}
                            </span>,
                            <span>
                                <br /> {strings.explanation.subline}
                            </span>,
                            <span>
                                <br />
                                {strings.explanation.extraLine}
                            </span>,
                        )}
                    </p>
                </div>
                <div className='absolute flex h-full w-full items-center justify-center md:right-0 md:w-1/2'>
                    <div
                        ref={ballsContainerRef}
                        className='absolute bottom-0 flex h-1/2 w-full justify-center space-x-2 md:h-[75%]'
                    >
                        <div className='space-y-6'>
                            {Array.from({ length: TOTAL_COL_BALLS_COUNT }).map((_, index) => (
                                <div
                                    key={`col-1-ball-${index.toString()}`}
                                    ref={(el) => {
                                        if (el) {
                                            col1ballsRef.current[index] = el;
                                        }
                                    }}
                                    className='h-20 w-20 rounded-full bg-emerald-400'
                                />
                            ))}
                        </div>
                        <div ref={transitionStartBallsContainerRef} className='space-y-6'>
                            {Array.from({ length: TOTAL_COL_BALLS_COUNT }).map((_, index) => (
                                <div
                                    key={`col-2-ball-${index.toString()}`}
                                    ref={(el) => {
                                        if (el) {
                                            col2ballsRef.current[index] = el;
                                        }
                                    }}
                                    className={cn('h-20 w-20 rounded-full bg-emerald-400')}
                                />
                            ))}
                        </div>
                        <div className='space-y-6'>
                            {Array.from({ length: TOTAL_COL_BALLS_COUNT }).map((_, index) => (
                                <div
                                    key={`col-3-ball-${index.toString()}`}
                                    ref={(el) => {
                                        if (el) {
                                            col3ballsRef.current[index] = el;
                                        }
                                    }}
                                    className='h-20 w-20 rounded-full bg-emerald-400'
                                />
                            ))}
                        </div>
                        <div className='space-y-6'>
                            {Array.from({ length: TOTAL_COL_BALLS_COUNT }).map((_, index) => (
                                <div
                                    key={`col-4-ball-${index.toString()}`}
                                    ref={(el) => {
                                        if (el) {
                                            col4ballsRef.current[index] = el;
                                        }
                                    }}
                                    className='h-20 w-20 rounded-full bg-emerald-400'
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div ref={transitionBgRef} className='absolute h-full w-full bg-primary opacity-0' />
                    <div
                        ref={transitionBallRef}
                        className='absolute z-10 flex h-20 w-20 items-center justify-center rounded-full bg-blue opacity-0'
                        style={{
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <p ref={transitionOutNumberRef} className='text-transition font-bold opacity-0'>
                            {strings.transitionOutNumber}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

type LocalSchema = {
    headline: string;
    subheadline: string;

    explanation: {
        base: string;
        highlighted: string;
        extraHighlighted: string;
        subline: string;
        extraLine: string;
    };
    transitionOutNumber: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        headline: 'Maßgeschneiderte',
        subheadline: 'Datenbankstrukturen',
        explanation: {
            base: 'Directus passt sich an {0} an und {1} zuverlässig. {2} ',
            highlighted: 'deine Datenbankstrukturen',
            extraHighlighted: 'skaliert',
            subline: 'Wir behalten Kontrolle und Überblick!',
            extraLine: 'Wir behalten Kontrolle und Überblick!',
        },
        transitionOutNumber: '4.',
    },
};
