import React, { ReactElement, useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap/all';

type Props = {
    amount: number;
};

export default function BallList(props: Props): ReactElement {
    const { amount } = props;

    const balls = useRef<HTMLDivElement[]>([]);

    useGSAP(() => {
        balls.current.forEach((ball, index) => {
            gsap.fromTo(
                ball,
                {
                    x: 100 * index,
                    opacity: 0,
                },
                {
                    x: 0,
                    opacity: 1,
                    delay: index * 0.1,
                },
            );
        });
    }, [balls]);

    return (
        <div className='flex space-x-5'>
            {Array.from({ length: amount }).map((_, index) => (
                <div
                    key={`${index.toString()}`}
                    ref={(el) => {
                        if (!el) return;
                        balls.current[index] = el;
                    }}
                    className='h-7 w-7 rounded-full bg-blue md:h-8 md:w-8'
                />
            ))}
        </div>
    );
}
