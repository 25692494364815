import React, { ReactElement } from 'react';
import Modal from '../core/modal';
import ModalContent from '../core/modal-content';
import TechnologyModalContent from './technology-modal-content';
import { Technology } from '../../../types/projects';
import ModalTrigger from '../core/modal-trigger';

type Props = {
    technology: Technology;
    trigger: ReactElement;
};

export default function TechnologyModal(props: Props): ReactElement {
    const { technology, trigger } = props;

    return (
        <Modal>
            <ModalTrigger>{trigger}</ModalTrigger>
            <ModalContent className='px-5 py-10 md:px-20'>
                <TechnologyModalContent technology={technology} />
            </ModalContent>
        </Modal>
    );
}
