import React, { ReactElement } from 'react';
import './styles/global.css';

import Header from './sections/header';
import FeatureOverview from './sections/features-overview';
import ContactSection from './sections/contact-section';
import BubbelSection from './sections/bubbel-section';
import FazitSection from './sections/fazit-section';
import ProjectsOverview from './sections/projects-overview';

import Intro from './scenes/intro';
import SceneTwo from './scenes/scene-two';
import SceneThree from './scenes/scene-three';
import BlockTwo from './scenes/block-two';
import BlockThree from './scenes/block-three';
import BlockFour from './scenes/block-four';
import ReasonOne from './scenes/reason-one';
import ReasonTwo from './scenes/reason-two';
import SceneNine from './scenes/scene-nine';
import SceneTen from './scenes/scene-ten';
import SceneEleven from './scenes/scene-eleven';

import './styles/global.css';
import SceneTwelve from './scenes/scene-twelve';
import Footer from './sections/footer';

function App(): ReactElement {
    return (
        <>
            <Header />
            <div className='flex h-full w-full flex-col items-center'>
                <div className='w-full'>
                    <Intro />
                    <SceneTwo />
                    <SceneThree />
                    <BlockTwo />
                    <BlockThree />
                    <BlockFour />
                    <ReasonOne />
                    <ReasonTwo />
                    <SceneNine />
                    <SceneTen />
                    <SceneEleven />
                    <SceneTwelve />
                </div>
                <div className='w-full'>
                    <BubbelSection />
                    <FeatureOverview />
                    <ContactSection />
                    <ProjectsOverview />
                    <FazitSection />
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default App;
