import React, { ReactElement, useRef } from 'react';
import { gsap } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import useText, { LocalizedStrings, SupportedLanguages } from '../hooks/use-locale';
import Strings from '../utils/string';

import ArrowDown from '../assets/images/intro-arrow-down.svg';
import BallList from '../components/animations/ball-list';
import useDimension from '../hooks/use-dimension';

export default function Intro(): ReactElement {
    const { strings } = useText(local);
    const windowDim = useDimension();

    const sceneContainerRef = useRef<HTMLDivElement>(null);
    const contentContainerRef = useRef<HTMLDivElement>(null);
    const contentWrapRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);
    const blueCanvasRef = useRef<HTMLDivElement>(null);
    const circlesGroupRef = useRef<HTMLDivElement>(null);
    const arrowImgRef = useRef<HTMLDivElement>(null);
    const textBlockRef = useRef<HTMLDivElement>(null);

    const mainTimeline = useRef<GSAPTimeline>();
    const canvasTimeline = useRef<GSAPTimeline>();
    const contentTimeline = useRef<GSAPTimeline>();
    const fadeOutContentTimeline = useRef<GSAPTimeline>();
    const movingContentTimeline = useRef<GSAPTimeline>();
    const textBlockTimeline = useRef<GSAPTimeline>();
    const bouncingBallTimeline = useRef<GSAPTimeline>();
    const blueCanvasTimeline = useRef<GSAPTimeline>();

    useGSAP(() => {
        mainTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: contentContainerRef.current,
                id: 'intro',
            },
        });

        initCanvasTimeline();
        initContentTimeline();
        initTextBlockTimeline();
        initFadeOutContentTimeline();
        initBouncingBallTimeline();
        initBlueCanvasTimeline();
    });

    function initCanvasTimeline(): void {
        canvasTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: 'top top',
                end: '20% top',
                scrub: true,
                id: 'canvasTimeline',
            },
        });

        canvasTimeline.current.to(canvasRef.current, {
            width: windowDim.dimension === 'mobile' ? '120vw' : '120vh',
            height: windowDim.dimension === 'mobile' ? '120vw' : '120vh',
            left: '50%',
        });

        canvasTimeline.current.to(arrowImgRef.current, { opacity: 0, translateY: '15vh' }, '<');
    }

    function initContentTimeline(): void {
        contentTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '20% top',
                end: '30% top',
                scrub: true,
                id: 'contentTimeline',
            },
        });
        contentTimeline.current.to(contentRef.current, { marginLeft: '0' });
        contentTimeline.current.to(contentRef.current, { opacity: 0, scale: 0.1 }, '<');
        contentTimeline.current.to(
            canvasRef.current,
            {
                width: windowDim.windowSize.width < 768 ? '50px' : '100px',
                height: windowDim.windowSize.width < 768 ? '50px' : '100px',
            },
            '<',
        );
        contentTimeline.current.to(canvasRef.current, smallesContentToBottomKeyframe, '>');

        movingContentTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '30% top',
                end: '45% top',
                scrub: true,
            },
        });

        movingContentTimeline.current.to(contentRef.current, { opacity: 0, scale: 0.1 }, '<');
        movingContentTimeline.current.to(
            canvasRef.current,
            {
                translateX: windowDim.dimension === 'mobile' ? '-20vw' : '7vw',
                translateY: windowDim.dimension === 'mobile' ? '280%' : '-60%',
            },
            '>',
        );
    }

    function initFadeOutContentTimeline(): void {
        fadeOutContentTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '40% top',
                end: '45% top',
                scrub: true,
                id: 'fadeOutContentTimeline',
            },
        });

        fadeOutContentTimeline.current.to(contentRef.current, generalFadeOut, '<');
    }

    function initTextBlockTimeline(): void {
        textBlockTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '38% top',
                end: '45% top',
                scrub: true,
            },
        });

        textBlockTimeline.current.to(textBlockRef.current, showDiv, 0);
        textBlockTimeline.current.to(textBlockRef.current, generalFadeIn, 0);
    }

    function initBouncingBallTimeline(): void {
        bouncingBallTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '50% top',
                end: '75% top',
                scrub: true,
            },
        });

        bouncingBallTimeline.current.to(
            canvasRef.current,
            { ease: 'bounce.out', translateY: windowDim.dimension === 'mobile' ? '350%' : '130%' },
            0,
        );
        bouncingBallTimeline.current.to(canvasRef.current, { translateX: '28vw' }, 0);
        bouncingBallTimeline.current.to(canvasRef.current, { right: -10 });
    }

    function initBlueCanvasTimeline(): void {
        blueCanvasTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainerRef.current,
                start: '60% top',
                end: 'bottom top',
                scrub: true,
                id: 'blueCanvasTimeline',
            },
        });

        blueCanvasTimeline.current.to(blueCanvasRef.current, generalFadeIn);
        blueCanvasTimeline.current.to(
            blueCanvasRef.current,
            {
                translateX: '-40%',
                scale: windowDim.windowSize.width < 768 ? 2 : 1,
                duration: 1.5,
                width: '130vw',
                height: '130vw',
            },
            '<',
        );
    }

    return (
        <section ref={sceneContainerRef} className='relative flex h-[450vh]'>
            <div
                ref={contentContainerRef}
                className='relative flex h-[100vh] min-h-screen w-full items-center justify-center overflow-hidden bg-darkBlue'
            >
                <div
                    ref={blueCanvasRef}
                    className='absolute z-50 -mr-[100vw] h-[100vw] w-[100vw] translate-x-full rounded-full bg-blue opacity-0'
                />
                <div
                    ref={canvasRef}
                    className='absolute left-1/2 m-auto flex h-[300vw] w-[300vw] -translate-x-1/2 flex-col items-center justify-center rounded-full bg-green p-10 align-middle'
                >
                    <div ref={contentWrapRef} className='relative'>
                        <div
                            ref={contentRef}
                            className='relative z-10 h-auto max-w-sm space-y-5 px-4 text-left md:max-w-lg'
                        >
                            <h1 className='text-5xl font-bold uppercase text-darkBlue md:text-7xl'>
                                {strings.headline.title}
                            </h1>
                            <p className='text-3xl text-darkBlue md:max-w-72'>
                                {Strings.Format(
                                    strings.headline.description.base,
                                    <span className='font-bold'>{strings.headline.description.reasons}</span>,
                                )}
                            </p>
                            <div ref={circlesGroupRef}>
                                <BallList amount={5} />
                            </div>
                        </div>
                        <div
                            ref={arrowImgRef}
                            className='opacity-1 absolute left-0 right-0 m-auto mt-16 flex h-20 w-full justify-center sm:mt-20'
                        >
                            <img className='w-16' src={ArrowDown} alt='Arrow Down' />
                        </div>
                    </div>
                </div>
                <div className='flex w-full flex-col items-center md:flex-row md:items-start md:pl-[20vw]'>
                    <div
                        ref={textBlockRef}
                        className='ml-0 hidden max-w-xs translate-y-0 text-left opacity-0 sm:translate-y-10 md:max-w-xs'
                    >
                        <h2 className='text-4xl font-bold leading-normal'>{strings.subline.title}</h2>
                    </div>
                </div>
            </div>
        </section>
    );
}

const generalFadeIn: GSAPTweenVars = {
    opacity: 1,
};

const generalFadeOut: GSAPTweenVars = {
    opacity: 0,
};

const showDiv: GSAPTweenVars = {
    display: 'block',
};

const smallesContentToBottomKeyframe: GSAPTweenVars = {
    translateY: '20vh',
};

type LocalSchema = {
    headline: {
        title: string;
        description: {
            base: string;
            reasons: string;
        };
    };
    subline: {
        title: string;
    };
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        headline: {
            title: 'Endlich ungebunden:',
            description: {
                base: 'Unsere {0} {1} für ein Headless CMS',
                reasons: '5 Gründe',
            },
        },
        subline: {
            title: 'CMS Systeme, die sich an ein spezifisches Frontend binden, waren gestern.',
        },
    },
};
