import React, { ReactElement, RefObject, useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap/all';
import { LocalizedStrings, SupportedLanguages } from '../hooks/use-locals';
import useText from '../hooks/use-locale';
import useDimension from '../hooks/use-dimension';
import Strings from '../utils/string';

export default function SceneEleven(): ReactElement {
    const { strings } = useText(local);

    const { dimension } = useDimension();

    const transitionInTimeline = useRef<GSAPTimeline>();
    const textSwitchTimeline = useRef<GSAPTimeline>();
    const littleBallsTimeline = useRef<GSAPTimeline>();
    const transitionOutTimeline = useRef<GSAPTimeline>();

    const sceneContainer = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);

    const ballRef = useRef<HTMLDivElement>(null);
    const transitionInNumberRef = useRef<HTMLDivElement>(null);
    const bgRef = useRef<HTMLDivElement>(null);

    const headlineRef = useRef<HTMLDivElement>(null);
    const argumentRef = useRef<HTMLDivElement>(null);

    const littleBallsDiv = useRef<HTMLDivElement>(null);
    const littleBallOne = useRef<HTMLDivElement>(null);
    const littleBallTwo = useRef<HTMLDivElement>(null);
    const littleBallThree = useRef<HTMLDivElement>(null);

    const lineOne = useRef<SVGLineElement>(null);
    const lineTwo = useRef<SVGLineElement>(null);
    const lineThree = useRef<SVGLineElement>(null);

    const transitionOutBall = useRef<HTMLDivElement>(null);
    const transitionOutNumberRef = useRef<HTMLDivElement>(null);
    const transiotionOutBg = useRef<HTMLDivElement>(null);

    useGSAP(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: 'bottom bottom+50vh',
                pin: contentContainer.current,
                scrub: true,
                onEnter: () => {
                    // @ts-ignore
                    ballRef.current.style.opacity = 1;
                    // @ts-ignore
                    bgRef.current.style.opacity = 1;
                },
                onLeaveBack: () => {
                    // @ts-ignore
                    ballRef.current.style.opacity = 0;
                    // @ts-ignore
                    bgRef.current.style.opacity = 0;
                },
            },
        });
        initTransitionInTimeline();
        initTextSwitchTimeline();
        initLittleBallsTimeline();
        initTransitionOutTimeline();
    });

    function initTransitionInTimeline(): void {
        transitionInTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: 'top top',
                end: '10% top',
                scrub: true,
            },
        });

        transitionInTimeline.current?.to(ballRef.current, {
            width: dimension === 'mobile' ? '180px' : '250px',
            height: dimension === 'mobile' ? '180px' : '250px',
            top: dimension === 'mobile' ? '60%' : '68%',
        });

        transitionInTimeline.current?.to(transitionInNumberRef.current, { opacity: 0 }, '<');
        transitionInTimeline.current?.to(headlineRef.current, { opacity: 1 }, '>-30%');
    }

    function initTextSwitchTimeline(): void {
        textSwitchTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '15% top',
                end: '30% top',
                scrub: true,
            },
        });

        textSwitchTimeline.current.to(headlineRef.current, { opacity: 0 });

        if (dimension === 'mobile') {
            const secondBallPos = calculateCenterFromDiv(littleBallTwo);
            textSwitchTimeline.current.to(ballRef.current, {
                left: '20%',
                top: `${secondBallPos.y}px`,
            });
        } else {
            textSwitchTimeline.current.to(ballRef.current, {
                left: '65%',
                top: '50%',
            });
        }
        textSwitchTimeline.current.fromTo(argumentRef.current, { opacity: 0 }, { opacity: 1 });
    }

    function initLittleBallsTimeline(): void {
        littleBallsTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '30% top',
                end: '60% top',
                scrub: true,
                onEnter() {
                    const centerBigBall = calculatebigBallCenter();
                    const widthLittleBallDiv = littleBallsDiv.current?.offsetLeft ?? 0;

                    const centerLittleBallOne = calculateCenterFromDiv(littleBallOne);
                    const centerLittleBallTwo = calculateCenterFromDiv(littleBallTwo);
                    const centerLittleBallThree = calculateCenterFromDiv(littleBallThree);

                    drawLine(lineOne, centerBigBall, {
                        x: centerLittleBallOne.x + widthLittleBallDiv,
                        y: centerLittleBallOne.y,
                    });
                    drawLine(lineTwo, centerBigBall, {
                        x: centerLittleBallTwo.x + widthLittleBallDiv,
                        y: centerLittleBallTwo.y,
                    });
                    drawLine(lineThree, centerBigBall, {
                        x: centerLittleBallThree.x + widthLittleBallDiv,
                        y: centerLittleBallThree.y,
                    });
                },
                onEnterBack() {
                    const centerBigBall = calculatebigBallCenter();
                    const widthLittleBallDiv = littleBallsDiv.current?.offsetLeft ?? 0;

                    const centerLittleBallOne = calculateCenterFromDiv(littleBallOne);
                    const centerLittleBallTwo = calculateCenterFromDiv(littleBallTwo);
                    const centerLittleBallThree = calculateCenterFromDiv(littleBallThree);

                    drawLine(lineOne, centerBigBall, {
                        x: centerLittleBallOne.x + widthLittleBallDiv,
                        y: centerLittleBallOne.y,
                    });
                    drawLine(lineTwo, centerBigBall, {
                        x: centerLittleBallTwo.x + widthLittleBallDiv,
                        y: centerLittleBallTwo.y,
                    });
                    drawLine(lineThree, centerBigBall, {
                        x: centerLittleBallThree.x + widthLittleBallDiv,
                        y: centerLittleBallThree.y,
                    });
                },

                onLeaveBack() {
                    resetLine(lineOne);
                    resetLine(lineTwo);
                    resetLine(lineThree);
                },
            },
        });
        littleBallsTimeline.current.fromTo(littleBallOne.current, { scale: 0 }, { scale: 1 });
        littleBallsTimeline.current.fromTo(lineOne.current, { opacity: 0 }, { opacity: 1 }, '<');

        littleBallsTimeline.current.fromTo(littleBallTwo.current, { scale: 0 }, { scale: 1 });
        littleBallsTimeline.current.fromTo(lineTwo.current, { opacity: 0 }, { opacity: 1 }, '<');

        littleBallsTimeline.current.fromTo(littleBallThree.current, { scale: 0 }, { scale: 1 });
        littleBallsTimeline.current.fromTo(lineThree.current, { opacity: 0 }, { opacity: 1 }, '<');
    }

    function initTransitionOutTimeline(): void {
        transitionOutTimeline.current = gsap.timeline({
            scrollTrigger: {
                trigger: sceneContainer.current,
                start: '60% top',
                end: 'bottom bottom',
                scrub: true,
            },
        });

        const centerLittleBallThree = calculateCenterFromDiv(littleBallThree);
        const widthLittleBallDiv = littleBallsDiv.current?.offsetLeft ?? 0;

        transitionOutBall.current?.setAttribute(
            'style',
            `left: ${centerLittleBallThree.x + widthLittleBallDiv}px; top: ${centerLittleBallThree.y}px;`,
        );

        transitionOutTimeline.current.to(littleBallThree.current, { backgroundColor: '#34AAFF' });
        transitionOutTimeline.current.fromTo(transitionOutBall.current, { opacity: 0 }, { opacity: 1 });
        transitionOutTimeline.current.to(littleBallThree.current, { opacity: 0 });

        transitionOutTimeline.current.fromTo(
            transitionOutBall.current,
            {
                top: `${centerLittleBallThree.y}px`,
                left: `${centerLittleBallThree.x + widthLittleBallDiv}px`,
            },
            {
                top: '50%',
                left: '50%',
                width: dimension === 'mobile' ? '700px' : '880px',
                height: dimension === 'mobile' ? '700px' : '880px',
            },
        );

        transitionOutTimeline.current.to(transiotionOutBg.current, { opacity: 0.8 }, '<');
        transitionOutTimeline.current.to(transitionOutNumberRef.current, { opacity: 1 }, '<');
        transitionOutTimeline.current.to(transiotionOutBg.current, { opacity: 1 });
    }

    function calculateCenterFromDiv(ball: RefObject<HTMLDivElement>): { x: number; y: number } {
        const centerX = (ball.current?.offsetLeft ?? 0) + (ball.current?.offsetWidth ?? 0) / 2;
        const centerY = (ball.current?.offsetTop ?? 0) + (ball.current?.offsetHeight ?? 0) / 2;

        return { x: centerX, y: centerY };
    }

    function calculatebigBallCenter(): { x: number; y: number } {
        const centerXBigBall =
            (ballRef.current?.offsetLeft ?? 0) +
            (ballRef.current?.offsetWidth ?? 0) / 2 -
            (ballRef.current?.clientWidth ?? 0) / 2;

        const centerYBigBall =
            (ballRef.current?.offsetTop ?? 0) +
            (ballRef.current?.offsetHeight ?? 0) / 2 -
            (ballRef.current?.clientWidth ?? 0) / 2;

        return { x: centerXBigBall, y: centerYBigBall };
    }

    function drawLine(
        line: RefObject<SVGLineElement>,
        from: { x: number; y: number },
        to: { x: number; y: number },
    ): void {
        line.current?.setAttribute('x1', `${from.x}`);
        line.current?.setAttribute('y1', `${from.y}`);
        line.current?.setAttribute('x2', `${to.x}`);
        line.current?.setAttribute('y2', `${to.y}`);
    }

    function resetLine(line: RefObject<SVGLineElement>): void {
        line.current?.setAttribute('x1', '0');
        line.current?.setAttribute('y1', '0');
        line.current?.setAttribute('x2', '0');
        line.current?.setAttribute('y2', '0');
    }

    return (
        <section>
            <div ref={sceneContainer} className='relative flex h-[450vh] justify-center overflow-hidden'>
                <div ref={bgRef} className='absolute left-0 top-0 h-full min-h-screen w-full opacity-0' />
                <div
                    ref={contentContainer}
                    className='flex h-[100vh] w-full max-w-[1536px] flex-col items-center justify-center'
                >
                    <div ref={headlineRef} className='absolute top-1/4 opacity-0'>
                        <h1 className='w-72 text-3xl md:w-[500px] md:text-4xl lg:text-5xl' style={{ lineHeight: 1.5 }}>
                            {strings.headline}
                        </h1>
                    </div>
                    <div className='absolute top-[20%] flex items-center justify-center md:left-0 md:top-auto md:w-1/2'>
                        <p
                            ref={argumentRef}
                            className='w-[360px] text-xl leading-relaxed opacity-0 md:w-[500px] md:text-3xl'
                            style={{ lineHeight: 1.8 }}
                        >
                            {Strings.Format(
                                strings.argument.base,
                                <span>
                                    <br />
                                </span>,
                                <span className='text-3xl font-bold md:text-5xl'>
                                    {strings.argument.highlight} <br />
                                </span>,
                            )}
                        </p>
                    </div>
                    <div
                        ref={ballRef}
                        className='absolute z-10 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-blue opacity-0'
                        style={{
                            left: '50%',
                            top: '50%',
                            width: dimension === 'mobile' ? '80vh' : '90vh',
                            height: dimension === 'mobile' ? '80vh' : '90vh',
                        }}
                    >
                        <p ref={transitionInNumberRef} className='text-transition font-bold'>
                            {strings.transitionInNumber}
                        </p>
                    </div>
                    <div className='absolute z-0 h-full w-full'>
                        <svg fill='none' className='h-full w-full'>
                            <line ref={lineOne} x1={0} y1={0} x2={0} y2={0} stroke='white' strokeWidth='2' />
                            <line ref={lineTwo} x1={0} y1={0} x2={0} y2={0} stroke='white' strokeWidth='2' />
                            <line ref={lineThree} x1={0} y1={0} x2={0} y2={0} stroke='white' strokeWidth='2' />
                        </svg>
                    </div>
                    <div
                        ref={littleBallsDiv}
                        className='absolute right-12 z-10 flex h-full w-1/4 flex-col items-center justify-end space-y-16 pb-10 md:right-0 md:justify-evenly md:space-y-0 md:py-20 '
                    >
                        <div ref={littleBallOne} className='h-20 w-20 rounded-full bg-cyan-300 ' />
                        <div ref={littleBallTwo} className=' h-20 w-20 rounded-full bg-cyan-300 ' />
                        <div ref={littleBallThree} className=' h-20 w-20 rounded-full bg-cyan-300 ' />
                    </div>
                    <div
                        ref={transitionOutBall}
                        className='absolute z-30 h-20 w-20 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-blue '
                    >
                        <p
                            ref={transitionOutNumberRef}
                            className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-transition font-bold opacity-0'
                        >
                            {strings.transitionOutNumber}
                        </p>
                    </div>
                    <div ref={transiotionOutBg} className='absolute z-20 h-full w-full bg-primary opacity-0' />
                </div>
            </div>
        </section>
    );
}

type LocalSchema = {
    headline: string;
    argument: { base: string; highlight: string };
    transitionInNumber: string;
    transitionOutNumber: string;
};

const local: LocalizedStrings<LocalSchema, SupportedLanguages> = {
    default: {
        headline: 'Intuitive Oberflächen und Rollenverteilung',
        argument: {
            base: 'Dein Team pflegt nur Inhalte, {0} die du ihnen {1} ganz ohne technisches Know-How.',
            highlight: 'freigibst -',
        },
        transitionInNumber: '4.',
        transitionOutNumber: '5.',
    },
};
